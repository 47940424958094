import "./offer-deliverable-modal.scss"
import {CheckboxItem, Input} from "../../../components/UI";
import {useContext, useEffect, useState} from "react";
import Select from "../../../components/UI/Select/Select";
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../service/toastify/toastify";
import {useHistory, useLocation} from "react-router-dom";

export const OfferDeliverableModal = () => {
    const location = useLocation()
    const history = useHistory()
    const { setModalType, setModalData, modalData } = useContext(MainContextProvider)
    const [files, setFiles] = useState([])
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [values, setValues] = useState({
        not_required: false,
        name: '',
        title: '',
        url: '',
        file_id: '',
        task_to_done: false
    })

    const getOfferFiles = async () => {
        try {
            const { status, data } = await customAxios.get(`/company/storage/offer/${modalData?.offer_id}/files`)
            if (status === 200) {
                setFiles(data.map((el: { id: string, name_to_show: string }) => ({ value: el.id, label: el.name_to_show })))
            }
        } catch (e) {}
    }

    useEffect(() => {
        if (modalData) {
            setValues({
                ...values,
                title: modalData.title,
                name: modalData.title,
            })
            getOfferFiles().then()
        }
    }, [modalData])
    
    const moveTaskToDone = async (data: { id: string }) => {
        const { status } = await customAxios.put(`/company/tasks/${modalData?.task_id}`, {
            status: 3
        })
        if (status === 200) {
            history.push(`${window.location.pathname}?update=${data.id}`)
            setModalType('')
            setModalData(null)
        }
    }

    const createDeliverable = async () => {
        let validReq = {}
        const postData = {
            task_id: modalData?.task_id,
            name: values.title,
            url: values.url,
            file_id: values.file_id
        }

        if (values.url) {
            validReq = controlErrorFunc({ obj: values, config: {
                    name: { required: true },
                    url: { url: true, required: true }
                }})
        } else if (values.file_id) {
            validReq = controlErrorFunc({ obj: values, config: {
                    name: { required: true },
                }})
        } else {
            validReq = controlErrorFunc({ obj: values, config: {
                    name: { required: true },
                    file_id: { required: true },
                    url: { url: true, required: true },
                }})
        }

        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }

        try {
            const { status, data } = await customAxios.post(`/company/deliverable/create`, postData)
            if (status === 200) {
                if (values.task_to_done) {
                    await moveTaskToDone(data)
                } else {
                    history.push(`${window.location.pathname}?update=${data.id}`)
                    setModalType('')
                    setModalData(null)
                }
            }
        } catch (e) {}
    }

    const changeValue = (e: string | boolean, type: string) => {
        const parsedValues = JSON.parse(JSON.stringify(values))
        if (type === 'url') {
            parsedValues.file_id = ''
        } else if (type === 'file_id') {
            parsedValues.url = ''
        }
        parsedValues[type] = e
        setValues({ ...parsedValues })
    }

    return (
        <div className={'offer-deliverable-modal__container'}>
            <p className={'offer-deliverable-modal__heading'}>
                <b>Task:</b> {modalData?.title}
            </p>
            {/*<div className="offer-deliverable-modal__not-required">
                <CheckboxItem
                    active={values.not_required}
                    onChange={() => changeValue(!values.not_required, 'not_required')}
                    label={'This task does not require a deliverable'}
                />
            </div>*/}
            <Input
                label={'Name'}
                className={'mt15'}
                otherProps={{
                    placeholder: '',
                    value: values.title,
                    onChange: e => changeValue(e.target.value, 'title')
                }}
                valid={!errors.title} errorMessage={errors.title}
            />
            <Input
                label={'Insert URL'}
                className={'mt15'}
                otherProps={{
                    placeholder: '',
                    value: values.url,
                    onChange: e => changeValue(e.target.value, 'url')
                }}
                valid={!errors.url} errorMessage={errors.url}
            />
            <div className="offer-deliverable-modal__hr">
                <hr/>
                <p>OR</p>
            </div>
            <Select
                menuPlacement={'top'}
                maxMenuHeight={170}
                className={'mt15'}
                label={'Select Uploaded File'}
                value={values.file_id}
                options={[
                    { value: '', label: 'Select Uploaded File' },
                    ...files,
                ]}
                onChange={e => changeValue(e.value, 'file_id')}
                valid={!errors.file_id} errorMessage={errors.file_id}
            />
            <CheckboxItem
                active={values.task_to_done}
                onChange={() => changeValue(!values.task_to_done, 'task_to_done')}
                label={'Change the task’s status to “Done”'}
            />
            <button onClick={createDeliverable} className={'btn-black w100 mt20 text-uppercase'}>Submit</button>
        </div>
    )
}