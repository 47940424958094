import React, {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import {useHistory, useLocation} from "react-router-dom";
import axios from "axios";
import {setCookie} from "../../../containers/Modals/NpsModal/NpsModal";
import "../../../containers/Modals/NpsModal/nps.scss"
import ModalContainer from "../../ModalContainer";
import Select from "../../UI/Select/Select";
import {CheckboxItem, Input} from "../../UI";
import {NpsAdmin} from "./NpsAdmin";
import {toastifyError} from "../../../service/toastify/toastify";

export const UpdateNps: React.FC<{ isUserAuth?: boolean }> = ({ isUserAuth }) => {
    const [userId, setUserId] = useState<string | null>(null)
    const [npsId, setNpsId] = useState<string | null>(null)
    const { setLoading } = useContext(MainContextProvider)
    const location = useLocation()
    const history = useHistory()
    const searchParams = new URLSearchParams(location.search);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [questionsList, setQuestionsList] = useState<any[]>([])
    const [values, setValues] = useState<{ [key: string]: string }>({})
    const [isAuth, setIsAuth] = useState(false)
    const [updatedItem, setUpdatedItem] = useState<any>(null)

    const getQuestions = async (value: string) => {
        const { status, data } = await axios.get(`${process.env.REACT_APP_STRIPE_API_URL}/question?value=${value}`, {
            headers: { auth: localStorage.getItem('nps-token') }
        })
        if (status === 200) {
            setQuestionsList(data?.items || [])
            setIsModalOpen(false)

            let messageValues: { [key: string]: string } = {}
            for (const item  of data.items ) {
                messageValues[`message_${item.id}`] = item.default_value || ''
            }
            setValues(messageValues)
        }
    }

    const updateNps = async () => {
        const { status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/nps`, {
            user_id: searchParams.get('user-id'), value: searchParams.get('nps')
        }, { headers: { auth: localStorage.getItem('nps-token') }  })
        if (status === 200 || status === 201) {
            history.push(`/update-nps?nps_id=${data.quiz.id}&user-nps-id=${data.user_id}&value=${searchParams.get('nps')}&user-id=${searchParams.get('user-id')}`)
        }
    }

    const checkQuestions = async () => {
        const { status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/platform-user`, { platform_user_id: searchParams.get('user-id') }, {
            headers: { auth: localStorage.getItem('nps-token') }
        })
        if (data.user.wait_quiz_question_id) {
            setNpsId(searchParams.get('nps_id'))
            setUserId(searchParams.get('user-nps-id'))
            await getQuestions(searchParams.get('value') || '1')
        } else {
            history.push(`/update-nps`)
        }
    }

    const checkAccessToken = async () => {
        const { status } = await axios.get(`${process.env.REACT_APP_STRIPE_API_URL}/nps/results`, {
            headers: { auth: localStorage.getItem('nps-token') || searchParams.get('nps-token') }
        })
        if (status === 200) {
             if (!localStorage.getItem('nps-token')) localStorage.setItem('nps-token', searchParams.get('nps-token') || '')
            setIsAuth(true)
        } else {
            localStorage.removeItem('nps-token')
        }
    }

    useEffect(() => {
        if (!searchParams.get('nps_id') && searchParams.get('user-id') && searchParams.get('nps')) {
            updateNps().then()
            setCookie('hide-nps', 'true')
        }

        if (searchParams.get('nps_id')) {
            /*setNpsId(searchParams.get('nps_id'))
            setUserId(searchParams.get('user-nps-id'))*/
            checkQuestions().then()
        }

        if (isAuth) {
            getQuestions(searchParams.get('value') || '1')
        }

        if (searchParams.get('nps-token') || localStorage.getItem('nps-token')) {
            checkAccessToken().then()
        }
        setLoading(false)
    }, [searchParams.get('user-id'), searchParams.get('nps_id'), searchParams.get('value'), searchParams.get('nps-token'), isAuth])

    const deleteQuestion = async (id: string) => {
        if (!window.confirm('Are you sure?')) return

        setLoading(true)
        const { status, data } = await axios.delete(`${process.env.REACT_APP_STRIPE_API_URL}/question/${id}`, {
            headers: { auth: localStorage.getItem('nps-token') }
        })
        if (status === 204) {
            await getQuestions(searchParams.get('value') || '1')
        }
        setLoading(false)
    }
    
    const sendQuestions = async () => {
        const questions: any = []
        Object.keys(values).map(key => {
            if (values[key].trim()) {
                questions.push({
                    nps_id: npsId,
                    platform_user_id: userId,
                    value: values[key],
                    question_id: key.split('_')[1]
                })
            }
        })

        setLoading(true)
        if (questions.length > 0) {
            const { status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/question/questions`, {
                questions,
                user_id: searchParams.get('user-id')
            })

            if (status === 200) {
                history.push(`/update-nps?finish=true`)
            }
        } else {
            toastifyError('You have to fill in at least one field')
        }
        setLoading(false)
    }

    const updateItem = (item: any) => {
        setUpdatedItem(item)
        setIsModalOpen(true)
    }

    const cancelTheSurvey = async () => {
        if (!window.confirm('Are you sure?')) return

        setLoading(true)
        const { status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/question/questions`, {
            questions: [],
            user_id: searchParams.get('user-id')
        })
        if (status === 200) {
            history.push(`/update-nps?skip-survey=true`)
        }
        setLoading(false)
    }

    if (searchParams.get('nps-result-list') && isAuth) {
        return <NpsAdmin />
    }

    if (searchParams.get('skip-survey')) {
        return (
            <div className={'nps__finish-text'}>
                {/*<h2>Thank you for participating in our survey!</h2>*/}
                <p>
                    We understand you may not want to participate in the survey at this time.
                    If you change your mind, you are always welcome to provide your feedback later. Feel free to send it to <a target={'_blank'} href="mailto:product@legalnodes.com">product@legalnodes.com</a>
                </p>
                <button onClick={() => history.push(`/`)} className={'nps__button'}>To dashboard</button>
            </div>
        )
    }

    if (searchParams.get('finish')) {
        return (
            <div className={'nps__finish-text'}>
                <h2>Thank you for participating in our survey!</h2>
                <p>
                    Your opinion is very important to us. With your feedback, we can improve the quality of our product & services and strive for excellence.
                    We appreciate that you took the time to share your thoughts and promise that each comment will be taken into consideration.
                </p>
                <button onClick={() => history.push(`/`)} className={'nps__button'}>To dashboard</button>
            </div>
        )
    }

    if (!isAuth && !npsId) {
        return (
            <div className={'flex alignCenter mt20'} style={{ flexDirection: 'column' }}>
                Nothing to show <br />
                <button className={'btn-black text-uppercase mt10'} style={{ width: '200px', marginLeft: '20px' }} onClick={() => history.push(`/dashboard`)}>
                    {isUserAuth ? 'Sign in' : 'Dashboard'}
                </button>
            </div>
        )
    }

    return (
        <div className={'nps__questions'} style={{ paddingTop: '20px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                {
                    isAuth && <div className={'nps__question-body-quiz'} style={{ background: '#fff', width: 'max-content', padding: '5px',marginBottom: '10px' }}>
                        <a onClick={() => history.push(`/update-nps?value=1`)} className={`${searchParams.get('value') === '1' && 'nps__question-body-quiz-active'}`}>1</a>
                        <a onClick={() => history.push(`/update-nps?value=2`)} className={`${searchParams.get('value') === '2' && 'nps__question-body-quiz-active'}`}>2</a>
                        <a onClick={() => history.push(`/update-nps?value=3`)} className={`${searchParams.get('value') === '3' && 'nps__question-body-quiz-active'}`}>3</a>
                        <a onClick={() => history.push(`/update-nps?value=4`)} className={`${searchParams.get('value') === '4' && 'nps__question-body-quiz-active'}`}>4</a>
                        <a onClick={() => history.push(`/update-nps?value=5`)} className={`${searchParams.get('value') === '5' && 'nps__question-body-quiz-active'}`}>5</a>
                        <a onClick={() => history.push(`/update-nps?value=6`)} className={`${searchParams.get('value') === '6' && 'nps__question-body-quiz-active'}`}>6</a>
                        <a onClick={() => history.push(`/update-nps?value=7`)} className={`${searchParams.get('value') === '7' && 'nps__question-body-quiz-active'}`}>7</a>
                        <a onClick={() => history.push(`/update-nps?value=8`)} className={`${searchParams.get('value') === '8' && 'nps__question-body-quiz-active'}`}>8</a>
                        <a onClick={() => history.push(`/update-nps?value=9`)} className={`${searchParams.get('value') === '9' && 'nps__question-body-quiz-active'}`}>9</a>
                        <a onClick={() => history.push(`/update-nps?value=10`)} className={`${searchParams.get('value') === '10' && 'nps__question-body-quiz-active'}`}>10</a>
                    </div>
                }
                {isAuth && <div style={{ marginBottom: '10px' }}>
                    <button onClick={() => history.push(`/update-nps?nps-result-list=true`)} className={'nps__button'}>To results</button>
                </div>}
            </div>

            {
                (questionsList || []).map((item: any) => {
                    if (item.type === 'input') {
                        return (
                            <div key={item.id} className={'nps__question'}>
                                <div className="nps__question-heading">
                                    {item.message}
                                    {isAuth && <div className={'nps__question-heading-icons'}>
                                        <a onClick={() => updateItem(item)} style={{color: '#545454'}}>&#9998;</a>
                                        <a onClick={() => deleteQuestion(item.id)}
                                           style={{color: '#FF5C5C'}}>&#10005;</a>
                                    </div>}
                                </div>
                                <div className="nps__question-body">
                                    <input
                                        value={values[`message_${item.id}`]}
                                        onChange={e => setValues({ ...values, [`message_${item.id}`]: e.target.value })}
                                    />
                                </div>
                            </div>
                        )
                    }

                    if (item.type === 'textarea') {
                        return (
                            <div key={item.id} className={'nps__question'}>
                                <div className="nps__question-heading">
                                    {item.message}
                                    {isAuth && <div className={'nps__question-heading-icons'}>
                                        <a onClick={() => updateItem(item)}
                                            style={{color: '#545454'}}>&#9998;</a>
                                        <a onClick={() => deleteQuestion(item.id)}
                                           style={{color: '#FF5C5C'}}>&#10005;</a>
                                    </div>}
                                </div>
                                <div className="nps__question-body">
                                    <textarea
                                        rows={6}
                                        value={values[`message_${item.id}`]}
                                        onChange={e => setValues({ ...values, [`message_${item.id}`]: e.target.value })}
                                    />
                                </div>
                            </div>
                        )
                    }

                    if (item.type === 'counter') {
                        return (
                            <div key={item.id} className={'nps__question'}>
                                <div className="nps__question-heading">
                                    {item.message}
                                    {isAuth && <div className={'nps__question-heading-icons'}>
                                        <a onClick={() => updateItem(item)} style={{color: '#545454'}}>&#9998;</a>
                                        <a onClick={() => deleteQuestion(item.id)}
                                           style={{color: '#FF5C5C'}}>&#10005;</a>
                                    </div>}
                                </div>
                                <div className="nps__question-body">
                                    <div className="nps__question-body-quiz">
                                        <a
                                            className={`${values[`message_${item.id}`] === '1' && 'nps__question-body-quiz-active'}`}
                                            onClick={() => setValues({ ...values, [`message_${item.id}`]: '1' })}
                                        >1</a>
                                        <a
                                            className={`${values[`message_${item.id}`] === '2' && 'nps__question-body-quiz-active'}`}
                                            onClick={() => setValues({ ...values, [`message_${item.id}`]: '2' })}
                                        >2</a>
                                        <a
                                            className={`${values[`message_${item.id}`] === '3' && 'nps__question-body-quiz-active'}`}
                                            onClick={() => setValues({ ...values, [`message_${item.id}`]: '3' })}
                                        >3</a>
                                        <a
                                            className={`${values[`message_${item.id}`] === '4' && 'nps__question-body-quiz-active'}`}
                                            onClick={() => setValues({ ...values, [`message_${item.id}`]: '4' })}
                                        >4</a>
                                        <a
                                            className={`${values[`message_${item.id}`] === '5' && 'nps__question-body-quiz-active'}`}
                                            onClick={() => setValues({ ...values, [`message_${item.id}`]: '5' })}
                                        >5</a>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    if (item.type === 'is_confirm') {
                        return (
                            <div key={item.id} className={'nps__question'}>
                                <div className="nps__question-heading">
                                    {item.message}
                                    {isAuth && <div className={'nps__question-heading-icons'}>
                                        <a onClick={() => updateItem(item)} style={{color: '#545454'}}>&#9998;</a>
                                        <a onClick={() => deleteQuestion(item.id)}
                                           style={{color: '#FF5C5C'}}>&#10005;</a>
                                    </div>}
                                </div>
                                <div className="nps__question-body">
                                    <div className="nps__question-body-quiz">
                                        <a
                                            className={`${values[`message_${item.id}`] === 'yes' && 'nps__question-body-quiz-active'}`}
                                            onClick={() => setValues({ ...values, [`message_${item.id}`]: 'yes' })}
                                        >Yes</a>
                                        <a
                                            className={`${values[`message_${item.id}`] === 'no' && 'nps__question-body-quiz-active'}`}
                                            onClick={() => setValues({ ...values, [`message_${item.id}`]: 'no' })}
                                        >No</a>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })
            }


            <div className="nps__buttons">
                {!isAuth && <button onClick={sendQuestions} className={'nps__button mr5'}>Submit</button>}
                {!isAuth && <em onClick={cancelTheSurvey}>Skip Survey</em>}
                {isAuth && <button onClick={() => setIsModalOpen(true)} className={'nps__button'}>Add question</button>}
            </div>
            {isModalOpen && <QuestionModal
                close={() => {
                    setIsModalOpen(false)
                    setUpdatedItem(null)
                }}
                update={() => {
                    getQuestions(searchParams.get('value') || '1').then()
                    setUpdatedItem(null)
                }}
                updatedItem={updatedItem}
            />}
        </div>
    )
}

const QuestionModal: React.FC<any> = ({ close, update, updatedItem }) => {
    const { setLoading } = useContext(MainContextProvider)
    const [values, setValues] = useState<any>({
        default_value: '',
        question: '',
        type: 'input',
        is_required: false,
        order: '0',
        active_in_questions: []
    })

    useEffect(() => {
        if (updatedItem) {
            console.log(updatedItem)
            setValues({
                ...updatedItem,
                question: updatedItem.message,
                active_in_questions: updatedItem.segment.split(',')
            })
        }
    },[updatedItem])

    const createQuestion = async () => {
        if (!values.question.trim()) {
            alert('Question is required')
            return
        }

        const postData = {
            message: values.question,
            type: values.type,
            is_required: values.is_required,
            order: values.order,
            segment: values.active_in_questions.join(','),
            default_value: values.default_value
        }

        setLoading(true)
        const { status, data } = updatedItem ?
            await axios.put(`${process.env.REACT_APP_STRIPE_API_URL}/question/${updatedItem.id}`, postData, { headers: { auth: localStorage.getItem('nps-token') } }) :
            await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/question`, postData, { headers: { auth: localStorage.getItem('nps-token') } })
        if (status === 200 || status === 201) {
            update()
        }
        setLoading(false)
    }

    const updateNumbersValue = (number: string) => {
        if (values.active_in_questions.includes(number)) {
            setValues({ ...values, active_in_questions: values.active_in_questions.filter((el: string) => el !== number) })
        } else {
            setValues({ ...values, active_in_questions: [...values.active_in_questions, number] })
        }
    }

    const isNumberActive = (number: string) => {
        return (values.active_in_questions.includes(number))
    }

    return (
        <div className={'nps__question-modal modal_overflow_hide invite_team_mate create_manager_offer'}>
            <ModalContainer heading={'Nps Question'} isOpen={true} close={close}>
                <div>
                    <Input
                        className={'mt15'}
                        label={'Question'}
                        otherProps={{
                            value: values.question,
                            onChange: e => setValues({ ...values, question: e.target.value })
                        }}
                    />
                    <Input
                        className={'mt15'}
                        label={'Default value'}
                        otherProps={{
                            value: values.default_value,
                            onChange: e => setValues({ ...values, default_value: e.target.value })
                        }}
                    />
                    <Select
                        className={'mt15'}
                        label={'Field type'}
                        value={values.type}
                        options={[
                            { value: 'input', label: 'Input' },
                            { value: 'textarea', label: 'Textarea' },
                            { value: 'counter', label: 'Counter' },
                            { value: 'is_confirm', label: 'yes/no' },
                        ]}
                        onChange={(e) => setValues({ ...values, type: e.value })}
                    />
                    <Input
                        className={'mt15'}
                        label={'Order'}
                        otherProps={{
                            value: values.order,
                            onChange: e => setValues({ ...values, order: e.target.value })
                        }}
                    />
                    <div className={'nps__question-modal-numbers'}>
                        <a onClick={() => updateNumbersValue('1')} className={`${isNumberActive('1') && 'nps__question-modal-numbers-active'}`}>1</a>
                        <a onClick={() => updateNumbersValue('2')} className={`${isNumberActive('2') && 'nps__question-modal-numbers-active'}`}>2</a>
                        <a onClick={() => updateNumbersValue('3')} className={`${isNumberActive('3') && 'nps__question-modal-numbers-active'}`}>3</a>
                        <a onClick={() => updateNumbersValue('4')} className={`${isNumberActive('4') && 'nps__question-modal-numbers-active'}`}>4</a>
                        <a onClick={() => updateNumbersValue('5')} className={`${isNumberActive('5') && 'nps__question-modal-numbers-active'}`}>5</a>
                        <a onClick={() => updateNumbersValue('6')} className={`${isNumberActive('6') && 'nps__question-modal-numbers-active'}`}>6</a>
                        <a onClick={() => updateNumbersValue('7')} className={`${isNumberActive('7') && 'nps__question-modal-numbers-active'}`}>7</a>
                        <a onClick={() => updateNumbersValue('8')} className={`${isNumberActive('8') && 'nps__question-modal-numbers-active'}`}>8</a>
                        <a onClick={() => updateNumbersValue('9')} className={`${isNumberActive('9') && 'nps__question-modal-numbers-active'}`}>9</a>
                        <a onClick={() => updateNumbersValue('10')} className={`${isNumberActive('10') && 'nps__question-modal-numbers-active'}`}>10</a>
                    </div>
                    {/*<CheckboxItem
                        label={'Is required'}
                        active={values.is_required}
                        onChange={() => setValues({ ...values, is_required: !values.is_required })}
                    />*/}
                    <button onClick={createQuestion} className={'btn-black w100 text-uppercase w100 mt20'}>Save</button>
                </div>
            </ModalContainer>
        </div>
    )
}