import React, {useContext, useEffect, useState} from "react";
import "./vlo-partner-request.scss"
import circleIcon from "../../../../../assets/images/createIcon.svg"
import arrowIcon from "../../../../../assets/images/white-right-arrow.svg"
import ModalContainer from "../../../../../components/ModalContainer/ModalContainer";
import customAxios from "../../../../../service/api/interceptors";
import Input from "../../../../../components/UI/Input/Input";
import Select from "../../../../../components/UI/Select/Select";
import {ReactEditor} from "../../../../../components/UI";
import {controlErrorFunc} from "../../../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../../../service/toastify/toastify";
import {ModalLoader} from "../../../../../components/other/ModalLoader/ModalLoader";
import {dateFormatter} from "../../../../../helpers/dateFormatter";
import {partner_request_statuses} from "../../../../ServiceProvider/partner_request_statuses";
import {useHistory} from "react-router-dom";
import {MainContextProvider} from "../../../../../context/MainContext";

type Props = {
    offer_id: string
    offerData: any
    setTabsCounter: (key: number) => void
}

const request_status_for_vlo = (status: string) => {
    switch (status) {
        case '0': return  'new';
        case '1': return  'Service Provider interested';
        case '2': return  'Service Provider not interested';
        case '3': return  'Estimates provided';
        case '4': return  'Estimates reviewed';
        case '5': return  'Client accepted';
        case '6': return  'Partner request has expired';
        case '7': return  'CLIENT HAS DECLINED';
    }
}

export const VloPartnerRequest: React.FC<Props> = ({ offer_id, offerData, setTabsCounter }) => {
    const { setModalType, setModalData, userData } = useContext(MainContextProvider)
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const history = useHistory()

    const getData = async () => {
        setModalIsOpen(false)
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/partner-request/offer/${offer_id}?order_by=title&direction=DESC`)
            if (status === 200) {
                const count = data.reduce((value: number, currentValue: any) => {
                    value += +currentValue.comments
                    return value
                }, 0)
                setTabsCounter(count)
                setItems(data)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getData().then()
    }, [])

    return (
        <div className={'vlo-partner-request'} style={{ position: 'relative' }}>
            {loading && <ModalLoader />}
            <h4>Request List</h4>
            {userData?.status.type_code === 300 && <button onClick={() => setModalIsOpen(true)} className="vlo-partner-request__button">
                <div className="vlo-partner-request__circle">
                    <img src={circleIcon} alt=""/>
                </div>
                <span>CREATE REQUEST</span>
            </button>}
            {
                (items || []).map((el: any) => (
                    <div className="vlo-partner-request__item singleOffer__deliverables-task w100" style={{ border: '1px solid rgb(202, 213, 221)' }}>
                        <div className="singleOffer__deliverables-task-title">
                            {el?.title}
                        </div>
                        <div className="singleOffer__deliverables-task-info" style={{ padding: '0 15px' }}>
                            <img
                                onClick={() => {
                                    /*setModalType('manager_profile_modal')
                                    setModalData({ id: el?.provider?.id })*/
                                }}
                                src={el?.provider?.avatar_url} className={'vlo-partner-request__provider'} alt=""/>
                            <div className="singleOffer__deliverables-task-status">
                                <div className="singleOffer__deliverables-task-label">
                                    Status:
                                </div>
                                <div style={{ color: '#2361FF' }} className="singleOffer__deliverables-task-text">
                                    {request_status_for_vlo(el?.status)}
                                </div>
                            </div>
                            <div className="singleOffer__deliverables-task-status">
                                <div className="singleOffer__deliverables-task-label">
                                    Updated on:
                                </div>
                                <div className="singleOffer__deliverables-task-text">
                                    {dateFormatter(el.updated_at)}
                                </div>
                            </div>
                            <div
                                onClick={() => history.push(`/partner-request/${el.id}`)} className="vlo-partner-request__item-button">
                                <img width={14} src={arrowIcon} alt=""/>
                            </div>
                        </div>
                    </div>
                ))
            }

            <CreateRequest
                close={() => setModalIsOpen(false)}
                update={getData}
                modalData={modalIsOpen}
                offerData={offerData}
                offerId={offer_id}
            />
        </div>
    )
}

const initialData = {
    title: '',
    description: '',
    provider_id: ''
}

const CreateRequest: React.FC<any> = ({ close, update, modalData, offerData, offerId }) => {
    const [loading, setLoading] = useState(false)
    const [providers, setProviders] = useState([])
    const [values, setValues] = useState(initialData)
    const [errors, setErrors] = useState<{[key: string]: string}>({})

    const getInitialData = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/user/provider/all-providers`)
            setValues({
                ...initialData,
                /*title: offerData.title,
                description: offerData.description,*/
                provider_id: data[0].id
            })
            setProviders(data.map((el: { id: string, name: string }) => ({ value: el.id, label: el.name })))
        } catch (e){}
        setLoading(false)
    }

    const createRequest = async () => {
        const validReq = controlErrorFunc({ obj: values, config: {
                title: { required: true, maxLength: 255 }, provider_id: { required: true }, description: { required: true }
            }})
        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        setLoading(true)
        const { title, description, provider_id } = values
        try {
            const { status, data } = await customAxios.post(`/partner-request/create`, {
                title, description, provider_id, offer_id: offerId
            })
            if (status === 200) {
                update()
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        if (modalData) {
            getInitialData().then()
        }
    }, [modalData])

    return (
        <div className={'vlo-partner-request-modal create_manager_offer'} style={{ position: "relative" }}>
            {loading && <ModalLoader />}
            <ModalContainer heading={'Create Partner Request'} isOpen={modalData} close={close}>
                <div className={'mt5'}>
                    <p className={'create_manager_offer__description'}>
                        Choose from the list of add-ons and partners for your partner request
                    </p>
                    <Select
                        label={'Choose partner'}
                        value={values.provider_id + ''}
                        onChange={e => setValues({ ...values, provider_id: e.value })}
                        options={providers}
                        valid={!errors?.provider_id}
                        errorMessage={errors.provider_id}
                    />
                    <h5 className={'vlo-partner-request-modal__description'}>Choose title and description</h5>
                    <Input
                        label={'Title'}
                        className={'mt15'}
                        otherProps={{
                            value: values.title,
                            onChange: e => setValues({ ...values, title: e.target.value })
                        }}
                        valid={!errors?.title}
                        errorMessage={errors.title}
                    />
                    <ReactEditor
                        className={'mt10'}
                        value={values.description}
                        onChange={text => setValues({ ...values, description: text })}
                        valid={!errors?.description}
                        errorMessage={errors.description}
                    />
                    <button onClick={createRequest} className={'btn-black w100 text-uppercase mt25'}>
                        Create
                    </button>
                </div>
            </ModalContainer>
        </div>
    )
}