import React from 'react'
import './textarea.scss'
import { useTranslation } from 'react-i18next'
import { TextareaType } from './textarea.type'

const TextArea:React.FC<TextareaType> = ({
  label,
  className,
  errorMessage,
  otherProps,
  valid = true,
}) => {
  const { t } = useTranslation()
  return (
    <div className={`textarea ${valid ? 'textarea__invalid' : ''} ${className || ''}`}>
      {label && <label className={'textarea__label'}>{label}</label>}
      <textarea
        {...otherProps}
      />
      {!valid
                && <span className={'textarea__error-message'}>{errorMessage || t('requiredField')}</span>}
    </div>
  )
}

export default TextArea
