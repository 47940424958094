import React, {useContext} from "react";
import './LegalTeam.scss'
import infoIcon from '../../../../assets/images/blackInfoIcon.svg'
import clientIcon from '../../../../assets/images/client.png'
import {MainContextProvider} from "../../../../context/MainContext";

type TeamItemType = {
    id: number
    avatar_path: string
    fullname: string
    title: string
    type: string
}

type LegalTeamType = {
    items: TeamItemType[]
}

const TeamItem: React.FC<TeamItemType> = ({ fullname, title, avatar_path, id, type }) => {
    const { setModalData, setModalType, userData } = useContext(MainContextProvider)
    return (
        <div
            className={'legal-team__item'}>
            <div className={'legal-team__item-img'}>
                <img
                    onClick={() => {
                        userData && setModalData({ id, type })
                        userData && setModalType('manager_profile_modal')
                    }}
                    style={{ height: '36px' }} src={avatar_path || clientIcon} alt=""/>
            </div>
            <div className={'legal-team__item-data'}>
                <div className="legal-team__item-name">{fullname}</div>
                <div className="legal-team__item-position">{title}</div>
            </div>
        </div>
    )
}

export const LegalTeam: React.FC<LegalTeamType> = ({ items }) => {
    const { userData } = useContext(MainContextProvider)

    return (
        <div className={'legal-team'}>
            <div className={'legal-team__heading'}>
                <h4>Your Virtual Legal Team</h4>
                {userData?.status.type_code === 100 && <div className={'legal-team__tooltip'}>
                    <img src={infoIcon} alt=""/>
                    <div className={'legal-team__tooltip-dropdown'}>
                        This is your Virtual Legal Team who are assigned to this project. Your Virtual Legal Team will
                        handle all the
                        tasks needed to solve your request. Their work is orchestrated by your Virtual Legal Officer.
                    </div>
                </div>}
            </div>
            <div className="legal-team__items">
                {
                    items.map((item) => <TeamItem key={item.id} {...item} />)
                }
            </div>
        </div>
    )
}