import React, {useContext} from "react";
import ModalContainer from "../../../components/ModalContainer";
import logoIcon from "../../../assets/images/logo.svg";
import {MainContextProvider} from "../../../context/MainContext";

type Props = {
    isOpen: boolean
    close: () => void
    ok: () => void
    title: string
    subtitle: string
    continueButtonTitle: string
}

export const CreditApproveModal: React.FC<Props> = ({
   close, isOpen, subtitle, title, ok, continueButtonTitle
}) => {
    const { creditCount } = useContext(MainContextProvider)

    return (
        <ModalContainer isOpen={isOpen} close={close}>
            <div>
                <div className={'flex justifyCenter'}>
                    <img src={logoIcon} alt=""/>
                </div>
                <div className="heading4 text-center mt40">
                    {title}
                </div>
                <p className={'p1 color-blue text-uppercase text-center mt20'}>
                    {subtitle}
                </p>
                <div className={'mt20'}>
                    <div className={'planner-page__modal-container'}>
                        Credits Balance: <b className={'text-weight-600'}>{creditCount.total_count}</b>
                    </div>
                </div>
                <div>
                    <button onClick={ok} className={'btn btn-black w100 text-uppercase mt40'}>
                        {continueButtonTitle}
                    </button>
                    <button onClick={close} className={'btn-white w100 text-uppercase mt10'}>
                        GO BACK
                    </button>
                </div>
            </div>
        </ModalContainer>
    )
}