import React, {useContext, useRef, useState} from "react";
import './ClientKanbanItem.scss'
import dotsIcon from '../../../../assets/images/kanbanIcons/dots.svg'
import doneIcon from '../../../../assets/images/kanbanIcons/done.svg'
import draftIcon from '../../../../assets/images/kanbanIcons/draft.svg'
import notificationIcon from '../../../../assets/images/kanbanIcons/notif.svg'
import offerIcon from '../../../../assets/images/kanbanIcons/offer.svg'
import requestIcon from '../../../../assets/images/kanbanIcons/request.svg'
import clipIcon from '../../../../assets/images/icons/kanban-item-clip.svg'
import emailIcon from '../../../../assets/images/kanbanIcons/whiteEmailSendIcon.svg'
import {DropDownNotifications} from "../../ClientKanbanData/DropDownNotifications/DropDownNotifications";
import {MainContextProvider} from "../../../../context/MainContext";
import {ClientKanbanStepNotifications} from "../ClientKanbanStepNotifications/ClientKanbanStepNotifications";
import {CSSTransition} from "react-transition-group";
import {useOnClickOutside} from "../../../../hooks/useOnClickOutside";
import customAxios from "../../../../service/api/interceptors";
import {dateFormatter} from "../../../../helpers/dateFormatter";
import {NavLink, useHistory} from "react-router-dom";

export const statuses = (statusText: string) => {
    switch (statusText) {
        case "-1": return {
            background: '#8E9EC8',
            text: "REQUEST",
            slug: 'request',
            icon: requestIcon
        }
        case "0": return {
            background: 'rgb(142, 158, 200)',
            text: "DRAFT",
            slug: 'request',
            icon: draftIcon
        }
        case "1": return {
            background: '#F3B71B',
            text: "PROJECT APPROVED",
            slug: 'request',
            icon: offerIcon
        }
        case "2": return {
            background: '#000',
            text: "ESTIMATES SENT TO THE CLIENT",
            slug: 'offer',
            icon: emailIcon
        }
        case "3": return {
            background: '#F3B71B',
            text: "AWAITING PAYMENT",
            slug: 'tasks_assigned',
            icon: requestIcon
        }
        case "9": return {
            background: '#2361FF',
            text: "Tasks Assigned",
            slug: 'tasks_assigned',
            icon: requestIcon
        }
        case "4": return {
            background: '#5D8C51',
            text: "DONE",
            slug: 'done',
            icon: doneIcon
        }
        case "5": return {
            background: '#8E9EC8',
            text: "DRAFT",
            slug: "draft",
            icon: draftIcon
        }
        case "6": return {
            background: 'rgb(142, 158, 200)',
            text: "Request",
            slug: "request",
            icon: draftIcon
        }
    }
}

type ClientKanbanItemType = {
    id: number
    title: string
    next_step: string
    description: string
    company_id: number
    status: number
    nextStep: string
    statusText: string
    manager: {
        name: string
        photos: string
    }
    stars: number[]
    onClick: any
    updateBoard: () => {}
    isWaitIcon?: boolean
    comments: number
    notifications: number
    company: {
        id: number
        manager_id: number
        name: string
        type: string
    }
    client: {
        avatar_path: string
    }
    clients: [{
        fullname: string
        avatar_path: string
    }]
    accepted: string
    is_done?: boolean
    hasTasks?: number
    last_comment?: {
        file_id: number
        file: any[]
        text: string
    }
    last_comment_author?: {
        avatar_url: string
        id: number
        name: string
        title: string
    }
    last_comment_time?: string
    is_archived: string
    to: string
    colIndex: number
    itemIndex: number
    index: number
}

export const ClientKanbanItem: React.FC<ClientKanbanItemType> = ({ to, index,
    id, title, comments, notifications, next_step, statusText, is_done, hasTasks, clients,
    nextStep,stars, onClick, isWaitIcon, manager, company, client, accepted, status,
    last_comment, last_comment_author, last_comment_time, updateBoard, is_archived, colIndex
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const { userData, setLoading } = useContext(MainContextProvider)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })
    const history = useHistory()

    const setOfferToArchived = async (key: number) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`company/offers/${id}`, { is_archived: key })
            if (status === 200) {
                if (key === 1)
                    updateBoard()
                else
                    history.push('/dashboard')
            }
        } catch (e) {}
        setLoading(false)
    }

    const setLegalRequestToArchived = async () => {
        try {
            const { status, data } = await customAxios.put(`company/offers/${id}`, { is_archived: 1 })
            if (status === 200) updateBoard()
        } catch (e) {}
    }

    const handleChildClick = (event: any) => {
        event.preventDefault();
        console.log('div clicked');
    }

    const handleParentClick = (event: any) => {
        event.stopPropagation()
    }

    if (userData?.status.type_code === 300 || userData?.status.type_code === 400 || userData?.status.type_code === 100) {
        return <NavLink className={`status_text_${statuses(statusText)?.slug} external_number_class_${colIndex + 1} element-index_${index}`} to={to} onClick={handleParentClick} style={{ textDecoration: 'none' }}>
            <div className={`client-kanban-item`}>
                {(userData?.status.type_code === 300 || userData?.status.type_code === 400) && <div className="client_kanbanStep_notifications client_kanbanStep_notifications__kanban"
                      style={{background: statuses(statusText)?.background, padding: "20px 10px"}}>
                    <div className={'client_kanbanStep_notifications__info'}>
                        <img className={'client_kanbanStep_notifications__img'} src={statuses(statusText)?.icon}
                             alt=""/>
                        <span style={{color: '#FFF'}}>{statuses(statusText)?.text}</span>
                    </div>
                    {userData?.status.type_code === 300 &&
                        <div ref={dropdownRef} className={'dropdown'} onClick={event => {
                            handleChildClick(event);
                            handleParentClick(event);
                        }}>
                            <img onClick={() => setIsOpen(!isOpen)} src={dotsIcon} alt=""/>
                            <CSSTransition in={isOpen} classNames={'my-node'} timeout={200} unmountOnExit>
                                {+is_archived === 0 ? <>
                                    <div className="header__right-link-squire"/>
                                    <div className={'dropdown__container'} style={{padding: 0}}>
                                        <a onClick={() => +statusText === 1 ? setLegalRequestToArchived() : setOfferToArchived(1)}
                                           className={'client-kanban-item__dropdown-link'}>Archive</a>
                                    </div>
                                </> : <>
                                    <div className="header__right-link-squire"/>
                                    <div className={'dropdown__container'} style={{padding: 0}}>
                                        <a onClick={() => setOfferToArchived(0)}
                                           className={'client-kanban-item__dropdown-link'}
                                           style={{textTransform: 'lowercase', width: '150px'}}>BRING BACK FROM THE
                                            ARCHIVE</a>
                                    </div>
                                </>}
                            </CSSTransition>
                        </div>}
                </div>}
                {
                    userData?.status.type_code === 100 && (
                        <ClientKanbanStepNotifications
                            place={'kanban'}
                            accepted={status === 100 || status === 300 ? status + '' : accepted}
                            is_done={is_done ? 1 : 0}
                            hasTasks={hasTasks || 0}
                        />
                    )
                }
                <div className="client-kanban-item__body" style={{ padding: '15px 10px' }}>
                    <div className="client-kanban-item__body-title" title={title}>
                        {
                            title.length > 60 ? `${title.slice(0, 60)}...` : title
                        }
                    </div>
                    <div className={"client-kanban-item__company flex-between"}>
                        <span className={"client-kanban-item__company-name"}>{company.name}</span>
                        {notifications > 0 && <img src={notificationIcon} alt=""/>}
                    </div>
                </div>
                {last_comment ? <div className="client-kanban-item__manager-footer">
                    <div className="client-kanban-item__contact">
                        <img className="client-kanban-item__contact-img" src={last_comment_author?.avatar_url} alt=""/>
                        <div className={'client-kanban-item__contact-name'}>
                            <div className={'client-kanban-item__contact-name-top'}>
                                <span>{last_comment_author?.name}</span>
                                <div className="client-kanban-item__comment-date">
                                    {/*{dateFormatter(last_comment_time + '')}*/}
                                    {/*// @ts-ignore*/}
                                    {dateFormatter((+(new Date()) / 1000) + '') === dateFormatter(last_comment_time + '') ?
                                        dateFormatter(last_comment_time + '', true).slice(0, -3) :
                                        dateFormatter(last_comment_time + '')}
                                </div>
                            </div>
                            <div className={'client-kanban-item__contact-message'} style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    {(last_comment.file || []).length > 0 ? <img style={{ marginRight: '3px' }} src={clipIcon} alt=""/> : null}
                                    {
                                        last_comment.text.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').length > 30 ?
                                            `${last_comment.text.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').slice(0, 30)}...` :
                                            last_comment.text.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')
                                    }
                                </div>
                                {+comments > 0 && <div className={"client-kanban-item__comment-items"}>{comments}</div>}
                            </div>
                        </div>
                    </div>
                </div> : <div className="flex-center client-kanban-item__manager-footer" style={{ justifyContent: 'center' }}>
                    <em className={'client-kanban-item__manager-footer-empty'}> No comments yet...</em>
                </div>}
            </div>
        </NavLink>
    }

    return (
        <div onClick={onClick} className={`client-kanban-item status_text_${statuses(statusText)?.slug} external_number_class_${colIndex + 1} element-index_${index}`}>
            <div>
                <ClientKanbanStepNotifications
                    place={'kanban'}
                    accepted={status === 100 || status === 300 ? status + '' : accepted}
                    is_done={is_done ? 1 : 0}
                    hasTasks={hasTasks || 0}
                />
            </div>
            <div className="client-kanban-item__body">
                <div className="client-kanban-item__body-title">
                    {title}
                </div>
                {userData?.status.type_code !== 100 && <div className="client-kanban-item__steps">
                    {statusText !== '4' && <div className={'client-kanban-item__steps-name'}>Next step:</div>}
                    <div className="client-kanban-item__steps-step">{nextStep}</div>
                </div>}
            </div>
            <div className="client-kanban-item__footer">
                <div className="client-kanban-item__icons">
                    <DropDownNotifications
                        show={{
                            wait: isWaitIcon,
                            comments: +comments,
                            notifications: +notifications
                        }}
                    />
                </div>
                <div className="client-kanban-item__users">
                    {/*{
                        userData?.status.type_code === 100 && Array.isArray(clients) && clients.map(item => (
                            <img
                                style={{ width: '21px', height: '21px', borderRadius: '4px', marginLeft: '3px' }}
                                src={item.avatar_path} alt=""/>
                        ))
                    }*/}
                    {
                        userData?.status.type_code === 300 && clients && clients.map(item => (
                            <div className={'client-kanban-item__user'}>
                                {clients.length === 1 && <span>{item?.fullname}</span>}
                                <img style={{ width: '27px', height:'27px' }} src={item?.avatar_path} alt=""/>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}