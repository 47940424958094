import React, {useRef, useState} from 'react'
import { CSSTransition } from 'react-transition-group'
import moreVerticalIcon from '../../../assets/images/moreVertical.svg'
import './dropdown.scss'
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";

type Props = {
  arr: { label: string, value: string, onClick: (key: string) => void, disabled?: boolean }[]
}

const DropDown: React.FC<Props> = ({ arr }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

  return (
    <div className={'dropdown'} ref={dropdownRef}>
      <button className={'dropdown__button'} onClick={() => setIsOpen(!isOpen)}><img src={moreVerticalIcon} alt={''} /></button>
      <CSSTransition in={isOpen} classNames={'my-node'} timeout={200} unmountOnExit>
        <div className={'dropdown__container'}>
          {
              arr.map(({ label, value, onClick, disabled }) => (
                <div
                  onClick={(event) => !disabled && onClick(value)}
                  className={`${disabled && 'dropdown__disabled'} dropdown__link`}
                >
                  {label}
                </div>
              ))
          }
        </div>
      </CSSTransition>
    </div>
  )
}

export default DropDown
