import {useEffect, useState} from "react";
import axios from "axios";
import moment from "moment";
import React from "react";
import {useHistory} from "react-router-dom";

export const NpsAdmin = () => {
    const history = useHistory()
    const [list, setList]= useState([])
    const [reportCounts, setReportCounts] = useState({
        sum: 0,
        all: 0,
        good: 0,
        normal: 0,
        angry: 0
    })

    const getData = async () => {
        const { status, data } = await axios.get(`${process.env.REACT_APP_STRIPE_API_URL}/nps/results`, {
            headers: { auth: localStorage.getItem('nps-token') }
        })

        const { data: reportData } = await axios.get(`${process.env.REACT_APP_STRIPE_API_URL}/nps/report`, {
            headers: { auth: localStorage.getItem('nps-token') }
        })
        if (reportData) setReportCounts(reportData)

        if (status === 200) {
            setList(data)
        }
    }

    useEffect(() => {
        getData()
    },[])

    return (
        <div className={'nps-results'} style={{ maxWidth: '800px', margin: '0 auto', marginTop: '20px' }}>
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div></div>
                <button onClick={() => history.push(`/update-nps`)} className={'nps__button'}>To questions</button>
            </div>
            <div className={'nps-results__item'}>
                <div className="nps-results__item-heading">
                    <div style={{ fontSize: '16px', fontWeight: 'bold' }}>REPORT</div>
                </div>
                <div className={'nps-results__item-body'}>
                    <ul>
                        <li><b style={{ color: '#bbb' }}>Average NPS score:</b> {(reportCounts.sum / reportCounts.all).toFixed()}</li>
                        <li><b style={{ color: '#bbb' }}>Total NPS score:</b> {((((reportCounts.good / reportCounts.all) * 100)) - ((reportCounts.angry / reportCounts.all) * 100)).toFixed()}%</li>
                        <li><b style={{ color: '#bbb' }}>Number and % of Promoters, Detractors, and Passives</b> </li>
                        <li><b style={{ color: '#bbb' }}>-- Promoters:</b> {((reportCounts.good / reportCounts.all) * 100).toFixed()}%</li>
                        <li><b style={{ color: '#bbb' }}>-- Passives:</b> {((reportCounts.normal / reportCounts.all) * 100).toFixed()}%</li>
                        <li><b style={{ color: '#bbb' }}>-- Detractors:</b> {((reportCounts.angry / reportCounts.all) * 100).toFixed()}%</li>
                        <li><b style={{ color: '#bbb' }}>Total Submitted Answers</b> {reportCounts.all}</li>
                    </ul>
                </div>
            </div>
            {
                (list || []).map(item => (
                    <div className={'nps-results__item'}>
                        <div className="nps-results__item-heading">
                            <div>{item.platform_user?.name}</div>
                            <div>{moment(item.created_at).format('DD-MM-YYYY HH:mm')}</div>
                        </div>
                        <div style={{ fontSize: '13px', marginTop: '10px' }}>
                            {item.platform_user?.companies.map(el => (el.name)).join(', ')}
                        </div>
                        <div className={'nps-results__item-body'}>
                            <ul>
                                <li><b style={{ color: '#bbb' }}>NPS:</b> {item.value}</li>
                                {
                                    (item.questions || []).map(question => (
                                        <li><b style={{ color: '#bbb' }}>{question?.question.message}</b>: <b style={{ textDecoration: 'underline' }}>{question.value}</b></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}