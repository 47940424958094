import {useContext, useEffect, useState} from "react";
import axios from "axios";
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";

var serverStatus = 0
export const ServerReload = () => {
    const { setModalData, setModalType } = useContext(MainContextProvider)

    const getData = async () => {
        try {
            const { status, data } = await customAxios.get(`user/profile/simple`)
            if (status === 200) {
                if (serverStatus === 1) {
                    setModalType('')
                    setModalData(null)
                } else {
                    setTimeout(getData, 3000)
                }
            }
        } catch (e: any) {
            setTimeout(getData, 3000)
            serverStatus = 1
        }
    }

    useEffect(() => {
        setTimeout(getData, 3000)
    }, [])

    return (
        <div style={{ fontSize: '20px', textAlign: 'center', paddingBottom: '10px', lineHeight: '22px' }}>
            <b style={{ display: 'block', color: 'red', fontWeight: 'bold', fontSize: '24px', marginBottom: '5px' }}>Please do not refresh the page</b>
            The server is restarting. Please wait for a few minutes. The information window will disappear after the restart.
        </div>
    )
}