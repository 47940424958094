import {UpdateNps} from "./UpdateNps";
import logoIcon from "../../../assets/images/logo.svg"
import {useHistory} from "react-router-dom";

export const NpsNoAuthRoute = () => {
    const history = useHistory()

    return (
        <div className={'layout'}>
            <header className={'header'}>
                <div className="header__left">
                    <img src={logoIcon} alt="" className="header__logo"/>
                </div>
                <div className="header__right mr10">
                    <div />
                    <button onClick={() => history.push('/')} className={'btn btn-black text-uppercase'}>Sign in</button>
                </div>
            </header>
            <div style={{ maxWidth: '1600px' }}>
                <UpdateNps isUserAuth={true} />
            </div>
        </div>
    )
}