import React, { useContext, useEffect, useState } from 'react'
import { ReactEditor } from '../../../components/UI'
import { MainContextProvider } from '../../../context/MainContext'
import './deliverables-modal.scss'
import { updateTask } from '../../../service/tasks'
import { PostTaskType } from '../../../types/task.type'
import { useTranslation } from 'react-i18next'
import controlErrorValid from "../../../components/UI/ControlValid/controlErrorValid";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import customAxios from "../../../service/api/interceptors";
import {updateOfferDeliverablesService} from "../../../service/client/offer";

const OfferDeliverablesModal = () => {
  const { setModalData, modalData, setModalType } = useContext(MainContextProvider)
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation()

  const updateElement = async (type:string = 'update') => {
    const validRes = controlErrorValid(value, {required: true, maxLength: 10000})
    if(validRes) {
      setErrorMessage(validRes + '')
      return false;
    }
    setErrorMessage('')

    setLoading(true)
    try {
      const { status } = await updateOfferDeliverablesService({ offer_id: modalData?.offer_id, deliverables: value })
      if(status === 200) {
        setModalType('')
        setModalData({ update: true })
      }
    } catch (e) {}
    setLoading(false)
  }

  useEffect(() => {
    if(modalData?.offer_id) {
      setValue(modalData?.deliverables)
    }
    setLoading(false)
  }, [modalData])

  return (
    <div className={'create_milestones__body'} style={{ position: 'relative' }}>
      {loading && <ModalLoader />}
      <ReactEditor
        value={value}
        onChange={(event:string) => setValue(event)}
        valid={!errorMessage}
        errorMessage={errorMessage}
      />
      <button
        onClick={() => updateElement('update')}
        className={'create_milestones__save btn-black'}
      >
        {t('deliverablesModal.button')}
      </button>
    </div>
  )
}

export default OfferDeliverablesModal
