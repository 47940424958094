import "./payment-plan.scss"
import React, {useContext, useEffect, useState} from "react";
import {PlanInformation} from "./PlanInformation/PlanInformation";
import customAxios from "../../../../service/api/interceptors";
import {PaymentPlanItems} from "./PaymentPlanItems/PaymentPlanItems";
import {PaymentPlanCredits} from "./PaymentPlanCredits/PaymentPlanCredits";
import {useHistory} from "react-router-dom";
import {CompanyType} from "../../../../types/company.type";
import ModalContainer from "../../../../components/ModalContainer";
import logoIcon from "../../../../assets/images/logo.svg";
import {MainContextProvider} from "../../../../context/MainContext";
import {PaymentSuccessfulModal} from "./PaymentSuccessfulModal/PaymentSuccessfulModal";
import {useIntercom} from "react-use-intercom";

export const PaymentPlan = () => {
    const { showNewMessages } = useIntercom()
    const { setLoading, creditCount } = useContext(MainContextProvider)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [credits, setCredits] = useState(1);
    const [finishDate, setFinishDate] = useState<string | null>(null)
    const history = useHistory()
    const [companies, setCompanies] = useState<CompanyType[]>([])
    const [subscription, setSubscription] = useState<{ [key: string]: string }>({})
    const [upcomingPlan, setUpcomingPlan] = useState<{ [key: string]: string } | null>(null)
    const [planIndex, setPlanIndex] = useState(0)
    const [companyCredits, setCompanyCredits] = useState({
        total_count: 0,
        covered_by_monthly_plan: 0,
        purchased: 0,
        expiration_covered_by_monthly_plan: 0,
        expiration_purchased: 0,
    })

    const getFinishDate = async (company_id: string) => {
        try {
            const { data } = await customAxios.get(`company/${company_id}/subscription/expiration-date`)
            if (data?.expiration_date) setFinishDate(data.expiration_date)
        } catch (e) {}
    }

    const getData = async () => {
        setLoading(true)
        try {
            const { data: companies, status: companiesStatus } = await customAxios.get(`/company/company/get-companies`)
            const { data: allSubscriptions } = await customAxios.get(`/user/client/info/subscriptions`)
            const { data: subscriptionData, status: subscriptionsStatus } = await customAxios.get(`/company/${companies[0].id}/subscription`)
            const { data: credits, status: creditsStatus } = await customAxios.get(`/company/${companies[0].id}/credits`)

            if (allSubscriptions[1]) {
                setUpcomingPlan(allSubscriptions[1])
            }

            if (creditsStatus === 200) {
                setCompanies(companies)
                if (companies.length > 0) {
                    await getFinishDate(companies[0].id)
                }
            }

            if (subscriptionsStatus === 200 && subscriptionData) {
                setSubscription(subscriptionData)
            }

            if (creditsStatus === 200) {
                setCompanyCredits({ ...credits })
                // setCredits({ ...credits })
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        document.title = 'Manage My Plan & Credits'
        getData().then()
    }, [])

    const createCreditInvoice = async (key: number) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`/invoices/company/${companies[0]?.id}/create-credit`, {
                credits_count: key
            })

            if (status === 200) {
                history.push(`/checkout/${data.id}?page-type=credit`)
            }
        } catch (e) {}
        setLoading(false)
    }
    
    const cancelPlan = async () => {
        setLoading(true)
        const {} = await customAxios.post(`subscription/${subscription.id}/unsubscribe`)
        setLoading(false)
    }

    return (
        <div className="payment-plan">
            <h2>Manage My Plan & Credits</h2>
            <div className={'payment-plan__naming'}>
                <div className="payment-plan__naming-name">
                    <div className="payment-plan__tabs">
                        <div onClick={() => setPlanIndex(0)} className={`${planIndex === 0 && 'payment-plan__tab-active'} payment-plan__tab`}>Current Plan</div>
                        {upcomingPlan && <div onClick={() => setPlanIndex(1)} className={`${planIndex === 1 && 'payment-plan__tab-active'} payment-plan__tab`}>Upcoming Plan</div>}
                    </div>
                </div>
                <div className="payment-plan__naming-contact-us">
                    Need to add a new business project? <a onClick={() => showNewMessages('')}>Contact Us</a>
                </div>
            </div>
            <PlanInformation
                companyCredits={companyCredits}
                plan_id={planIndex === 0 ? subscription?.id : (upcomingPlan?.id || '')}
                plan_name={planIndex === 0 ? subscription?.title : (upcomingPlan?.title || '')}
                payment_period={planIndex === 0 ? (subscription?.payment_period || '1') : (upcomingPlan?.payment_period || '1')}
                plan_date={finishDate}
                cancelPlan={cancelPlan}
                updateSubscriptions={getData}
                is_sub_paused={planIndex === 0 ? (+subscription?.is_sub_paused === 1 ? "1" : "0") : (+(upcomingPlan?.is_sub_paused || 1) === 1 ? "1" : "0")}
                showNewMessages={(key: string) => showNewMessages(key)}
                isUpcomingTab={planIndex === 1}
                isUpcoming={!!upcomingPlan}
                isSubscriptionActive={+subscription.active === 1}
            />
            <PaymentPlanItems
                subscriptionTitle={planIndex === 0 ? subscription?.title : (upcomingPlan?.title || '')}
                isUpcomingTab={planIndex === 1}
            />
            <PaymentPlanCredits
                creditsCount={credits}
                updateCredits={(count: number) => setCredits(count)}
                createInvoice={createCreditInvoice}
            />
            <InfoModal
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
            />
        </div>
    );
}


const InfoModal: React.FC<{ isOpen: boolean, close: () => void }> = ({ isOpen, close }) => {
    return (
        <div className={'payment-plan-information-modal'}>
            <ModalContainer isOpen={isOpen} close={close}>
                <div>
                    <div className={'payment-plan-information-modal__logo'}>
                        <img src={logoIcon} alt=""/>
                    </div>
                    <div className={'payment-plan-information-modal__text'}>
                        <h3>How can I use my credits?</h3>
                        <p>
                            Use your Credits to create new legal requests. For your VLO to begin working on your legal request,
                            you must pay 1 Credit per request. Some legal requests are simple and can be completed without additional cost.
                        </p>
                        <p>
                            For more complex legal requests, your VLO will provide an estimate on any additional Credits that may be required.
                            You will be notified each time credits are deducted from your balance.
                        </p>
                        <h3>Credits included in your subscription</h3>
                        <p>
                            Each month that your subscription is active, you will receive a number of Credits to spend on your business project.
                            Make sure to use them within the month, as they cannot be transferred to the next month of your subscription. Please check
                            the expiry dates and use your credits before they expire. We will always redeem your credits covered by your monthly plan
                            first before redeeming any additional credits that you have purchased.
                        </p>
                        <h3>What if my Credits balance is insufficient?</h3>
                        <p>
                            If you are unable to proceed with a request, you can buy additional Credits to top up your balance.
                            We recommend purchasing Credits in advance to avoid delays in processing your requests. You can use our
                            Credit bundles to quickly top up your balance.
                        </p>
                        <button onClick={close} className={'btn-black w100 mt20'}>OK</button>
                    </div>
                </div>
            </ModalContainer>
        </div>
    )
}