import customAxios from './api/interceptors'
// @ts-ignore
import qs from 'qs'

type TTracker = {
  user_id: string
  user_type: number | undefined
  event_name: string
  event_data?: unknown
}

function toTimestamp() {
  const datum = Date.parse(String(new Date()))
  return datum / 1000
}

export const sendEvent = (name: string, data: any) => {
  if (window.location.host !== 'localhost:30001') {
    return customAxios.post('data/event', { event_name: name, event_data: data }, )
  }
}
