import React from "react";
import {PartnerRequestType} from "../PartnerRequestType";
import {dateFormatter} from "../../../../helpers/dateFormatter";

type Props = {
    data: PartnerRequestType
}

export const PartnerRequestBody: React.FC<Props> = ({ data }) => {
    return (
        <div className={'partner-request-view__data'}>
            <div className="partner-request-view__key-value">
                <div className="partner-request-view__key-value-key">
                    UPDATED ON:
                </div>
                <div className="partner-request-view__key-value-value">
                    {dateFormatter(data?.updated_at)}
                </div>
            </div>
            <h4 className="partner-request-view__title">
                {data?.title}
            </h4>
            <div className={'partner-request-view__description partner-request-view__description-block'}>
                <p dangerouslySetInnerHTML={{ __html: data?.description }} />
            </div>
        </div>
    )
}