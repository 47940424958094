import { RouterType } from '../helpers/types'
import {TestRoute} from "../containers/TestRoute";
import {ServiceProviderView} from "../containers/ServiceProvider/view/ServiceProviderView";
import Settings from "../containers/Settings";
import {ServiceProviderKanban} from "../containers/ServiceProvider/kanban/ServiceProviderKanban";
import {ProviderCheckout} from "../containers/Settings/Routes/ProviderCheckout/Checkout";
import {FinishStep} from "../components/other/EmailHaveBeenSent/FinishStep";
import {SearchResults} from "../containers/Other/SearchResults/SearchResults";

const LAWYER:RouterType[] = [
  {
    exact: false,
    path: '/partner-request/:id',
    title: 'Dashboard',
    Component: ServiceProviderView,
  },
  {
    exact: false,
    path: '/dashboard/',
    title: 'Dashboard',
    Component: ServiceProviderKanban,
  },
  {
    exact: false,
    path: '/test',
    title: 'Legal Request',
    Component: TestRoute,
  },
  {
    exact: false,
    path: '/settings',
    title: 'Настройки',
    Component: Settings,
  },
  {
    exact: false,
    path: '/checkout/:id',
    title: 'Legal Request',
    Component: ProviderCheckout,
  },
  {
    exact: true,
    path: '/finish-bank-payment',
    title: 'finish-bank-payment',
    Component: FinishStep,
  },
  {
    exact: false,
    path: '/search',
    title: 'Dashboard',
    Component: SearchResults,
  },
]

export default LAWYER
