import './modal-loader.scss'

export const ModalLoader = () => {
    return (
        <div className="mobile-loader-container">
            <div className="lds-spinner">
                <div /><div /><div /><div /><div /><div />
                <div /><div /><div /><div /><div /><div />
            </div>
        </div>
    )
}