import searchIcon from "../../../../assets/images/icons/search.svg";
import React, {useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

export const HeaderSearch = () => {
    const [text, setText] = useState('')
    const [isOpen, setIsOpen] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    const history = useHistory()
    const location = useLocation()

    const goToSearchResult = () => {
        if (!isOpen) {
            setIsOpen(true)
            setTimeout(() => inputRef.current?.focus(), 300)
        }

        if (isOpen && inputRef.current?.value) {
            history.push(`/search?text=${text}`);
            setIsOpen(false)
            setText('')
        }
    }

    if (location.pathname === '/search') {
        return <></>
    }

    return (
        <a id={'header-search-button'} onClick={goToSearchResult} className={`${isOpen ? 'header__search-open' : 'header__search-hide'} header__right-link`}>
            <input
                ref={inputRef}
                value={text}
                type="text"
                onChange={e => setText(e.target.value)}
                onKeyDown={e => {
                    if (e.keyCode === 13) goToSearchResult()
                }}
            />
            <img onClick={goToSearchResult} src={searchIcon} alt=""/>
        </a>
    )
}