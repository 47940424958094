import React from 'react'
import { TabsType } from './tabs.type'
import './tabs.scss'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import BottomIcon from '../../../assets/images/backBlackIcon.svg'

const Tabs:React.FC<TabsType> = ({ arr, active, onChange, DivBottomRender }) => {
  const renderTabComponent = arr.find((tab) => tab.value === active)

  return window.innerWidth > 460 ? (
    <div className={'tabs'}>
      <div className={'tabs__header'}>
        {
          arr.map(({ value, label }) => {
              if (label) {
                  return <span
                      onClick={() => onChange(value)}
                      className={`tabs__header-item ${value === active && 'tabs__header-item-active'}`}
                  >
              {label}
            </span>
              }
          })
        }
      </div>
      <div className={'tabs__body'}>
        <TransitionGroup className={'transition-group'}>
          <CSSTransition
            key={active}
            timeout={{ enter: 200, exit: 0 }}
            classNames={'slide'}
            unmountOnExit
          >
            <section>
              {renderTabComponent && renderTabComponent.Component}
            </section>
          </CSSTransition>
        </TransitionGroup>
      </div>
      {DivBottomRender}
    </div>
  ) : <div className={'mobile-tabs'}>
      {
          arr.map(({ label, value, Component }) => {
              const splitArr = active.split(',')

              if(!label) {
                  return <></>
              }

              return (
                  <div className={`${splitArr.includes(value) && 'mobile-tabs__item-active'} mobile-tabs__item`}>
                      <h2
                          onClick={() => splitArr.includes(value) ? onChange(splitArr.filter(el => el !== value).join(',')) : onChange(`${active},${value}`)}
                          className={'mobile-tabs__item-header'}>
                          {label}
                          <img className={'mobile-tabs__item-icon'} src={BottomIcon} alt=""/>
                      </h2>
                      <CSSTransition
                          in={splitArr.includes(value)}
                          timeout={200}
                          classNames={'fade'}
                          unmountOnExit
                      >
                          <div className={'mobile-tabs__component'}>
                              {Component}
                          </div>
                      </CSSTransition>
                  </div>
              )
          })
      }
      {DivBottomRender}
  </div>
}

export default Tabs
