import customAxios from './api/interceptors'

export const onBoardingFlowService = () => customAxios.get(`flow/onboarding`)

export const acceptOfferService = (offer_id: number) => customAxios.post('company/offer/accept-offer', { offer_id })

export const getOfferService = (id: number) => customAxios.get(`company/offers/${id}`)

export const getStripeLinkService = () => customAxios.get(`onboarding/stripe-request-buy-page`)

export const getHelloSignService = () => customAxios.get(`onboarding/hellosign-request-sign-page`)

export const getOfferCommentsService = () => customAxios.get(`company/offer-comments`)

export const sendOfferCommentsService = (offer: number, text: string) =>
    customAxios.post(`company/offer-comment?offer_id=${offer}&seen=0&text=${text}`, {
        headers:{ 'Content-Type': 'multipart/form-data' }
    })