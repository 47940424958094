import React, {useContext, useEffect, useState} from "react";
import penIcon from '../../../../assets/images/pen.svg'
import axios from "axios";
import downloadIcon from "../../../../assets/images/dowload.svg";
import customAxios from "../../../../service/api/interceptors";
import {CheckboxItem} from "../../../../components/UI/Checkbox";
import {CustomCheckbox} from "../../../../components/other/Registration/CustomCheckbox/CustomCheckbox";
import {MainContextProvider} from "../../../../context/MainContext";
import {toastifyError, toastifySuccess} from "../../../../service/toastify/toastify";
import {getBillingClientDataService} from "../../../../service/client/onboardingService";
import {stripeCountries} from "../../../Modals/ConfirmBillingData/ConfirmBillingData";
import {dateFormatter} from "../../../../helpers/dateFormatter";
import {intercomCatchErrorMessage} from "../../../../helpers/intercomCatchErrorMessage";
import {useIntercom} from "react-use-intercom";
import {all_countries} from "../../../../helpers/all_countries";

export const ProviderPaymentByCrypto: React.FC<any> = ({ match, history, invoiceInfo, payment_method_code, method, paymentType }) => {
    const { params: { id } } = match
    const { setLoading, setModalType, modalData, setModalData } = useContext(MainContextProvider)
    const [isEdit, setIsEdit] = useState(false)
    const [vat, setVat] = useState(0)
    const [checked, setChecked] = useState(false)
    const [isSentEmail, setIsSentEmail] = useState(false)
    const [values, setValues] = useState({
        name: '',
        number: '',
        vat: '',
        stripe_country_code: '',
        country: '',
        city: '',
        state: '',
        address: '',
        postal_code: '',
        sending_wallet_address: '',
        full_name: '',
        company: {
            id: ''
        },
        tch: false
    })
    const { showNewMessages } = useIntercom()

    const getTaxData = async ({ address, stripe_country_code, city, state, postal_code }: any) => {
        setLoading(true)
        try {
            const { status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/stripe/check-tax`, {
                amount: invoiceInfo?.sum,
                country: stripe_country_code,
                type: invoiceInfo?.service,
                city: city,
                state: state,
                line1: address,
                postal_code: postal_code,
            })
            if (data) {
                if (+payment_method_code === 5)
                    setVat(20)
                else
                    setVat((data.tax_data.tax / data.tax_data.subtotal) * 100)
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    const getBillingData = async () => {
        try {
            const { status, data } = await customAxios.get('/billing-info/provider')
            const { name, number, address, vat, stripe_country_code, country, city, state, postal_code, company, sending_wallet_address, full_name } = data;
            if(status === 200) {
                setValues({
                    ...values,
                    name, number, address, vat, stripe_country_code, country, city, state, postal_code, company, sending_wallet_address, full_name
                })
                await getTaxData({ address, stripe_country_code, city, state, postal_code })
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    useEffect(() => {
        if (modalData === 'billing-confirmed') {
            getBillingData().then()
            setModalData(null)
        }
    }, [modalData])

    useEffect(() => {
        getBillingData().then()
    }, [])

    const sendInvoiceToEmail = async () => {
        setLoading(true)

        const { status, data } = await customAxios.post(`/provider/invoices/${id}/email`, { payment_method: payment_method_code })
        if (status === 200) {
            setIsSentEmail(true)
            history.push(`/finish-bank-payment`)
        } else {
            toastifyError('Something went wrong')
        }
        setLoading(false)
    }

    return (
        <div className="checkout">
            <div className="checkout__left">
                <div className="checkout__container">
                    <div className="checkout__heading">
                        Pay Invoice #{invoiceInfo?.id} via Cryptocurrency
                    </div>
                    <p className={'checkout__invoice-description'}>
                        Your have chosen “Cryptocurrency” as your preferred payment method.<br />
                        To generate your invoice, please provide the details of the account from which you will make payment. This will enable us to identify your payment.
                    </p>
                </div>
                <div className="checkout__container mt10">
                    <div className="checkout__heading">
                        Your Company Details
                        <button onClick={() => {
                            setModalType('confirm_provider_billing_data')
                            setModalData({ company_id: invoiceInfo?.company?.id, method, paymentType })
                        }} className={'checkout__invoice-edit'}>Edit <img src={penIcon} alt=""/></button>
                    </div>
                    <div className="checkout__invoice-table">
                        <h2>Important! Please provide the exact details of the account that will provide the payment. </h2>
                        {/*<div className="checkout__invoice-row">
                            <b>Bank name</b><span>TEST BANK</span>
                        </div>*/}
                        <div className="checkout__invoice-row">
                            <b>Sending Wallet Address</b><span style={{ overflowWrap: 'anywhere' }}>{values.sending_wallet_address}</span>
                        </div><br />
                        {method === 'personal' && <div className="checkout__invoice-row">
                            <b>Full name</b><span style={{overflowWrap: 'anywhere'}}>{values.full_name}</span>
                        </div>}
                        {method === 'corporate' && <div className="checkout__invoice-row">
                            <b>Company number</b><span>{values.number}</span>
                        </div>}
                        <div className="checkout__invoice-row">
                            <b>Billing Address</b><span>{values.address}</span>
                        </div><br/>
                        <div className="checkout__invoice-row">
                            <b>Country</b><span>{values.stripe_country_code && all_countries.find(el => values.stripe_country_code === el.code)?.name}</span>
                        </div>
                        <div className="checkout__invoice-row">
                            <b>Zip</b><span>{values.postal_code}</span>
                        </div>
                        <div className="checkout__invoice-row">
                            <b>State</b><span>{values.state}</span>
                        </div>
                        {method === 'corporate' && <div className="checkout__invoice-row">
                            <b>Vat Number</b><span>{values.vat}</span>
                        </div>}
                        <em
                            style={{ textDecoration: 'none', fontStyle: 'italic', fontSize: '15px', fontWeight: 500 }}>
                            Please note: If we receive payment from a different account, the payment MAY NOT be accepted.</em>
                    </div>
                </div>
                <div className="checkout__container mt10">
                    {/*<div className="checkout__heading">
                        Legal Nodes Bank Details
                    </div>
                    <div className="checkout__invoice-table">
                        <h2>To pay via Bank Transfer, transfer funds using the following bank information:</h2>
                        <div className="checkout__invoice-row">
                            <b>Bank name</b><span>TEST BANK</span>
                        </div>
                        <div className="checkout__invoice-row">
                            <b>Routing number</b><span>110000000</span>
                        </div>
                        <div className="checkout__invoice-row">
                            <b>Account number</b><span>test_52796e3294dc</span>
                        </div>
                        <div className="checkout__invoice-row">
                            <b>SWIFT code</b><span>TSTEZ122</span>
                        </div>
                        <em>
                            We will mark this Invoice as “Closed” once we receive confirmation on the payment.<br />
                            Please keep in mind that Bank transfers, also known as ACH payments, can take up to five business days.
                        </em>
                    </div>*/}
                    <div className="checkout__invoice-table">
                        <CustomCheckbox
                            label={'I confirm that this invoice will be paid for with the company details stated above and that all information is correct.'}
                            checked={checked}
                            onChange={()=> setChecked(!checked)}
                            isStopPropagation={false}
                        />
                        {
                            invoiceInfo.status === "UNPAID" &&
                                <button onClick={sendInvoiceToEmail} disabled={!checked || isSentEmail} className={'btn-black w100 mt20'}>GET INVOICE</button>
                        }
                    </div>
                </div>
            </div>
            <div className="checkout__right checkout__container">
                <h2 className={'checkout__heading'}>
                    Invoice
                    {invoiceInfo.invoice_url && <a href={invoiceInfo.invoice_url} target={'_blank'} className="checkout__header-download">
                        <span>Download PDF</span>
                        <div className="checkout__header-download-icon">
                            <img src={downloadIcon} alt="" />
                        </div>
                    </a>}
                </h2>
                <div className="checkout__invoice-description">
                    <div className="checkout__description-row">
                        <div className="checkout__description-name">Invoice#</div>
                        <div className="checkout__description-value">{invoiceInfo?.id}</div>
                    </div>
                    <div className="checkout__description-row">
                        <div className="checkout__description-name">Date</div>
                        <div className="checkout__description-value">{dateFormatter(invoiceInfo?.created_at)}</div>
                    </div>
                    <div className="checkout__description-row" style={{ flexDirection: 'column' }}>
                        <div className="checkout__description-name">Description</div>
                        <div className="checkout__description-value task-body__description">
                            <div dangerouslySetInnerHTML={{ __html: invoiceInfo.service_list }} />
                        </div>
                    </div>
                    <div className="checkout__invoice-hr" />
                    <div className="checkout__invoice-row" style={{ marginTop: '10px' }}>
                        <b style={{ fontWeight: 400 }}>Subtotal</b>
                        <span>${+invoiceInfo?.sum}</span>
                    </div>
                    <div className="checkout__invoice-row" style={{ marginTop: '10px' }}>
                        <b style={{ fontWeight: 400 }}>VAT</b>
                        <span>{vat}%</span>
                    </div>
                    <div className="checkout__invoice-row" style={{ marginTop: '10px' }}>
                        <b style={{ fontSize: '17px' }}>Total</b>
                        <span style={{ fontWeight: 600, fontSize: '20px' }}>${+invoiceInfo?.sum + (invoiceInfo?.sum * (vat / 100))}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}