import React, {useCallback, useContext, useEffect, useState} from "react";
import "./planner-page.scss"
import customAxios from "../../../../../service/api/interceptors";
import deleteIcon from "./icons/delete.svg"
import updateIcon from "./icons/update.svg"
import createIcon from "./icons/create.svg"
import {MainContextProvider} from "../../../../../context/MainContext";
import {ConfirmDeletePlannerItem} from "./ConfirmDeletePlannerItem";
import {toastifySuccess} from "../../../../../service/toastify/toastify";
import moment from "moment";
import {NewPlannerRequestModal} from "./NewPlannerRequestModal";
import {useHistory, useLocation} from "react-router-dom";
import {
    CustomSortDropDown,
    SortKanbanBoard
} from "../../../../LegalManager/ManagerKanban/SortKanbanBoard/SortKanbanBoard";

export type PlannerSuggestionItemType = {
    "category_id": number
    "id": number,
    "title": string,
    "description": string,
    "importance": string,
    "price_estimate": number,
    "time_estimate": number,
    "goal_id": number,
    "project_id": number,
    "has_addon": string,
    "created_at": number,
    "is_covered_by_monthly_plan": string,
    "is_price_estimate_not_applicable": string,
    "type": string
    "category": {
        "id": string
        "title": string
    }
}

type Props = {
    company_id: string
    setActiveTab: (key: 'board' | 'planner') => void
    activeTab: string
}

export const PlannerPage: React.FC<Props> = ({ company_id, setActiveTab, activeTab }) => {
    const history = useHistory()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const { setLoading } = useContext(MainContextProvider)
    const [list, setList] = useState<PlannerSuggestionItemType[]>([])
    const [deleteId, setDeleteId] = useState<string | null>(null)
    const [modalConfig, setModalConfig] = useState<null | { id: number | null, item?: PlannerSuggestionItemType, isSubmit?: boolean }>(null)

    const getItems = async () => {
        setLoading(true)
        try {
            const { status, data } =
                await customAxios.get(`/project-suggestion/planned?company_id=${company_id}&order_by=${searchParams.get('order') || '0'}`)

            if (status === 200) {
                setList((data?.planned_requests || []).map((el: any) => ({ ...el, description: el.description + '' })))
            }

        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        if (searchParams.get('view') === "planner") getItems().then()
    }, [company_id, searchParams.get('view'),  searchParams.get('order')])

    const deleteItem = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.delete(`/project-suggestion/${deleteId}`)
            if (status === 200) {
                setDeleteId(null)
                getItems().then()
                toastifySuccess('Item deleted successfully')
            }
        } catch (e) {}
        setLoading(false)
    }

    const updateParam = useCallback((paramName, newValue) => {
        const searchParams = new URLSearchParams(location.search);
        if (paramName !== 'page') {
            searchParams.set('page', '1')
        }
        if (searchParams.has(paramName)) {
            searchParams.set(paramName, newValue);
        } else {
            searchParams.append(paramName, newValue);
        }
        history.push(`${location.pathname}?${searchParams.toString()}`);
    }, [history, location.pathname, location.search]);

    return (
        <div>
            <div className={'flex alignCenter justifySpaceBetween'}>
                <div className={'tasks-project-kanban__tabs'}>
                    <div
                        onClick={() => history.push(`/dashboard`)}
                        className={`tasks-project-kanban__tabs-item ${!searchParams.get('view') && 'tasks-project-kanban__tabs-item-active'}`}>
                        Active Board
                    </div>
                    <div
                        onClick={() => history.push(`/dashboard?view=planner`)}
                        className={`tasks-project-kanban__tabs-item ${searchParams.get('view') === 'planner' && 'tasks-project-kanban__tabs-item-active'}`}>
                        Planner
                    </div>
                </div>

                {searchParams.get('view') === 'planner' && <div className={'flex'}>
                    <CustomSortDropDown
                        arr={[
                            { value: '0', label: 'Created date (Ascending)' },
                            { value: '1', label: 'Created date (Descending)' },
                        ]}
                        onClick={(value) => updateParam('order', value)}
                        value={searchParams.get('order') || '0'}
                    />
                    <div onClick={() => setModalConfig({id: null})} className={'planner-page__create-button pointer'} style={{ marginLeft: '5px' }}>
                        <img src={createIcon} alt=""/>
                    </div>
                </div>}
            </div>

            {searchParams.get('view') === "planner" && <div className={'planner-page'}>
                <div className="planner-page__list">
                    {
                        (list || []).map(item => (
                            <div key={item.id} className="planner-page__list-item">
                                <div className="planner-page__list-item-heading">
                                    <div className="planner-page__list-item-heading-left">
                                        <div className={'planner-page__list-item-date'}>
                                            <em className={'mr10'}>Created on: {moment(item.created_at * 1000).format('DD MMM YYYY')}</em>
                                            <em style={{ color: '#000' }}>Category: {item.category?.title}</em>
                                        </div>
                                        <div className={'planner-page__list-item-title'}>
                                            {item.title}
                                        </div>
                                    </div>
                                    <div className="planner-page__list-item-heading-right">
                                        <a onClick={() => setDeleteId(item.id + '')}>
                                            <img src={deleteIcon} alt=""/>
                                        </a>
                                        <a  onClick={() => setModalConfig({ id: null, item })}>
                                            <img src={updateIcon} alt=""/>
                                        </a>
                                        <button onClick={() => setModalConfig({ id: null, item, isSubmit: true })}>
                                            SUBMIT TO VLO
                                        </button>
                                    </div>
                                </div>
                                <div className={'planner-page__list-item-body'}>
                                    {item.description.length <= 150 ? item.description : `${item.description.substr(0, 150)}...`}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>}

            <ConfirmDeletePlannerItem
                isOpen={!!deleteId}
                close={() => setDeleteId(null)}
                ok={deleteItem}
            />

            <NewPlannerRequestModal
                isOpen={!!modalConfig}
                close={() => setModalConfig(null)}
                ok={getItems}
                updateItems={getItems}
                company_id={company_id}
                item={modalConfig?.item}
                isSubmit={modalConfig?.isSubmit}
            />
        </div>
    )
}