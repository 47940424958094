import React, {useContext, useEffect, useState} from "react";
import {Input, ReactSelect, TextArea} from "../../../components/UI";
import {getBillingClientDataService, updateBillingClientDataService, confirmBillingService} from "../../../service/client/onboardingService";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../service/toastify/toastify";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {MainContextProvider} from "../../../context/MainContext";
import axios from "axios";
import {useIntercom} from "react-use-intercom";
import {intercomCatchErrorMessage} from "../../../helpers/intercomCatchErrorMessage";
import {all_countries} from "../../../helpers/all_countries";
import customAxios from "../../../service/api/interceptors";

export const stripeCountries = [
    { country: 'Argentina', code: 'AR' },
    { country: 'Australia', code: 'AU' },
    { country: 'Austria', code: 'AT' },
    { country: 'Belgium', code: 'BE' },
    { country: 'Bolivia', code: 'BO' },
    { country: 'Brazil', code: 'BR' },
    { country: 'Bulgaria', code: 'BG' },
    { country: 'Canada', code: 'CA' },
    { country: 'Chile', code: 'CL' },
    { country: 'Colombia', code: 'CO' },
    { country: 'Costa Rica', code: 'CR' },
    { country: 'Croatia', code: 'HR' },
    { country: 'Cyprus', code: 'CY' },
    { country: 'Czech Republic', code: 'CZ' },
    { country: 'Denmark', code: 'DK' },
    { country: 'Dominican Republic', code: 'DO' },
    { country: 'Egypt', code: 'EG' },
    { country: 'Estonia', code: 'EE' },
    { country: 'Finland', code: 'FI' },
    { country: 'France', code: 'FR' },
    { country: 'Germany', code: 'DE' },
    { country: 'Greece', code: 'GR' },
    { country: 'Hong Kong SAR China', code: 'HK' },
    { country: 'Hungary', code: 'HU' },
    { country: 'Iceland', code: 'IS' },
    { country: 'India', code: 'IN' },
    { country: 'Indonesia', code: 'ID' },
    { country: 'Ireland', code: 'IE' },
    { country: 'Israel', code: 'IL' },
    { country: 'Italy', code: 'IT' },
    { country: 'Japan', code: 'JP' },
    { country: 'Latvia', code: 'LV' },
    { country: 'Liechtenstein', code: 'LI' },
    { country: 'Lithuania', code: 'LT' },
    { country: 'Luxembourg', code: 'LU' },
    { country: 'Malta', code: 'MT' },
    { country: 'Mexico ', code: 'MX' },
    { country: 'Netherlands', code: 'NL' },
    { country: 'New Zealand', code: 'NZ' },
    { country: 'Norway', code: 'NO' },
    { country: 'Paraguay', code: 'PY' },
    { country: 'Peru', code: 'PE' },
    { country: 'Poland', code: 'PL' },
    { country: 'Portugal', code: 'PT' },
    { country: 'Romania', code: 'RO' },
    { country: 'Singapore', code: 'SG' },
    { country: 'Slovakia', code: 'SK' },
    { country: 'Slovenia', code: 'SI' },
    { country: 'Spain', code: 'ES' },
    { country: 'Sweden', code: 'SE' },
    { country: 'Switzerland', code: 'CH' },
    { country: 'Thailand', code: 'TH' },
    { country: 'Trinidad & Tobago', code: 'TT' },
    { country: 'United Arab Emirates', code: 'AE' },
    { country: 'United Kingdom', code: 'GB' },
    { country: 'United States', code: 'US' },
    { country: 'Uruguay', code: 'UY' },
    { country: 'Ukraine', code: 'UA' }
]

const buttonStyle = { width: '49%', height: '42px', fontSize: '11px' }
const validationData = {
    //name: { required: true, maxLength: 255 },
    address: { required: true, maxLength: 255 },
    stripe_country_code: { required: true },
    city: { required: true, maxLength: 255 },
    postal_code: { required: true, maxLength: 255 },
    vat: { maxLength: 16 },
    currency: { required: true }
}

export const ProviderConfirmBillingData:React.FC<any> = ({ close }) => {
    const { showNewMessages } = useIntercom()
    const { setModalType, setModalData, modalData } = useContext(MainContextProvider)
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [values, setValues] = useState({
        full_name: '',
        sending_wallet_address: '',
        name: '',
        number: '',
        vat: '',
        stripe_country_code: '',
        country: '',
        city: '',
        state: '',
        address: '',
        postal_code: '',
        company_id: '',
        currency: 'USD',
        tch: false
    })

    const getBillingData = async () => {
        try {
            const { status, data } = await customAxios.get(`/billing-info/provider`)
            const { name = '', number = '', address = '', vat = '', city = '', state = '', postal_code = '', currency = '', full_name = '', sending_wallet_address = '' } = data;
            if(status === 200 && typeof data === 'object') {
                setValues({
                    ...values,
                    name, number, address, vat, stripe_country_code: modalData?.selectedCountry || data.stripe_country_code || 'GB', city, state, postal_code, full_name, sending_wallet_address,
                    currency: modalData?.paymentType === 'crypto' ? 'USDC' : (currency && currency !== 'USDC' ? currency : 'USD')
                })
            } else {

            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    useEffect(() => {
        getBillingData().then()
    }, [])

    function checkCryptoString(str: string) {
        const regex = /^0x[a-fA-F0-9]{40}$/;
        return regex.test(str);
    }

    const updateBillingData = async () => {
        const valData = JSON.parse(JSON.stringify(validationData))
        if (modalData?.method === 'corporate') {
            valData.name = { required: true, maxLength: 255 }
            valData.number = { required: true, maxLength: 255 }
        } else {
            valData.full_name = { required: true, maxLength: 255 }
        }

        if (modalData?.paymentType === 'crypto' && values.sending_wallet_address) {
            valData.sending_wallet_address = { required: false, minLength: 42, maxLength: 42 }
        }

        const validReq = controlErrorFunc({ obj: values, config: valData })

        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }

        setErrors({})

        /*if (values.vat) {
            setLoading(true)
            const vatData = checkVAT(values.vat, countries);
            if (!vatData.isValid) {
                setErrors({ vat: 'Enter valid VAT number' })
                setLoading(false)
                return;
            }
            setLoading(false)
        }*/

        setLoading(true)
        try {
            const { name, number, address, vat, stripe_country_code, country, city, state, postal_code, currency, full_name, sending_wallet_address } = values;
            const { status, data } = await customAxios.post(`/billing-info/provider`, {
                name, number, address, vat, stripe_country_code, country, city, state, postal_code, currency, company_id: modalData?.company_id, full_name, sending_wallet_address,
                payment_method: modalData?.payment_method
            })
            if (status === 200 && data.id) {
                setModalType('')
                setModalData('billing-confirmed')
            } else {
                toastifyError('Something went wrong')
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    if(loading) {
        return <div style={{ position: 'relative', height: '300px' }}>
            <ModalLoader />
        </div>
    }

    return !modalData ? null : (
        <div>
            <p className="create_legal_request__description">
                This information will be used to identify your payment:
            </p>
            <div>
                {modalData?.method === 'corporate' ? <Input
                    label={'Legal Company Name'}
                    className={'mt15'}
                    otherProps={{
                        placeholder: 'Legal Company Name',
                        value: values.name,
                        onChange: event => setValues({ ...values, name: event.target.value, tch: true })
                    }}
                    errorMessage={errors?.name} valid={!errors.name}
                /> : <Input
                    label={'Full Name'}
                    className={'mt15'}
                    otherProps={{
                        placeholder: 'Full Name',
                        value: values.full_name,
                        onChange: event => setValues({ ...values, full_name: event.target.value, tch: true })
                    }}
                    errorMessage={errors?.full_name} valid={!errors.full_name}
                />}

                {modalData?.method === 'corporate' && <Input
                    label={'Company Registration Number'}
                    className={'mt15'}
                    otherProps={{
                        placeholder: 'Company Registration Number',
                        value: values.number,
                        onChange: event => setValues({ ...values, number: event.target.value, tch: true })
                    }}
                    errorMessage={errors?.number} valid={!errors.number}
                />}
                <ReactSelect
                    label={'Country'}
                    className={'checkout__form-mt15'}
                    value={values.stripe_country_code}
                    onChange={e => setValues({ ...values, stripe_country_code: e.value })}
                    options={all_countries.map(item => ({ value: item.code, label: item.name }))}
                    valid={!errors.stripe_country_code} errorMessage={errors.stripe_country_code}
                />
                <Input
                    label={'City'}
                    className={'mt15'}
                    otherProps={{
                        placeholder: 'City',
                        value: values.city,
                        onChange: event => setValues({ ...values, city: event.target.value, tch: true })
                    }}
                    errorMessage={errors?.city} valid={!errors.city}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Input
                        label={'ZIP'}
                        className={'checkout__form-mt15 checkout__form-w50'}
                        otherProps={{
                            placeholder: 'ZIP',
                            value: values.postal_code,
                            onChange: e => setValues({ ...values, postal_code: e.target.value })
                        }} valid={!errors.postal_code} errorMessage={errors.postal_code}
                    />
                    <Input
                        label={'State'}
                        className={'checkout__form-mt15 checkout__form-w50'}
                        otherProps={{
                            placeholder: 'State',
                            value: values.state,
                            onChange: e => setValues({ ...values, state: e.target.value })
                        }} valid={!errors.state} errorMessage={errors.state}
                    />
                </div>
                <Input
                    label={'Billing Address'}
                    className={'mt15'}
                    otherProps={{
                        placeholder: 'Billing Address',
                        value: values.address,
                        onChange: event => setValues({ ...values, address: event.target.value, tch: true }),
                    }}
                    errorMessage={errors?.address} valid={!errors.address}
                />
                <div style={{ marginTop: '15px' }}>
                    <ReactSelect
                        label={'Currency'}
                        value={values.currency}
                        options={modalData?.paymentType === 'crypto' ? [
                            { value: 'USDC', label: 'USDC' },
                            /*{ value: 'Tether TRC20', label: 'Tether TRC20' },*/
                            /*{ value: 'NEAR', label: 'NEAR' },*/
                        ] : [
                            { value: 'USD', label: 'USD' },
                            { value: 'EUR', label: 'EUR' },
                            { value: 'GBP', label: 'GBP' },
                        ]}
                        onChange={(e) => setValues({ ...values, currency: e.value })}
                        valid={!errors?.currency}
                        errorMessage={errors.currency}
                    />
                </div>
                {modalData?.method === 'corporate' && <Input
                    label={'VAT number (not required)'}
                    className={'mt15'}
                    otherProps={{
                        placeholder: 'VAT number',
                        value: values.vat,
                        onChange: event => setValues({...values, vat: event.target.value, tch: true})
                    }}
                    errorMessage={errors?.vat} valid={!errors.vat}
                />}
                <div className={'flex alignCenter justifySpaceBetween mt15'} style={{ paddingBottom: '15px' }}>
                    <button onClick={() => setModalType('')} style={buttonStyle} className={'btn-white text-uppercase'}>
                        CANCEL
                    </button>
                    <button onClick={updateBillingData} style={buttonStyle} className={'btn-black text-uppercase'}>
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    )
}