import React, { useEffect, useState, useContext } from 'react'
import cardIcon from '../../../../assets/images/master-card.svg'
import plusIcon from '../../../../assets/images/createIcon.svg'
import './subscriptions.scss'
import basketIcon from '../../../../assets/images/basket.svg'

import questionIcon from '../../../../assets/images/icons/question.svg'
import shoppingCartIcon from '../../../../assets/images/icons/shopping-cart.svg'
import wrenchIcon from '../../../../assets/images/icons/wrench.svg'

import { MainContextProvider } from '../../../../context/MainContext'
import { dateFormatter } from '../../../../helpers/dateFormatter'
import { getSubscriptions, SubscriptionsType } from '../../../../service/settings'
import customAxios from "../../../../service/api/interceptors";
import {openPopupWidget} from "react-calendly";
import {intercomCatchErrorMessage} from "../../../../helpers/intercomCatchErrorMessage";
import {useIntercom} from "react-use-intercom";
import {useHistory} from "react-router-dom";
import axios from "axios";

type CardType = {
  brand: string
  last4: string
  exp_year: number
  exp_month: number
}

const Subscriptions = () => {
  const history = useHistory()
  const { setModalType, setLoading, userData } = useContext(MainContextProvider)
  const [subscriptions, setSubscriptions] = useState<SubscriptionsType[]>([])
  const { showNewMessages } = useIntercom()
  const [card, setCard] = useState<CardType | null>(null)

  const getPaymentItems = async (payment_method_id: string) => {
    try {
      const { status, data } = await axios.get(`${process.env.REACT_APP_STRIPE_API_URL}/stripe-other/payment-method/${payment_method_id}`)
      if (status === 200) {
        setCard(data.card)
      }
    } catch (e) {}
  }

  const getSubscription = async (id: string) => {
    try {
      const { status, data } = await axios.get(`${process.env.REACT_APP_STRIPE_API_URL}/stripe-other/subscription/${id}?token=${localStorage.getItem('accessToken')}`)
      if (status === 200) {
        await getPaymentItems(data.default_payment_method)
      }
    } catch (e) {}
  }

  useEffect(() => {
    (async () => {
      setLoading(true)
      document.title = "Your Monthly Plans"
      try {
        const { data } = await getSubscriptions()
        setSubscriptions(data)
        data.map((item: any) => {
          if (item.stripe_subscription_id) {
            getSubscription(item.stripe_subscription_id)
          }
        })
      } catch (e) {
        await showNewMessages(intercomCatchErrorMessage(e))
      }
      setLoading(false)
    })()
  }, [])

  const getUrl = async () => {
    /*setLoading(true)
    try {
      const { status, data } = await customAxios.get('/onboarding/get-kanban-calendly-widget') // /onboarding/calendly-request-widget
      if (status === 200) {
        openPopupWidget({ url: `${data}${data.includes('?') ? '&' : '?'}email=${userData?.email}&name=${userData?.name}`})
      }
    } catch (e) {}
    setLoading(false)*/

    setModalType('transitionFromTrial')
  }

  return (
    <div className={'subscriptions'}>
      <h2 style={{ display: 'none' }}>Subscriptions</h2>
      <div className={'subscriptions__table'}>
        <div className={'subscriptions__table-thead'}>
          <span className={'subscriptions__th subscriptions__thead-cols'} style={{ paddingLeft: '40px', width: '35%' }}>{'Type'}</span>
          <span className={'subscriptions__th subscriptions__thead-cols'} style={{ width: '50%' }}>{'Payment Method'}</span>
          {/*<span className={'subscriptions__th subscriptions__thead-cols'}>{'Billing Date'}</span>*/}
          {/*<span className={'subscriptions__th subscriptions__thead-cols'} style={{ width: '55%' }}>{'Description'}</span>*/}
          <span className={'subscriptions__th subscriptions__thead-cols'}>{'Actions'}</span>
        </div>
        {
          subscriptions.map(({
            activate_date, title, active, url, purchase_url, type, price, next_billing_date,payment_method, description, stripe_subscription_id, is_retainer, price_monthly
          }) => (
            <div className={`subscriptions__table-tbody subscriptions__table-type-${type} ${!(+active === 1) && 'subscriptions__table-tbody-gray'}`}>
              <div className={'subscriptions__thead-cols'} style={{ paddingLeft: '40px', width: '25%' }}>
                <div>
                  <b className={'subscriptions__h4'}>{title}</b>
                  <span style={{ display: 'block' }} className={'subscriptions__span'}>
                  ${price_monthly}
                    {'/mo\r'}
                </span>
                </div>
                <button onClick={() => showNewMessages('Hello! I would like to change / pause my current Monthly Plan.')} style={{ width: '150px' }} className="btn-black subscriptions__mobile-change-plan">CHANGE PLAN</button>
                {stripe_subscription_id && card && <span className={'subscriptions__mobile-card subscriptions__card'} style={{ display: 'none' }}>
                   <img src={cardIcon} alt=""/>
                  <p>
                    <div className={'text-uppercase'}><b>{card?.brand} •••• •••• •••• {card?.last4}</b> <br />Expires {card?.exp_month}/{card?.exp_year}</div>
                    <div onClick={() => history.push('/settings/stripe-payment-method/' + stripe_subscription_id)} className={'subscriptions__edit'}>Edit</div>
                  </p>
                </span>}
                <div className={'subscriptions__activate-date'} style={{ display: 'none' }}>
                  {activate_date && dateFormatter(activate_date + '')}
                  {stripe_subscription_id && card && <button className={'subscriptions__mobile-card-edit'} onClick={() => history.push('/settings/stripe-payment-method/' + stripe_subscription_id)}>
                    Edit
                  </button>}
                </div>
              </div>
              {/*<div className={'subscriptions__thead-cols'} style={{ width: '55%', color: '#FFFFFF', fontSize: '14px', fontWeight: 300, paddingRight: '30px', lineHeight: '17px' }}>
                {description}
              </div>*/}
              <div className={'subscriptions__thead-cols'} style={{ width: '50%' }}>
                {stripe_subscription_id && card && <span className={'subscriptions__card'}>
                   <img src={cardIcon} alt=""/>
                  <p>
                    <div className={'text-uppercase'}><b>{card?.brand} •••• •••• •••• {card?.last4}</b> <br />Expires {card?.exp_month}/{card?.exp_year}</div>
                    <div onClick={() => history.push('/settings/stripe-payment-method/' + stripe_subscription_id)} className={'subscriptions__edit'}>Edit</div>
                  </p>
                </span>}
              </div>
              {/*<div className={'subscriptions__thead-cols'}>
                <span className={'subscriptions__date'}>{next_billing_date ? dateFormatter(`${next_billing_date}`) : ''}</span>
              </div>*/}
              {userData?.isTrial && title.includes('Trial') ? <div className={'subscriptions__thead-cols subscriptions__actions'}>
                <button onClick={() => setModalType('choose_payment_plan')} className="btn-black" style={{ textTransform: 'uppercase' }}>Upgrade</button>
              </div> : <div className={'subscriptions__thead-cols subscriptions__actions'} style={{ width: '20%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end' }}>
                {/*{
                    +active === 1 ? <a><img onClick={() => setModalType('create_support')} width={'25'} src={wrenchIcon} alt={''} /></a> : <a target={'_blank'} href={purchase_url} rel={'noreferrer'}><img width={'25'} src={shoppingCartIcon} alt={''} /></a>
                }*/}
                {/*<a style={{ color: '#fff', fontSize: '13px', fontWeight: 300, textDecoration: 'none' }} href="">Unsubscribe</a>*/}
                <div>
                  {+active === 1 ?
                      <button onClick={() => showNewMessages('Hello! I would like to change / pause my current Monthly Plan.')} style={{ width: '150px' }} className="btn-black subscriptions__change-plan">CHANGE PLAN</button> :
                      <button onClick={() => showNewMessages('I would like to activate/modify my subscription')} style={{ width: '150px' }} className="btn-black">TALK TO SALES</button>
                  }
                </div>
                <a target={'_blank'} href={url}><img className={'subscriptions__questions'} style={{ marginTop: '8px' }} width={'25'} src={questionIcon} alt={''} /></a>
              </div>}
            </div>
          ))
        }
      </div>
      {/*<div onClick={() => setModalType('create_support')} className={'subscriptions__button'}>
        <div className={'subscriptions__button-container'}>
          <div className={'subscriptions__button-icon'}>
            <img src={plusIcon} alt={''} />
          </div>
          <span>{'Need help with a legal, tax, privacy, or accounting matter? Contact us!'}</span>
        </div>
      </div>*/}
    </div>
  )
}

export default Subscriptions
