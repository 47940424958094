import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MainContextProvider } from '../../../context/MainContext'
import { TextArea } from '../../../components/UI'

import { sendLegalTicket } from '../../../service/legalTicket'

import { toastifyError, toastifySuccess } from '../../../service/toastify/toastify'

import './legalTicket.scss'

const LegalTicket:React.FC = () => {
  const { t } = useTranslation()

  const { setModalType } = useContext(MainContextProvider)
  const [value, setValue] = useState('')
  const [valid, setValid] = useState(true)

  const sendRequest = async () => {
    if (value.length < 1) {
      setValid(false)
      return
    }

    const body = {
      text: value,
    }

    try {
      await sendLegalTicket({ body })
      toastifySuccess(t('successfullySent'))
      setModalType('')
    } catch (e) {
      toastifyError(t('wentWrong'))
    }
  }

  const onHandlerChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!valid) {
      setValid(true)
    }

    setValue(event.target.value)
  }

  return (
    <div className={'legal-ticket__body'}>
      <TextArea
        otherProps={{
          placeholder: t('lawyer.descriptionLegalWorks'),
          value,
          rows: 6,
          onChange: onHandlerChange,
        }}
        valid={valid}
      />
      <button
        onClick={sendRequest}
        className={'btn btn-black btn_full-width text-uppercase legal-ticket__btn'}
      >
        { t('send') }
      </button>
    </div>
  )
}

export default LegalTicket
