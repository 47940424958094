import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import {ServiceProviderKanbanItems} from "./ServiceProviderKanbanComponents/ServiceProviderKanbanItems";
import "./service-provider-kanban.scss"
import {SortKanbanBoard} from "../../LegalManager/ManagerKanban/SortKanbanBoard/SortKanbanBoard";
import {KanbanTabs} from "../../LegalManager/ManagerKanban/KanbanTabs";
import {ServiceProviderKanbanItemType} from "./ServiceProviderKanbanComponents/serviceProviderKanbanItemType";
import {useHistory, useLocation} from "react-router-dom";
import {getManagerKanbanService} from "../../../service/manager/legalBoard";
import InfiniteScroll from "react-infinite-scroll-component";

export type ServiceProviderKanbanType = {
    'client accepted': ServiceProviderKanbanItemType[],
    'done': ServiceProviderKanbanItemType[],
    'manager accepted': ServiceProviderKanbanItemType[],
    'new': ServiceProviderKanbanItemType[]
}
export type ServiceProviderKanbanCountType = {
    'client accepted': { count: number },
    'done': { count: number },
    'manager accepted': { count: number },
    'new': { count: number }
}

let service_provider_items_timeout: NodeJS.Timeout | null = null

function processTable(data: any) {
    const obj: any = {}
    let i = 0;

    Object.keys(data).map(key => {
        obj[key] = data[key].partner_requests.map((el: any) => {
            i++
            return { ...el, index: i }
        })
    })

    return obj
}

function getPerPage() {
    return 6
}

export const ServiceProviderKanban = () => {
    const [kanbanCount, setKanbanCount] = useState<ServiceProviderKanbanCountType>({} as ServiceProviderKanbanCountType)
    const [kanban, setKanban] = useState<ServiceProviderKanbanType>({} as ServiceProviderKanbanType)
    const { setLoading } = useContext(MainContextProvider)
    const history = useHistory()
    const location = useLocation()
    const searchParams = new URLSearchParams(window.location.search);
    const fetchMoreDataRef = useRef({});
    const totalCountRef = useRef(0);
    const [fetchItems, setFetchItems] = useState([{ item: 'a' }])

    const updateParam = useCallback((paramName, newValue) => {
        if (paramName !== 'page') {
            searchParams.set('page', '1')
        }
        if (searchParams.has(paramName)) {
            searchParams.set(paramName, newValue);
        } else {
            searchParams.append(paramName, newValue);
        }
        history.push(`${location.pathname}?${searchParams.toString()}`);
    }, [history, location.pathname, location.search]);

    const getKanbanData = async () => {
        const order_by = localStorage.getItem('provider-kanban-sort-value') || 1
        const per_page = searchParams.get('page') ? getPerPage() * +(searchParams.get('page') || 1) : getPerPage()

        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`provider/kanban?order_by=${order_by}&per_page_count=${per_page}`)
            if (status === 200) {
                setFetchItems([...fetchItems, ...fetchItems])
                fetchMoreDataRef.current = processTable(data.kanban)

                let moreBiggerCount = 0
                Object.keys(data.kanban).map(key => {
                    if (data.kanban[key].count > moreBiggerCount) {
                        moreBiggerCount = data.kanban[key].count
                    }
                })
                totalCountRef.current = moreBiggerCount

                setKanban(processTable(data?.kanban))
                setKanbanCount(data?.kanban)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        getKanbanData().then()
        document.title = 'Dashboard'
    }, [])

    useEffect(() => {
        if (!searchParams.get('page')) {
            getKanbanData().then()
        }
    }, [searchParams.get('page')])

    const fetchMoreData = async () => {
        if (+(searchParams.get('page') || 1) >= Math.ceil(Number(totalCountRef.current) / Number(getPerPage()))) {
            return
        }

        setLoading(true)
        try {
            const order_by = localStorage.getItem('provider-kanban-sort-value') || 1
            const { status, data } = await customAxios.get(`provider/kanban?order_by=${order_by}&per_page_count=${getPerPage()}&page=${+(searchParams.get('page') || 1) + 1}`)
            if (status === 200) {
                const parseKanban = JSON.parse(JSON.stringify(fetchMoreDataRef.current))
                setFetchItems([...fetchItems, ...fetchItems])
                Object.keys(data.kanban).map(key => {
                    parseKanban[key] = [...parseKanban[key], ...data.kanban[key].partner_requests]
                })
                fetchMoreDataRef.current = parseKanban
                setKanban(parseKanban)
                updateParam('page', +(searchParams.get('page') || '1') + 1)
            }
        } catch (e) {}

        setLoading(false)
    }

    return (
        <div className={'service-provider-kanban'}>
            <div className="service-provider-kanban__header">
                <div style={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
                    <h2 className="client-kanban-header__heading">Dashboard</h2>
                    {/*<KanbanTabs
                        hideFrozen
                        dropdownOptions={[{id:'', name: 'All'}, ...[{ id: '4', name: 'test', debt: 'none' }]]}
                    />*/}
                </div>
                <SortKanbanBoard onClick={(value) => window.location.reload()} />
            </div>
            <InfiniteScroll
                dataLength={fetchItems.length}
                next={fetchMoreData}
                hasMore={true}
                loader={<></>}
            >
                <ServiceProviderKanbanItems
                    kanban={kanban}
                    kanbanCount={kanbanCount}
                />
            </InfiniteScroll>
        </div>
    )
}