import React, {useContext, useEffect, useState} from 'react'
import './account-sttings.scss'
import { Input } from '../../../../components/UI'
import plusIcon from '../../../../assets/images/createIcon.svg'
import {toastifyError, toastifySuccess} from '../../../../service/toastify/toastify'
import {
  changeProfileData,
  deleteAvatarService,
  getAvatar,
  getUserInfo,
  getUserPhoto
} from '../../../../service/settings'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"
import ModalContainer from "../../../../components/ModalContainer";
import controlErrorValid from "../../../../components/UI/ControlValid/controlErrorValid";
import {MainContextProvider} from "../../../../context/MainContext";
import {intercomCatchErrorMessage} from "../../../../helpers/intercomCatchErrorMessage";
import {useIntercom} from "react-use-intercom";

const validationConfig = {
  firstname: {
    required: true,
    maxLength: 80
  },
  lastname: {
    required: true,
    maxLength: 80
  },
  email: {
    email: true,
    maxLength: 255
  },
  password: {
    minLength: 8,
    maxLength: 255
  },
  repeat_password: {
    minLength: 8,
    maxLength: 255
  }
}

function base64ToImage(cropData: string) {
  if(!cropData) return ''
  let arr = cropData.split(','),
      // @ts-ignore
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], 'filename.jpg', {type:mime})
}

const AccountSettings = () => {
  const { setLoading, setUserData, userData } = useContext(MainContextProvider)
  const [avatar, setAvatar] = useState('')
  const [cropData, setCropData] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const { showNewMessages } = useIntercom()
  const [values, setValues] = useState<any>({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    repeat_password: ''
  })
  const [errors, setErrors] = useState<any>({});

  const setAvatarData = async () => {
    try {
      const { data, status } = await getAvatar();
      if(status !== 200 || !data) {
        return false;
      }
      setAvatar(JSON.parse(data).message)
    } catch (e) {
      await showNewMessages(intercomCatchErrorMessage(e))
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getUserPhoto()
        const { data: { firstname, lastname, email }, status } = await getUserInfo()
        if (status === 200) {
          setLoading(false)
          setValues({ ...values, firstname, lastname, email })
          setAvatar(JSON.parse(data).message)
        }
        await setAvatarData()
      } catch (e) {
        await showNewMessages(intercomCatchErrorMessage(e))
      }
      document.title = "Account Settings"
    })()
  }, [])

  const Validation = () => {
    const errorsObject: any = {};
    Object.keys(validationConfig).map(key => {
      // @ts-ignore
      const valid = controlErrorValid(values[key], validationConfig[key]);
      if(valid) errorsObject[key] = valid;
    })
    if((values.password !== '' || values.repeat_password !== '') && values.password !== values.repeat_password ) {
      errorsObject.password = 'Password mismatch';
      errorsObject.repeat_password = 'Password mismatch';
    }
    setErrors({...errorsObject})
    return Object.keys(errorsObject).length === 0
  }

  const clearAvatar = async () => {
    const { status, data } = await deleteAvatarService()
    if(status === 200 && data && JSON.parse(data).status === 'ok') {
      setAvatar('')
    }
  }

  const save = async () => {
    if (!Validation()) {
      toastifyError('Please correctly fill all fields in the form')
      return;
    }

    const sendData = {
      firstname: values.firstname,
      lastname: values.lastname,
      email: values.email,
      password: values.password,
      password_repeat: values.repeat_password,
      cropData: base64ToImage(cropData)
    }

    try {
      const {status, data} = await changeProfileData(sendData)
      if (status === 200) {
        if(typeof data === 'string' && JSON.parse(data).status === 'error') {
          toastifyError(JSON.parse(data).message)
        } else {
          toastifySuccess('Settings have been saved')
        }
      }
    } catch (e) {
      console.log(e)
      await showNewMessages(intercomCatchErrorMessage(e))
    }
  }


  return (
    <div className={'account-settings'}>
      <div className={'account-settings__container'}>
        <h2>{'Account Information'}</h2>
        <Demo
            cropData={cropData}
            setCropData={setCropData}
            avatar={avatar}
            clearAvatar={clearAvatar}
        />
        <Input
          label={'First Name'}
          valid={!errors.firstname}
          errorMessage={errors.firstname}
          otherProps={{
            value: values.firstname,
            onChange: (e) => setValues({...values, firstname: e.target.value.replace(/[^a-zA-Z0-9- ]+/, '') }),
            onKeyDown: event => {
              if(event.keyCode === 13) save().then()
            }
          }}
          className={'account-settings__input'}
        />
        <Input
          label={'Last Name'}
          valid={!errors.lastname}
          errorMessage={errors.lastname}
          otherProps={{
            value: values.lastname,
            onChange: (e) => setValues({...values, lastname: e.target.value.replace(/[^a-zA-Z0-9- ]+/, '') }),
            onKeyDown: event => {
              if(event.keyCode === 13) save().then()
            }
          }}
          className={'account-settings__input'}
        />
        <Input
          label={'Email'}
          valid={!errors.email}
          errorMessage={errors.email}
          otherProps={{
            value: values.email,
            onChange: (e) => setValues({...values, email: e.target.value.replace(/[^a-zA-Z0-9-@._]+/, '') }),
            onKeyDown: event => {
              if(event.keyCode === 13) save().then()
            },
            autoComplete: 'off'
          }}
          className={'account-settings__input'}
        />

        <h3>{'Password'}</h3>
        <Input
          label={'New password'}
          valid={!errors.password}
          otherProps={{
            value: values.password,
            onChange: (e) => setValues({...values, password: e.target.value.replace(/[^a-zA-Z0-9-@ ]+/, '') }),
            type: 'password',
            onKeyDown: event => {
              if(event.keyCode === 13) save().then()
            },
            autoComplete: 'new-password'
          }}
          className={'account-settings__input'}
          errorMessage={passwordMismatch ? 'Password mismatch' : errors.password}
        />
        <Input
          label={'Repeat New Password'}
          valid={!errors.repeat_password}
          otherProps={{
            value: values.repeat_password,
            onChange: (e) => setValues({...values, repeat_password: e.target.value.replace(/[^a-zA-Z0-9-@ ]+/, '') }),
            type: 'password',
            onKeyDown: event => {
              if(event.keyCode === 13) save().then()
            },
            autoComplete: 'new-password'
          }}
          className={'account-settings__input'}
          errorMessage={passwordMismatch ? 'Password mismatch' : errors.repeat_password}
        />
        <button
          onClick={save}
          className={'btn-black'}
        >
          {'SAVE\r'}
        </button>
      </div>
    </div>
  )
}

export default AccountSettings

export const Demo: React.FC<any> = ({ cropData, setCropData, avatar, clearAvatar }) => {
  const [image, setImage] = useState('');
  const [cropper, setCropper] = useState<any>();
  const [isOpen, setIsOpen] = useState(false)

  const imageValidation = (e: any) => {
    const allowedTypes = '.jpeg, .png, .jpg, .bmp, .gif'
    
    if(e?.target?.files?.[0] && e.target.files[0].size > 8000000) {
      toastifyError('Max file size is 8Mb')
      return false;
    }

    if(e?.target?.files?.[0] && e.target.files[0].name.length === 0) {
      toastifyError('Image name is required')
      return false;
    }

    if(e?.target?.files?.[0]) {
      let fileType = e.target.files[0].type.split('/')[1];
      if(!allowedTypes.includes(fileType)) {
        toastifyError('Invalid image type')
        return false;
      }
    }

    if(!e?.target) return false

    return true
  }

  const onChange = (e: any) => {
    if(!imageValidation(e)) {
      return false;
    }

    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
      setIsOpen(true)
    };
    if (files && files[0] && reader){
      reader.readAsDataURL(files[0]);
    }
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas({
        maxWidth: 1000,
        maxHeight: 1000
      }).toDataURL());
      setIsOpen(false)
    }
  };

  return (
      <div>
        <div style={{ width: "100%" }}>
          <label htmlFor='avatar-file' className="account-settings__file">
            {
              cropData || avatar ? <div style={{
                width: '80px',
                height: '80px',
                border: '2px dashed #606060',
                borderRadius: '6px',
                flexShrink: 0,
                marginRight: '10px'
              }}><img
                style={{
                  width: '90%',
                  height: '90%',
                  display: 'block',
                  marginTop: '5%',
                  marginLeft: '5%',
                  borderRadius: '6px',
                }}
                  src={cropData || avatar} alt=""/></div> : <div className="account-settings__file-container">
                <div className="account-settings__file-container-block">
                  <img src={plusIcon} alt=""/>
                </div>
              </div>
            }

            <div className="account-settings__file-text">
              <div className="account-settings__file-text-left">
                <span>Click here to choose and upload a new profile avatar</span>
                <em>jpeg, png, jpg, bmp, gif</em>
              </div>
              <div className="account-settings__file-text-right">
                {cropData && <button onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  setCropData('')
                }}>Clear</button>}
                {avatar && !cropData && <button onClick={clearAvatar}>Delete avatar</button>}
                <a>Choose a file</a>
              </div>
            </div>
            <input
                onChange={onChange}
                className="account-settings__file-input"
                id="avatar-file"
                type="file"
                accept={'.jpeg, .png, .jpg, .bmp, .gif'}
                size={8000000}
            />
          </label>
          <br />
          <ModalContainer isOpen={isOpen} close={() => setIsOpen(false)}>
            <div>
              <Cropper
                  style={{ height: 400, width: "100%" }}
                  zoomTo={0}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={image}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  // autoCropArea={1}
                  checkOrientation={false}
                  aspectRatio={4 / 4}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
              />
              <button onClick={getCropData} className="btn-black">Save</button>
              <p style={{ fontSize: '14px', marginTop: '7px', textAlign: 'center' }}>You can use zoom with scroll</p>
            </div>
          </ModalContainer>

        </div>
        {/*<div>
          <div className="box" style={{ width: "50%", float: "right" }}>
            <h1>Preview</h1>
            <div
                className="img-preview"
                style={{ width: "100%", float: "left", height: "300px" }}
            />
          </div>
          <div
              className="box"
              style={{ width: "50%", float: "right", height: "300px" }}
          >
            <h1>
              <span>Crop</span>
              <button style={{ float: "right" }} onClick={getCropData}>
                Crop Image
              </button>
            </h1>
            <img style={{ width: "100%" }} src={cropData} alt="cropped" />
          </div>
        </div>*/}
      </div>
  );
};