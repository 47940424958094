import {AuthModal, AuthModalProps} from "../AuthModal/AuthModal";
import ArrowIcon from '../../../../assets/images/arrow.svg'
import React, {useEffect, useState} from "react";
import {forgotPassword} from "../../../../service/auth/auth";
import {AuthInput} from "../../../../components/UI/AuthInput/AuthInput";
import controlErrorValid from "../../../../components/UI/ControlValid/controlErrorValid";

const EmailIcon = <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M20 8l-8 5-8-5v10h16zm0-2H4l8 4.99z" opacity=".3" />
    <path d="M4 20h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2zM20 6l-8 4.99L4 6h16zM4 8l8 5 8-5v10H4V8z" />
</svg>

interface ForgotPasswordProps extends AuthModalProps {
    initialEmail: string
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ isOpen, close, initialEmail }) => {
    const [email, setEmail] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)
    const [error, setError] = useState('')

    const forgetPasswordSave = async (e:any) => {
        const valid = controlErrorValid(email, { email: true, required: true });
        if(valid && typeof valid === 'string') {
            setError(valid)
            return false;
        }
        setError('')

        const { status } = await forgotPassword(email)
        if(status === 200) {
            setIsSuccess(true)
        }
    }

    useEffect(() => {
        if(initialEmail && !email) setEmail(initialEmail)
    }, [isOpen])

    return (
        <AuthModal isOpen={isOpen} close={close}>
            <>
                <div className="auth-modal__container">
                    <div style={{ minWidth: '470px' }} className="auth-modal__body">
                        <div className="auth-modal__hr-header">
                            <h1>{isSuccess ? 'Success' : 'Forgot your password?'}</h1>
                            <hr />
                        </div>
                        {
                            isSuccess ?
                                <p className={'auth-modal__description'}>The letter had been sent to your email {email}. <br />Check your email and move to link</p> :
                                <p className={'auth-modal__description'}>Enter your email address</p>
                        }

                        <AuthInput
                            otherProps={{
                                value: email,
                                placeholder: 'Enter Email',
                                disabled: isSuccess,
                                onChange: event => setEmail(event.target.value)
                            }}
                            icon={EmailIcon}
                            error={error}
                        />
                        <button
                            onClick={isSuccess ? close : forgetPasswordSave}
                            className={'auth-modal__button'}>
                                <img src={ArrowIcon} alt=""/>
                                {isSuccess ? 'Close' : 'Send'}
                        </button>
                    </div>
                    <div className={'auth-modal__close-button'}>
                        <a onClick={close} className={'auth-modal__close-button-link'}>Cancel</a>
                    </div>
                </div>
            </>
        </AuthModal>
    )
}