import React, {useContext, useEffect, useRef, useState} from "react";
import {MainContextProvider} from "../context/MainContext";
import {useParams} from "react-router-dom";
import customAxios from "../service/api/interceptors";
import pushNotifications from "../helpers/pushNotifications";
import {MessageItem} from "./other/CommentsContainer/CommentsContainer";

export const TestOffer = () => {
    const { id }: any = useParams()
    const { setLoading, centrifuge, userData } = useContext(MainContextProvider)
    const [chats, setChats] = useState([])
    const [activeChatId, setActiveChatId] = useState(null)
    const [connectData, setConnectData] = useState([])
    const wsChatsRef = useRef<any>([])
    const messagesRef = useRef(``)

    const connectedWsToChats = (chats: any[]) => {
        chats.map(item => {
            const offer_comment_channel = `chat-${item.id}#${userData?.user_id}`;
            const sbData = centrifuge.subscribe(offer_comment_channel, function(ctx: { data: { comments: MessageItem } }) {
                console.log(`in notifications - ${offer_comment_channel} --- ${ctx?.data?.comments?.text}`)
            });
            wsChatsRef.current = [...wsChatsRef.current, sbData]
        })
    }

    const getChats = async () => {
        const { status, data } = await customAxios.get(`/chat/offer/${id}`)
        setChats(data)
        connectedWsToChats(data)
        setActiveChatId(data[0].id)
    }

    useEffect(() => {
        setLoading(false)
        getChats().then()
    }, [])

  return (
      <div>
          <div>Active notifications - {(wsChatsRef.current || []).map((el: any) => <b style={{ marginRight: '30px' }}>{el?.channel}</b>)}</div>
          <div style={{ marginTop: '50px' }}>
              {
                  chats.map((el: any) => (
                      <button onClick={() => setActiveChatId(el.id)}>{el.name} - {el.id}</button>
                  ))
              }
              <div style={{ marginTop: '10px' }}>
                  {
                      activeChatId &&
                      <ChatComponent
                          item={chats.find((el: any) => el.id == activeChatId)}
                          setConnectData={setConnectData}
                          connectData={connectData}
                      />
                  }
              </div>
          </div>

      </div>
  )
}

let subscriptionData: any = null;
const ChatComponent: React.FC<any> = ({ item, setConnectData, connectData }) => {
    const { setLoading, centrifuge, userData } = useContext(MainContextProvider)
    const [firstLoad, setFirstLoad] = useState(true)
    const socketsRef = useRef<any>([])
    const messagesRef = useRef<string[]>([])
    const [st, setSt] = useState<string[]>([])

    const connectSocket = () => {
        const offer_comment_channel = `chat-${item.id}#${userData?.user_id}`;
        socketsRef.current = [{ id: `chat_${item.id}`, name: `in_chat chat-${item.id}#${userData?.user_id}` }]
        subscriptionData = centrifuge.subscribe(offer_comment_channel, function(ctx: { data: { comments: MessageItem } }) {
            console.log(`in chat - ${offer_comment_channel} --- ${ctx?.data?.comments?.text}`)
        });
    }

    useEffect(() => {
        if (firstLoad) return

        // @ts-ignore
        if (typeof subscriptionData === 'object') {
            subscriptionData?.unsubscribe()
            subscriptionData = null

            connectSocket()
        }

    }, [item])


    useEffect(() => {
        if (item && centrifuge && firstLoad) {
            connectSocket()
            setFirstLoad(false)
        }
    }, [item, centrifuge])

    /*const connectSocket = () => {
        const offer_comment_channel = `chat-${item.id}#${userData?.user_id}`;
        socketsRef.current = [...socketsRef.current, { id: item.id, name: `in_chat chat-${item.id}#${userData?.user_id}` }]
        subscriptionData = centrifuge.subscribe(offer_comment_channel, function(ctx: { data: { comments: MessageItem } }) {
            if(ctx && ctx.data && ctx.data.comments) {

            }
        });
    }

    useEffect(() => {
        if (typeof subscriptionData === 'object') {
            // @ts-ignore
            subscriptionData.unsubscribe()
            subscriptionData = null

            const items = socketsRef.current.filter((el: any) => el.name !== `in_chat chat-${item.id}#${userData?.user_id}`)
            console.log('update', items)
            socketsRef.current = items

            connectSocket()
        }
    }, [item])

    useEffect(() => {
        if(centrifuge) connectSocket()
        return () => {
            if(subscriptionData && typeof subscriptionData === 'object') {
                // @ts-ignore
                subscriptionData.unsubscribe()

                const items = socketsRef.current.filter((el: any) => el.name !== `in_chat chat-${item.id}#${userData?.user_id}`)
                console.log('create', socketsRef.current)
                socketsRef.current = items
            }
        }
    }, [centrifuge])*/

    return <div>
        <div>Active chats - {`chat-${item?.id}#${userData?.user_id}`} - {item.name}</div>
    </div>
}