import React, {useRef, useState} from "react";
import dropdownIcon from "./dropdown.svg"
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import "./card_dropdown.scss"
import {CSSTransition} from "react-transition-group";

export const CardDropdown: React.FC<any> = ({ items }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    return (
        <div ref={dropdownRef} className={'card_dropdown'}>
            <img onClick={() => setIsOpen(!isOpen)} src={dropdownIcon} alt=""/>
            <CSSTransition in={isOpen} classNames={'my-node'} timeout={200} unmountOnExit>
                <>
                    <div className={'card_dropdown__squire'} />
                    <div className={'card_dropdown__container'}>
                        {
                            (items || []).map((el: any) => (
                                <a onClick={() => { el.func(); setIsOpen(false) }}>{el.label}</a>
                            ))
                        }
                    </div>
                </>
            </CSSTransition>

        </div>
    )
}