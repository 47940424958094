import { RefObject, useEffect } from 'react'

type UseOnClickOutsideType<T> = {
  ref: RefObject<T>,
  handler: (event: MouseEvent | TouchEvent) => any,
}

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>({ ref, handler } : UseOnClickOutsideType<T>) => {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        const { current } = ref
        if (!current || current.contains(event.target as HTMLElement)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    [ref, handler],
  )
}
