import React, {useContext, useEffect, useState} from "react";
import {Input, TextArea} from "./UI";
import Select from "./UI/Select/Select";
import {MainContextProvider} from "../context/MainContext";
import './testforCors.scss'
import customAxios from "../service/api/interceptors";
import axios from "axios";

export const TestForCors: React.FC<any> = () => {
    const { setLoading, isLoggedIn } = useContext(MainContextProvider)
    const [url, setUrl] = useState('')
    const [method, setMethod] = useState('post')
    const [data, setData] = useState('')

    useEffect(() => {
        setLoading(false)
    })

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const saveLogin = () => {
        if(method === 'get') {
            customAxios.get(url)
        } else if(method === 'post') {
            customAxios.post(url, data, config)
        } else if(method === 'put') {
            customAxios.put(url, data, config)
        } else if(method === 'delete') {
            customAxios.delete(url)
        }
    }

    const saveLogout = () => {
        if(method === 'get') {
            axios.get(url)
        } else if(method === 'post') {
            axios.post(url, data, config)
        } else if(method === 'put') {
            axios.put(url, data, config)
        } else if(method === 'delete') {
            axios.delete(url)
        }
    }

    return (
        <>
            <div className={'test-for-cors'}>
                <div style={{
                    display: 'flex',
                }}>
                    <Select
                        label={'Method'}
                        value={method}
                        options={[
                            {value: 'get', label: 'get'},
                            {value: 'post', label: 'post'},
                            {value: 'put', label: 'put'},
                            {value: 'delete', label: 'delete'},
                        ]}
                        onChange={e => setMethod(e.value)}
                        valid
                        styles={{
                            width: '500px'
                        }}
                    />
                    <Input
                        label={'URL'}
                        valid={true}
                        otherProps={{
                            value: url,
                            onChange: e => setUrl(e.target.value)
                        }}
                    />
                </div>

                <TextArea
                    label={'JSON'}
                    otherProps={{
                        value: data,
                        onChange: e => setData(e.target.value),
                        rows: 10
                    }}
                />

                <button onClick={isLoggedIn ? saveLogin : saveLogout} className={'btn-black'}>Save</button>
            </div>

        </>
    )
}