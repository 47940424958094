import React, {useContext, useEffect} from "react";
import finishIcon from '../../../assets/images/registration/onboarding_finish_step.png'
import modules from './FinishStep.module.scss'
import {MainContextProvider} from "../../../context/MainContext";

export const FinishStep = () => {
    const { setLoading } = useContext(MainContextProvider)

    useEffect(() => {
        setLoading(false)
        document.title = 'The Invoice has been sent to your email'
    },[])

  return (
      <div className={modules.component}>
          <div className={modules.container}>
              <img className={modules.container_image} src={finishIcon} alt=""/>
              <h1 className={modules.container_heading}>
                  The Invoice has been sent to your email
              </h1>
              <p className={modules.container_text}>
                  You will find all the necessary information for the payment inside the PDF attachment to the letter. Please visit your Email inbox to proceed with the payment.
              </p>
              {/*<a className={modules.button} href="/">Go to cabinet</a>*/}
          </div>
      </div>
  )
}