import userIcon from "../../../assets/images/userIcon.svg";
import clientIcon from "../../../assets/images/client.png";
import {dateFormatter} from "../../../helpers/dateFormatter";
import {ReactEditor} from "../../UI";
import sendIcon from "../../../assets/images/send.svg";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {MainContextProvider} from "../../../context/MainContext";
import moreIcon from '../../../assets/images/moreVertical.svg'
import customAxios from "../../../service/api/interceptors";
import {ModalLoader} from "../ModalLoader/ModalLoader";
import editIcon from '../../../assets/images/pen.svg'
import clipIcon from '../../../assets/images/clip.svg'
import basketIcon from '../../../assets/images/basket-black.svg'
import dowloadIcon from '../../../assets/images/dowload2.svg'
import searchIcon from '../../../assets/images/icons/search.svg'
import cancelIcon from '../../../assets/images/cancelBlueIcon.svg'
import fullScreenIcon from '../../../assets/images/fullscreen.svg'
import hideFullScreenIcon from '../../../assets/images/hide-full-screen.png'
import {toastifyError, toastifySuccess} from "../../../service/toastify/toastify";
import {intercomCatchErrorMessage} from "../../../helpers/intercomCatchErrorMessage";
import {useIntercom} from "react-use-intercom";
import {useHistory, useParams} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import pushNotifications from "../../../helpers/pushNotifications";
import {CompanyType} from "../../../types/company.type";


const arr = [1,2,3,4,5,6]
arr.splice(2, 1, 8)

export type MessageItem = {
    id: string | number
    author: {
        name: string
        type: string
        avatar_url: string
    }
    text: string
    datetime: string
    created_at: string
    author_id: number
    file_id: string
    file?: {
        name: string
        storage_file_id: string
    }
}

type Props = {
    chats: { id: string, name: string }[]
    chat_id?: string
    entity_id: string
    postMessage: (text: string) => void
    setMessages?: (data: MessageItem[]) => void
    link?: string
    uploadFileAndSendMessage?: (file: any, id: any) => void
    linkToSend: string
    linkToGetMessages: 'offer' | 'legal_request' | 'partner-request'
    isUnSubscribe: boolean
    disabledStartTimer?: boolean
    websocketName?: string
    setCounterData?: (count: number) => void
    fullScreenChat?: boolean
    setFullScreenChat?: (key: boolean) => void
    pr_offer_id?: string
    isHideContent?: boolean
}

export const CommentsContainer: React.FC<Props> = ({
    chats, entity_id, setMessages, link= '', linkToGetMessages, linkToSend, isUnSubscribe, disabledStartTimer,
    websocketName = 'offer', setCounterData, chat_id, isHideContent
}) => {
    const { id }: any = useParams()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const { setModalType, setModalData, userData, centrifuge, headerTimer, setHeaderTimer, currentTimer, setCurrentTimer } = useContext(MainContextProvider)
    const [text, setText] = useState('')
    const [editId, setEditId] = useState<null | { index: number, id: string | number }>(null)
    const { showNewMessages } = useIntercom()
    const todayFormattedDate = dateFormatter(((+(new Date()) / 1000) + ''))
    const [items, setItems] = useState<MessageItem[]>([])
    const [count, setCount] = useState(0)
    const [startPage, setStartPage] = useState(1)
    const pageCount = Math.ceil(count / 10)
    const pageRef = useRef<number>(1)
    const [page, setPage] = useState(1)
    const [fileData, setFile] = useState(null)
    const [filesData, setFilesData] = useState([])
    const [foundFile, setFoundFile] = useState<MessageItem | null>(null)
    let subscriptionData: any = () => {};
    const contentRef = useRef()
    const searchParams = new URLSearchParams(window.location.search);
    const history = useHistory()
    const loadingRef = useRef(false)
    const [searchText, setSearchText] = useState('')
    const [isSearchResult, setIsSearchResult] = useState(false)
    const chatIdRef = useRef<null | string>(null)

    useEffect(() => {
        if (chat_id) chatIdRef.current = chat_id
    }, [chat_id])

    const scrollToForId = (elementId: string) => {
        setLoading(true)
        if (contentRef.current) {
            // @ts-ignore
            const targetElement = contentRef.current.querySelector(`#el_${elementId}`);
            console.log(targetElement)
            if (targetElement) {
                targetElement.scrollIntoView({ block: 'center' })

            }
        }
        setLoading(false)
    }

    const onScroll = () => {
        if (isSearchResult) {
            return
        }

        // @ts-ignore
        const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
        //console.log((Math.abs(scrollTop) + clientHeight), scrollHeight)
        if (((Math.abs(scrollTop) + clientHeight + 2) >= scrollHeight) && !loadingRef.current) {
            getElementAtBottom(pageRef.current + 1).then();
        }

        if (scrollTop === 0) {
            getElementAtTop().then();
        }
    }

    const getElementAtBottom = async (pageData: number) => {
        console.log('Достигнут низ блока content', pageRef.current, pageCount);

        if (+pageRef.current >= +pageCount) {
            return;
        }

        loadingRef.current = true
        setLoading(true)
        const { status, data } = await customAxios.get(`/chat/${chat_id}/comments?per_page_count=10&page=${pageData}`)
        if (status === 200) {
            setItems(prevState => ([ ...prevState, ...data.comments ]))
            setPage(pageData)
            pageRef.current = pageData || 1
        }
        loadingRef.current = false
        setLoading(false)

    }

    const getElementAtTop = async (item_id?: any, start_page_query?: string) => {
        console.log('Достигнут верх блока content');

        if (+startPage === 1 && !start_page_query) {
            return
        }

        setLoading(true)
        const { status, data } = await customAxios.get(`/chat/${chat_id}/comments?per_page_count=10&page=${Number(start_page_query) - 1 || +startPage - 1}`)
        if (status === 200) {
            // @ts-ignore
            setItems(prevState => ([ ...data.comments, ...prevState ]))
            console.log(item_id, data.comments[data.comments.length - 1].id)
            setTimeout(() => scrollToForId(item_id || data.comments[data.comments.length - 1].id), 200)
            setStartPage((Number(start_page_query) || +startPage) - 1)
        }
    }

    const getComments = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/chat/${chat_id}/comments?per_page_count=10&page=${searchParams.get('initialChatPage') || 1}`)
            if (searchParams.get('initialChatPage')) {
                // @ts-ignore
                const item = data.comments.find(el => +el.id === +searchParams.get('file_id'))
                if (item) setFoundFile(item)
                setStartPage(Number(searchParams.get('initialChatPage')))

                if (item) {
                    if (Number(searchParams.get('initialChatPage')) > 1) {
                        setTimeout(() => getElementAtTop(searchParams.get('file_id'), String(searchParams.get('initialChatPage'))), 500)
                    } else {
                        setTimeout(() => scrollToForId(String(searchParams.get('file_id'))), 500)
                    }
                }
            } else {
                // @ts-ignore
                contentRef.current.scrollTop = contentRef.current.scrollHeight;
            }
            setItems(data.comments)
            setCount(data.count)
            setPage(Number(searchParams.get('initialChatPage') || 1))
            pageRef.current = Number(searchParams.get('initialChatPage') || 1)
            //history.push(`${window.location.pathname}`)
            if (setCounterData) {
                setCounterData(data?.not_seen_comments || 0)
            }
        } catch (e) {}
        setLoading(false)
    }

    const updateParam = (paramName: string, newValue: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has(paramName)) {
            if (newValue === 'null' || newValue === '') {
                searchParams.delete(paramName)
            } else {
                searchParams.set(paramName, newValue);
            }
        } else {
            searchParams.append(paramName, newValue);
        }
        history.push(`${window.location.pathname}?${searchParams.toString()}`);
    };

    const getLocalStorageComments = () => {
        const lsData: string | null = localStorage.getItem(`platform-comments`)
        const data = lsData ? JSON.parse(lsData) : []
        const currentComment = data.find((el: any) => +el.chat_id === +(chat_id || 0))
        return currentComment ? currentComment.text : ''
    }

    const setLsComment = () => {
        setText(getLocalStorageComments() ? getLocalStorageComments() : '')
    }

    const clearLsComment = () => {
        const lsData: string | null = localStorage.getItem(`platform-comments`)
        const data = lsData ? JSON.parse(lsData) : []
        const currentComment = data.find((el: any) => +el.chat_id === +(chat_id || 0))
        if (currentComment) {
            localStorage.setItem(`platform-comments`, JSON.stringify(data.filter((el: any) => !(+el.chat_id === +(chat_id || 0)))))
        }
    }

    const createLsComment = (text: string) => {
        const lsData: string | null = localStorage.getItem(`platform-comments`)
        const data = lsData ? JSON.parse(lsData) : []
        const item = { chat_id: chat_id, text }
        const otherData = data.filter((el: any) => !(+el.chat_id === +(chat_id || 0)))
        localStorage.setItem(`platform-comments`, JSON.stringify([ ...otherData, item ]))
    }

    const createComment = async (text: string) => {
        let bodyFormData = new FormData();
        for (let i = 0; i < filesData.length; i++) {
            // @ts-ignore
            bodyFormData.set(`UploadStorageForm[documents][${i + 1}]`, filesData[i])
        }
        bodyFormData.set(`${linkToGetMessages}_id`, id)
        bodyFormData.set('text', text)

        setLoading(true)
        try {
            const { status, data } = await customAxios.post(linkToSend, bodyFormData, {
                headers:{ 'Content-Type': 'multipart/form-data' }
            })

            if(status === 200 || status === 201) {
                // @ts-ignore
                setItems([ data, ...items ])
                setFilesData([])
                clearLsComment()
                toastifySuccess('Message has been sent')
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    const editMessage = async () => {
        if (!editId) return;

        let bodyFormData = new FormData();
        for (let i = 0; i < filesData.length; i++) {
            // @ts-ignore
            bodyFormData.set(`UploadStorageForm[documents][${i + 1}]`, filesData[i])
        }
        bodyFormData.set(`${linkToGetMessages}_id`, id)
        bodyFormData.set('text', text)

        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`${link}${editId.id}`, bodyFormData, {
                headers:{ 'Content-Type': 'multipart/form-data' }
            })
            if (status === 200 || status === 201) {
                if (data) {
                    const parsedData = JSON.parse(JSON.stringify(items))
                    const messageIndex = items.findIndex((el: MessageItem) => +el.id === +data.id)
                    if (parsedData[messageIndex]) {
                        parsedData[messageIndex] = data;
                        setItems(parsedData)
                        setText('')
                        setFilesData([])
                        setEditId(null)
                    }
                }
            }
        } catch (e) {
            console.log(e)
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    const searchData = async () => {
        if (searchText.length < 1) {
            return;
        }

        setLoading(true)
        const { status, data } = await customAxios.get(`/company/comment/search?text=${searchText}&chat_id=${chat_id}`)
        if (status === 200) {
            setIsSearchResult(true)
            setItems(data.comments)
        }
        setLoading(false)
    }

    useEffect(() => {
        setSearchText('')
        setIsSearchResult(false)
    }, [chat_id])

    const checkCommentAsSeen = async (id: string) => {
        if (userData?.status.type_code === 300) {
            const {} = await customAxios.post(`/company/comment/${id}/seen`)
        }
    }

    const connectSocket = () => {
        chats.map(el => {
            const offer_comment_channel = `chat-${el.id}#${userData?.user_id}`;
            centrifuge.subscribe(offer_comment_channel, function(ctx: { data: { comments: MessageItem } }) {
                if (chatIdRef.current && (String(el.id) === String(chatIdRef.current)) && pageRef.current === 1) {
                    setItems(prevState => [
                        ctx.data.comments,
                        ...prevState
                    ])
                    checkCommentAsSeen(ctx.data.comments.id + '').then()
                }
            });
        })
    }

    useEffect(() => {
        if (centrifuge && chats.length > 0) {
            connectSocket()
        }
    }, [centrifuge, chats])

    useEffect(() => {
        setLsComment()
        getComments().then()
    }, [entity_id, chat_id])
    
    const deleteMessage = async (id: string | number) => {
        setLoading(true)
        try {
            const { status } = await customAxios.delete(`${link}${id}`)
            if (status === 204) {
                const parsedData = items.filter((el: MessageItem) => +el.id !== +id)
                if (setMessages) {
                    setItems([...parsedData])
                    toastifySuccess('Message has been deleted')
                }
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    const downloadFile = async (id: string, name: string) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`company/storage/download?file_id=${id}`, {
                responseType: 'blob',
            })
            if (status === 200) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }

    function ext(name: string) {
        let m = name.match(/\.([^.]+)$/)
        return m && m[1]
    }

    const fileChange = (event: any) => {
        if (!event?.target?.files && !event?.target?.files[0]) {
            return false
        }

        for (let i = 0; i < event?.target?.files.length; i++) {
            if (event?.target?.files[i].size > 25 * 1024 * 1024) {
                toastifyError(`Max file size is 25 Megabyte (${event?.target?.files[i].name})`)
                return false
            }

            // @ts-ignore
            if (!['pdf', 'doc', 'png', 'docx', 'jpg', 'jpeg', 'gif', 'tiff', 'bmp', 'xls', 'xlsx', 'rar', 'zip'].includes(ext(event?.target?.files[i].name).toLowerCase())) {
                toastifyError(`Allowed only pdf, doc, png, docx, jpg, jpeg, gif, tiff, bmp, xls, xlsx, rar, zip (${event?.target?.files[i].name})`)
                return false
            }
        }

        toastifySuccess('Files are attached to the message')
        setFilesData(Array.from(event.target.files))

        return false

        const file = event.target.files[0]

        if (file.size > 25 * 1024 * 1024) {
            toastifyError('Max file size is 25 Megabyte')
            return false
        }

        // @ts-ignore
        if (!['pdf', 'doc', 'png', 'docx', 'jpg', 'jpeg', 'gif', 'tiff', 'bmp', 'xls', 'xlsx', 'rar', 'zip'].includes(ext(file.name).toLowerCase())) {
            toastifyError('Allowed only pdf, doc, png, docx, jpg, jpeg, gif, tiff, bmp, xls, xlsx, rar, zip')
            return false
        }

        // @ts-ignore
        setFile && setFile(file)
    }

    const [infoMessage, setInfoMessage] = useState(true)
    const closeInfoMessage = () => {
        setInfoMessage(false)
        sessionStorage.setItem('chat-message-info', 'close')
    }

    function formatTimeIn12Hour(date: Date) {
        let hours = date.getHours();
        let minutes: any = date.getMinutes();
        let period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // если часы равны 0, то преобразуем в 12
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${minutes} ${period}`;
    }
    
    function getAccessEditMessage(created_at: number) {
        if (userData?.status.type_code === 300 || userData?.status.type_code === 400) {
            return true
        }
        const currentDate = new Date()
        const timestamp = ((currentDate.getTime() - (30 * 60 * 1000)) / 1000).toFixed()
        return (+timestamp < created_at)
    }

    const [isStartTimer, setIsStartTimer] = useState(false)
    const startTimer = async () => {
        if (userData?.status.type_code !== 300 || disabledStartTimer) {
            return
        }

        if (isStartTimer) {
            return;
        }
        setIsStartTimer(true)

        if (headerTimer?.created_at) {
            return
        }

        try {
            const { data: companyItems } = await customAxios.get(`/company/company/get-companies?expand=client,company`)
            const { status, data } = await customAxios.post(`/timely/event/add`, { company_id: headerTimer?.company_id, offer_id: headerTimer?.offer_id })
            if (status === 200) {
                // @ts-ignore
                setHeaderTimer({ ...headerTimer, created_at: Number((+(new Date()) / 1000).toFixed()), timer_id: data.id })
                setTimeout(() => setIsStartTimer(false), 3000)
            }
        } catch (e) {

        }

        /*if (userData?.status.type_code !== 300) {
            return
        }
        if (isStartTimer) {
            return;
        }
        setIsStartTimer(true)

        const { data: companyItems } = await customAxios.get(`/company/company/get-companies?expand=client,company`)

        if (!currentTimer?.created_at && !headerTimer?.created_at) {
            const { status, data } = await customAxios.post(`/timely/event/add`, { company_id: headerTimer?.company_id || currentTimer?.company_id })
            if (status === 200) {
                // @ts-ignore
                setCurrentTimer({ ...(headerTimer || currentTimer), created_at: Number((+(new Date()) / 1000).toFixed()), timer_id: data.id })
                toastifySuccess('Timer has been started')
            }
        } else if ((Number(currentTimer?.company_id) !== Number(headerTimer?.company_id)) && window.confirm(`Would you like to stop tracking time for company ${companyItems.find((el: CompanyType) => +el.id === Number(currentTimer?.company_id))?.name} and start tracking time for ${companyItems.find((el: CompanyType) => +el.id === Number(headerTimer?.company_id))?.name}?`)) {
            const { status, data } = await customAxios.put(`/timely/event/${currentTimer?.timer_id || headerTimer?.timer_id}/stop-timer`)
            if (status === 200) {
                const { status, data } = await customAxios.post(`/timely/event/add`, { company_id: headerTimer?.company_id || currentTimer?.company_id })
                if (status === 200) {
                    // @ts-ignore
                    setCurrentTimer({ ...(headerTimer), created_at: Number((+(new Date()) / 1000).toFixed()), timer_id: data.id })
                    toastifySuccess('Timer has been started')
                }
            }
        }*/
    }

    const deleteFile = async (comment_id: string, file_id: string) => {
        if (!window.confirm('Are you sure that you want to delete this file?')) {
            return;
        }

        setLoading(true)
        try {
            const { status, data } = await customAxios.delete(`/company/comment/${comment_id}/file/${file_id}`)
            if (status === 200) {
                const filterItems = JSON.parse(JSON.stringify(items))
                const commentIndex = filterItems.findIndex((el: { id: string }) => +el.id === +comment_id)
                filterItems[commentIndex].file = filterItems[commentIndex].file.filter((el: { id: string }) => +el.id !== +file_id)
                setItems(filterItems)
            }
        } catch (e) {

        }
        setLoading(false)
    }

    const chaster_left = 3001 - +(text.replace(/&nbsp;/gi, '').trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').trim().length < 1 || (text.trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')).length || 0)

    return (
        <div className={'comment-list'} style={{ position: "relative" }}>
            {(loadingRef.current) && <ModalLoader />}
            {/*<div className={'comment-list__header'}>
                <h2 className={'comment-list__title'}>{t('taskBody.commentsTitle')}</h2>
                <img className={'comment-list__fullScreenIcon'} onClick={() => setFullScreenChat && setFullScreenChat(!fullScreenChat)} style={{ width: '28px', cursor: 'pointer' }} src={fullScreenChat ? hideFullScreenIcon : fullScreenIcon} alt=""/>
                <img style={{ opacity: 0 }} className={'comment-list__icon'} src={userIcon} alt={''} />
            </div>*/}
            {userData?.status.type_code === 100 && infoMessage && !sessionStorage.getItem("chat-message-info") && <div className={'comment-list__desc'}>
                <div onClick={closeInfoMessage} className={'comment-list__desc-close'}>&#10005;</div>
                VLO standard business hours are <b>8:00 - 19:00 GMT+1.</b><br />
                Our VLO`s typically respond within <b>2-3 hours</b>,<br/> however please allow 24 hours for your VLO to respond.
            </div>}
            <div className={'flex alignCenter justifySpaceBetween comment-list__search-div mr15'}>
                <div />
                <div className={'flex-center'} style={{ display: 'none' }}>
                    <div className="comment-list__search mr10">
                        <input
                            value={searchText}
                            type="text"
                            onChange={e => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) searchData()
                            }}
                        />
                        <div>
                            <img onClick={searchData} src={searchIcon} alt=""/>
                        </div>
                    </div>
                    {isSearchResult && <div>
                        <img className={'pointer'} onClick={() => {
                            setSearchText('')
                            getComments()
                            setIsSearchResult(false)
                        }} src={cancelIcon} alt=""/>
                    </div>}
                </div>
            </div>
            <div>
                {/*<InfiniteScroll
                    dataLength={items.length}
                    next={getMoreComments}
                    hasMore={true}
                    inverse={true}
                    style={{ display: 'flex', flexDirection: 'column-reverse', padding: '0 15px' }}
                    loader={<h4></h4>}
                    height={450}
                >*/}
                <div
                    // @ts-ignore
                    ref={contentRef}
                    style={{ overflow: loading ? 'hidden' : 'auto', height: items.length === 0 ? '250px' : '450px', display: 'flex', flexDirection: 'column-reverse', padding: '0 15px' }}
                    onScroll={onScroll}
                >
                    {items.map(({ id, file, author, text, datetime, created_at, author_id, file_id }: any, index) => (
                        <div id={`el_${id}`} key={id} className={`user-messages ${foundFile && (String(id) === String(foundFile?.id)) && 'user-messages__message-found'}`}>
                            <div className={'user-messages__avatar'}>
                                <div className={'user-messages__img'} onClick={() => {
                                    if(author.type === '1000') return;

                                    setModalType(author.type === '200' || author.type === '300' ? 'manager_profile_modal' : 'client_profile_modal')
                                    setModalData(author_id)
                                }}>
                                    <img style={{ height: '36px' }} className={'img'} src={author ? author.avatar_url : clientIcon} alt={''} />
                                </div>
                                <div style={{ width: '100%' }}>
                                    <p className={'user-messages__name'}>
                                        <b>{author && author.name}</b>
                                        <em style={{ display: 'none' }}>{dateFormatter(created_at) === todayFormattedDate ? formatTimeIn12Hour(new Date(+created_at * 1000)) : dateFormatter(created_at)}</em>
                                    </p>
                                    <div className={'task-body__description task-body__description-block'} style={{ display: 'flex', flexDirection: 'column' }}>
                                        <p dangerouslySetInnerHTML={{ __html: text }} />
                                        {file && Array.isArray(file) && file.map((el: { name: string, id: string }) => (
                                            <div
                                                id={`file-id-${el.id}`}
                                                onClick={() => downloadFile(el.id, el?.name)}
                                                className={'user-messages__chat-file'}>
                                                <div className={'user-messages__chat-file-img'}>
                                                    <img src={clipIcon} alt=""/>
                                                </div>
                                                <div className={'user-messages__chat-file-text'}>
                                                    {(el.name || '').slice(0,15)}{(el.name || '').length > 20 && '...'}
                                                </div>
                                                <div className={'user-messages__chat-file-button'} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={dowloadIcon} alt=""/>
                                                </div>
                                                {link && !isUnSubscribe && (String(userData?.user_id) === String(author_id)) && getAccessEditMessage(+created_at) && <div className={'user-messages__chat-file-close'} style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }} onClick={(e) => {
                                                    e.stopPropagation()
                                                    deleteFile(id, el.id)
                                                }}>
                                                    <img src={basketIcon} alt=""/>
                                                </div>}
                                            </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={'user-messages__sending-time'}>
                            <span className={'user-messages__sending-time__span'}>
                                <em>{dateFormatter(datetime || created_at)}</em>
                                <br />
                                <em>{dateFormatter(datetime || created_at, true)}</em>
                            </span>
                                {link && !isUnSubscribe && (String(userData?.user_id) === String(author_id)) && getAccessEditMessage(+created_at) && <div className={'user-messages__edit'}>
                                    <div className="user-messages__edit-icon">
                                        <img src={editIcon} alt=""/>
                                    </div>
                                    <div className={`${index === 0 && 'user-messages__edit-dropdown-bottom'} user-messages__edit-dropdown`}>
                                        <div
                                            onClick={() => {
                                                setEditId({index, id})
                                                setText(text)
                                            }}
                                            id={'update-message-' + String(id)}
                                            className="user-messages__edit-dropdown-item">
                                            Edit
                                        </div>
                                        <div
                                            onClick={() => deleteMessage(id)}
                                            style={{ color: '#ff5c5c' }} id={'delete-message-' + String(id)} className="user-messages__edit-dropdown-item">
                                            Delete
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    ))}
                </div>
                {/*</InfiniteScroll>*/}
            </div>
            {filesData.length > 0 && <div style={{borderTop: '10px solid #eef0f4', display: 'flex', flexWrap: 'wrap' }}>
                {
                    filesData.length > 0 && (filesData || []).map((el: { name: string }, i) => (
                        <div
                            key={i} onClick={() => setFilesData(filesData.filter((el, index) => i !== index))}
                             className={'pre-download-file user-messages__chat-file'}
                             style={{width: 'max-content', boxSizing: 'border-box'}}>
                            <div className={'user-messages__chat-file-img'}>
                                <img src={clipIcon} alt=""/>
                            </div>
                            <div className={'user-messages__chat-file-text'}>
                                {(el?.name || '').slice(0, 30)}{el?.name?.length > 30 && '...'}
                            </div>
                            <div className="user-messages__chat-file-close">
                                &#10005;
                            </div>
                        </div>
                    ))
                }
            </div>}
            <div className={'comment-list__input-futter'}>
                <ReactEditor
                    className={'comment-list__editor'}
                    value={text}
                    onChange={(e) => {
                        createLsComment(e)
                        setText(e)
                        startTimer()
                    }} height={200} valid={true}
                />

                <button
                    title={isUnSubscribe ? 'You do not have an active Monthly Plan so this feature is unavailable. Please activate the Monthly Plan to unlock this feature.' : ''}
                    disabled={text.replace(/&nbsp;/gi, ' ').trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').trim().length < 1 || (text.trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')).length > 3001 || isUnSubscribe}
                        onClick={() => {
                    if (editId) {
                        editMessage().then()
                    } else {
                        createComment(text).then()
                        setText('')
                    }

                }} className={'comment-list__send-button'}>
                    <img src={sendIcon} alt={''} />
                </button>
                {!isUnSubscribe && <label htmlFor="comment-list__file" className={'comment-list__file'}>
                    <img src={clipIcon} alt=""/>
                    <input
                        id={'comment-list__file'} multiple
                        onChange={fileChange}
                        className='comment-list__input-image'
                        type="file"
                        value={[]}
                        accept={'.pdf, .doc, .png, .docx, .jpg, .jpeg, .gif, .tiff, .bmp, .xls, .xlsx, .rar, .zip'}
                    />
                </label>}

                {!isUnSubscribe && <em style={{fontSize: '12px', color: '#9b9b9b', paddingLeft: '5px'}}>
                    {chaster_left > 0 ? chaster_left : 0} characters left
                </em>}
            </div>
        </div>
    )
}