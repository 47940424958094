import { NavLink } from "react-router-dom";
import {statuses} from "../../../../components/other/ClientKanbanBoard/ClientKanbanItem/ClientKanbanItem";
import React from "react";
import {ServiceProviderKanbanType} from "../ServiceProviderKanban";
import {ServiceProviderKanbanItemType} from "./serviceProviderKanbanItemType";
import dotsIcon from '../../../../assets/images/kanbanIcons/dots.svg'
import doneIcon from '../../../../assets/images/kanbanIcons/done.svg'
import draftIcon from '../../../../assets/images/kanbanIcons/draft.svg'
import notificationIcon from '../../../../assets/images/kanbanIcons/notif.svg'
import offerIcon from '../../../../assets/images/kanbanIcons/offer.svg'
import requestIcon from '../../../../assets/images/kanbanIcons/request.svg'
import clipIcon from '../../../../assets/images/icons/kanban-item-clip.svg'
import {dateFormatter} from "../../../../helpers/dateFormatter";

type Props = {
    el: ServiceProviderKanbanItemType
    heading: {
        icon: any
        background: string
        text: string
    }
}

export const ServiceProviderKanbanItem: React.FC<Props> = ({ el, heading }) => {
    const { id, title, company } = el
    const notifications = 0
    const comments = 0

    const handleChildClick = (event: any) => {
        event.preventDefault();
        console.log('div clicked');
    }

    const handleParentClick = (event: any) => {
        console.log('link clicked');
    }

    return (
        <NavLink to={`/partner-request/${id}`} onClick={handleParentClick} style={{ textDecoration: 'none' }}>
            <div className={`client-kanban-item`}>
                <div className="client_kanbanStep_notifications client_kanbanStep_notifications__kanban" style={{ background: heading.background, padding: "20px 10px" }}>
                    <div className={'client_kanbanStep_notifications__info'}>
                        <img className={'client_kanbanStep_notifications__img'} src={heading.icon} alt=""/>
                        <span style={{ color: '#FFF' }}>{heading.text}</span>
                    </div>
                </div>
                <div className="client-kanban-item__body" style={{ padding: '15px 10px' }}>
                    <div className="client-kanban-item__body-title" title={title}>
                        {
                            title.length > 60 ? `${title.slice(0, 60)}...` : title
                        }
                    </div>
                    <div className={"client-kanban-item__company flex-between"}>
                        <span className={"client-kanban-item__company-name"}>{company?.name}</span>
                        {notifications > 0 && <img src={notificationIcon} alt=""/>}
                    </div>
                </div>
                {el.last_comment ? <div className="client-kanban-item__manager-footer">
                    <div className="client-kanban-item__contact">
                        <img className="client-kanban-item__contact-img" src={el.last_comment_author?.avatar_url} alt=""/>
                        <div className={'client-kanban-item__contact-name'}>
                            <div className={'client-kanban-item__contact-name-top'}>
                                <span>{el.last_comment_author?.name}</span>
                                <div className="client-kanban-item__comment-date">
                                    {/*{dateFormatter(el?.last_comment_time + '')}*/}
                                    {/*// @ts-ignore*/}
                                    {dateFormatter((+(new Date()) / 1000) + '') === dateFormatter(el?.last_comment_time + '') ?
                                        (dateFormatter(el.last_comment_time + '', true) || '').slice(0, -3):
                                        dateFormatter(el.last_comment_time + '')}
                                </div>
                            </div>
                            <div className={'client-kanban-item__contact-message'} style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    {(el.last_comment.file || []).length > 0 ? <img style={{ marginRight: '3px' }} src={clipIcon} alt=""/> : null}
                                    {
                                        el.last_comment.text.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').length > 30 ?
                                            `${el.last_comment.text.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').slice(0, 30)}...` :
                                            el.last_comment.text.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')
                                    }
                                </div>
                                {+comments > 0 && <div className={"client-kanban-item__comment-items"}>{comments}</div>}
                            </div>
                        </div>
                    </div>
                </div> : <div className="flex-center client-kanban-item__manager-footer" style={{ justifyContent: 'center' }}>
                    <em className={'client-kanban-item__manager-footer-empty'}> No comments yet...</em>
                </div>}
            </div>
        </NavLink>
    )
}