import React, {useContext, useEffect, useRef, useState} from "react";
import customAxios from "../../service/api/interceptors";
import "./documents.scss"
import searchIcon from "./search.svg"
import dotsIcon from "./dots.svg"
import docIcon from "./docIcon.svg"
import markIcon from "./markedIcon.svg"
import unMarkIcon from "./unMarkedIcon.svg"
import closeIcon from "./close.svg"
import {NavLink, useHistory} from "react-router-dom"
import {DocumentType} from "../../types/document.type";
import {dateFormatter} from "../../helpers/dateFormatter";
import moment from "moment";
import Pagination from "../../components/other/Pagination";
import {MainContextProvider} from "../../context/MainContext";
import {intercomCatchErrorMessage} from "../../helpers/intercomCatchErrorMessage";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import {CustomLoader} from "../../components/UI/CustomLoader/CustomLoader";

const groupItemsByMonthAndYear = (items: DocumentType[]) => {
    const grouped: any = {};

    items.forEach((item: DocumentType) => {
        const date = new Date(+item.created_at * 1000);
        const monthYear = date.toLocaleString('en', { month: 'long', year: 'numeric' });
        if (!grouped[monthYear]) {
            grouped[monthYear] = [];
        }
        grouped[monthYear].push(item);
    });
    return grouped;
};

export const DocumentsList: React.FC = () => {
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')
    const [documentsCount, setDocumentsCount] = useState<DocumentType[]>([])
    const [allFiles, setAllFiles] = useState<DocumentType[]>([])
    const [groupedItems, setGroupedItems] = useState({});
    const history = useHistory();
    const searchParams = new URLSearchParams(window.location.search);
    const [openId, setOpenId] = useState(0)

    const getItems = async () => {
        setLoading(true)
        try {
            const page = `&page=${searchParams.get('page') || 1}`
            const name = searchParams.get('search') ? `&name=${searchParams.get('search')}` : ''
            const bookmarked = searchParams.get('bookmarked') === 'checked' ? '&is_bookmarked=1' : ''
            const { status, data } = searchParams.get('tab') === 'deliverables' ? await customAxios.get(`/company/deliverable/documents?per_page_count=20${page}${bookmarked}${name}`) : await customAxios.get(`/company/storage/documents?per_page_count=20${page}${bookmarked}${name}`)
            if (status === 200) {
                setAllFiles(data.files || data.deliverables)
                setDocumentsCount(data.count || 1)
                setGroupedItems(groupItemsByMonthAndYear(data.files || data.deliverables))
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        document.title = 'Documents'
        getItems().then()
    }, [window.location.search])

    const updateParam = (paramName: string, newValue: string) => {
        const searchParams = new URLSearchParams(window.location.search);
        if (paramName === 'tab') {
            searchParams.delete('page')
            searchParams.delete('search')
            searchParams.delete('bookmarked')
        }
        if (paramName !== 'page') {
            searchParams.set('page', '1')
        }
        if (searchParams.has(paramName)) {
            searchParams.set(paramName, newValue);
        } else {
            searchParams.append(paramName, newValue);
        }
        history.push(`${window.location.pathname}?${searchParams.toString()}`);
    }

    const setBookmark = async (file_id: string) => {
        setLoading(true)
        const file = allFiles.find(el => +el.id === +file_id)
        try {
            const { status, data } = file?.bookmark ?
                await customAxios.delete(`/company/storage/file/${file_id}/bookmark`) :
                await customAxios.post(`/company/storage/file/${file_id}/bookmark`)

            if (status === 200) {
                await getItems()
            }
        } catch (e) {}
        setLoading(false)
    }

    const downloadFile = async (id: string, name: string) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`company/storage/download?file_id=${id}`, {
                responseType: 'blob',
            })
            if (status === 200) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        } catch (e) {}
        setLoading(false)
    }
    
    const showFileInChat = async (file_id: string, offer_id: string, offer_status: string, chat_id: string) => {
        setLoading(true)
        try {
            const { data } = await customAxios.get(`/chat/comment/${file_id}/page?per_page_count=10`)
            history.push(`/${+offer_status === 6 ? 'legal-request' : 'offer'}/${offer_id}?initialChatPage=${data.page}&file_id=${file_id}&chat_id=${chat_id}`)
        } catch (e) {}
        setLoading(false)
    }

    const DocumentFileItem = (el: DocumentType) => {
        return <tr>
            <td className={'documents__table-project'}>
                {el?.offer_title}
            </td>
            <td className={'documents__table-file'}>
                <div>
                    <img src={docIcon} alt=""/>
                    <div>
                        <em>{el?.name_to_show}</em>
                        <span>{moment(+el?.created_at * 1000).format('DD-MM-YYYY HH:mm')}</span>
                    </div>
                </div>
            </td>
            <td className={'documents__table-author'}>
                <div style={{ display: 'flex' }}>
                    <img src={el?.author?.avatar_url} alt=""/>
                    <span>{el?.author?.name}</span>
                </div>
            </td>
            <td className={'documents__table-options'}>
                <a onClick={() => showFileInChat(el?.comment_id, el.offer_id, el.offer_status, el.chat_id)}>Show in chat</a>
            </td>
            <td className={'documents__table-actions'}>
                <div>
                    {<img onClick={() => setBookmark(el.id)} src={el.bookmark === null ? unMarkIcon : markIcon} alt=""/>}
                    <DownloadData onClick={() => downloadFile(el.id, el.name)} />
                </div>
            </td>
        </tr>
    }

    const DocumentDeliverableItem = (el: DocumentType) => {
        return <tr>
            <td className={'documents__table-project'}>
                {el?.project_title}
            </td>
            <td className={'documents__table-file'}>
                <div>
                    <img src={docIcon} alt=""/>
                    <div>
                        <em>{el?.name}</em>
                        <span>{moment(+el?.created_at * 1000).format('DD-MM-YYYY HH:mm')}</span>
                    </div>
                </div>
            </td>
            <td className={'documents__table-author'}>
                <div style={{ display: 'flex' }}>
                    <img src={el?.author_user?.avatar_url} alt=""/>
                    <span>{el?.author_user?.name}</span>
                </div>
            </td>
            <td className={'documents__table-options'}>
                {/*<a onClick={() => showFileInChat(el.id, el.offer_id, el.storage_file_id)}>Show in chat</a>*/}
            </td>
            <td className={'documents__table-actions'}>
                <div>
                    {/*{<img onClick={() => setBookmark(el.id)} src={el.bookmark === null ? unMarkIcon : markIcon} alt=""/>}*/}
                    <DownloadDeliverableData url={el.url} file={el.file} />
                </div>
            </td>
        </tr>
    }

    return loading ? <CustomLoader /> : (
        <div className={'documents'}>
            <h2>Documents</h2>
            <div className="documents__container">
                <div className="documents__left">
                    <div className="documents__heading">
                        {
                            !searchParams.get('search') ? <div className={'documents__search'}>
                                <input
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                    placeholder={'Search'}
                                    onKeyDown={e => {
                                        if (e.keyCode === 13) {
                                            updateParam('search', search)
                                            setSearch('')
                                        }
                                    }}
                                />
                                <img
                                    onClick={() => {
                                        updateParam('search', search)
                                        setSearch('')
                                    }}
                                    src={searchIcon} alt=""/>
                            </div> : <div className={'documents__search'}>
                                <input
                                    style={{ background: '#FFF' }}
                                    disabled
                                    value={searchParams.get('search') + ''}
                                />
                                <img onClick={() => {
                                    updateParam('search', '')
                                }} src={closeIcon} alt=""/>
                            </div>
                        }
                    </div>
                    <div className="documents__tabs">
                        <div
                            onClick={() => updateParam('tab', 'deliverables')}
                            className={`documents__tab ${(searchParams.get('tab') === 'deliverables') && 'documents__tab-active'}`}>
                            Deliverables
                        </div>
                        <div
                            onClick={() => updateParam('tab', 'files')}
                            className={`documents__tab ${(!searchParams.get('tab') || searchParams.get('tab') === 'files') && 'documents__tab-active'}`}>
                            Files
                        </div>
                    </div>
                    {
                        +allFiles.length === 0 && <div className={'documents__no-items'}>
                            No items to display
                        </div>
                    }
                        {
                            Object.keys(groupedItems).map(key => (
                                <div>
                                    <h3>{key}</h3>
                                    <table className={'documents__table'}>
                                        <tr style={{ background: 'none' }}>
                                            <th>Project</th>
                                            <th style={{ width: '30%' }}>File</th>
                                            <th style={{ width: '20%' }}>Author</th>
                                            <th style={{ width: '15%' }}>Options</th>
                                            <th />
                                        </tr>
                                    {
                                        // @ts-ignore
                                        (groupedItems[key] || []).map((el: DocumentType) => {
                                            if (searchParams.get('tab') === 'deliverables') {
                                                return <DocumentDeliverableItem { ...el } />
                                            } else {
                                                return <DocumentFileItem { ...el } />
                                            }
                                        })
                                    }
                                    </table>
                                </div>
                            ))
                        }
                    {
                        +documentsCount > 20 &&
                            <Pagination
                                currentPage={Number(searchParams.get('page')) || 1}
                                pagesAmount={Math.ceil(Number(documentsCount) / 20)}
                                setCurrentPage={(page) => updateParam('page', page + '')}
                            />
                    }
                </div>
                <div className="documents__right">
                    <div className={'bookmarkBlock'}>
                        <div onClick={() => updateParam('bookmarked', 'all')} className="bookmarkBlock__checkbox">
                            <div className="bookmarkBlock__squire">
                                {(!searchParams.get('bookmarked') || searchParams.get('bookmarked') === 'all') && <div className="bookmarkBlock__squire-active"/>}
                            </div>
                            <div className="bookmarkBlock__checkbox-label">All</div>
                        </div>
                        <div className="bookmarkBlock__hr" />
                        {searchParams.get('tab') !== 'deliverables' && <div onClick={() => updateParam('bookmarked', 'checked')} className="bookmarkBlock__checkbox">
                            <div className="bookmarkBlock__squire">
                                {searchParams.get('bookmarked') === 'checked' &&
                                    <div className="bookmarkBlock__squire-active"/>}
                            </div>
                            <div className="bookmarkBlock__checkbox-label">Bookmarked</div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const DownloadDeliverableData: React.FC<{ url: string, file: any }> = ({ url, file }) => {
    const { setLoading } = useContext(MainContextProvider)
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    const downloadFile = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`company/storage/download?file_id=${file?.id}`, {
                responseType: 'blob',
            })
            if (status === 200) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file?.name_to_show); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        } catch (e) {}
        setLoading(false)
    }

    if (url) {
        return <div ref={dropdownRef} onClick={() => setIsOpen(!isOpen)} className={'documents__download'}>
            <img src={dotsIcon} alt=""/>
            {isOpen && <a style={{ color: '#000', textDecoration: 'none' }} target={'_blank'} href={url} className="documents__dropdown">
                Download
            </a>}
        </div>
    } else {
        return <div ref={dropdownRef} onClick={() => setIsOpen(!isOpen)} className={'documents__download'}>
            <img src={dotsIcon} alt=""/>
            {isOpen && <a style={{ color: '#000', textDecoration: 'none' }} onClick={downloadFile} className="documents__dropdown">
                Download
            </a>}
        </div>
    }
}

const DownloadData: React.FC<{ onClick: () => void }> = ({ onClick }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    return <div ref={dropdownRef} onClick={() => setIsOpen(!isOpen)} className={'documents__download'}>
        <img src={dotsIcon} alt=""/>
        {isOpen && <div onClick={onClick} className="documents__dropdown">
            Download
        </div>}
    </div>
}