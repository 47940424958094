import logoIcon from "../../../assets/images/logo.svg";
import React, {useContext} from "react";
import ModalContainer from "../../../components/ModalContainer";
import {MainContextProvider} from "../../../context/MainContext";

export const CreditDeletedModal: React.FC = () => {
    const { modalData, setModalType, setModalData } = useContext(MainContextProvider)

    const close = () => {
        setModalType('')
        setModalData(null)
    }

    return (
        <div>
            <div className={'flex justifyCenter'}>
                <img src={logoIcon} alt=""/>
            </div>
            <div className="heading4 text-center mt40">
                {modalData?.title}
            </div>
            <p className={'p1 color-blue text-uppercase text-center mt20'}>
                {modalData?.creditsSum} credit has been charged Successfully.
            </p>
            <p className={'p1 color-blue text-uppercase text-center mt10'}>
                {modalData?.subtitle}
            </p>
            <div className={'mt20'}>
                <div className={'planner-page__modal-container'}>
                    ✅ {modalData?.creditsSum} Credit
                </div>
            </div>
            <div>
                <button onClick={close} className={'btn btn-black w100 text-uppercase mt40'}>
                    OK
                </button>
            </div>
        </div>
    )
}