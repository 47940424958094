import React, {useContext, useEffect, useRef, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import pushNotifications from "../../../helpers/pushNotifications";
import {useHistory} from "react-router-dom";

type Props = {
    activeRequestTab: 'offer' | 'pr'
    setActiveRequestTab: (key: string) => void
    offer_id: string
    pr_id?: string
    active: 'partner_request' | 'offer'
}

export const OfferAndPartnerRequestTabs: React.FC<Props> = ({ offer_id, pr_id, activeRequestTab, setActiveRequestTab, active }) => {
    const { userData, centrifuge } = useContext(MainContextProvider)
    const history = useHistory()
    const [tabsCounter, setTabsCounter] = useState({ offers: 0, pr: 0 })
    const [offerCount, setOfferCount] = useState(0)
    const [prCount, setPrCount] = useState(0)
    const prRef: any = useRef()
    const offerRef: any = useRef()

    const getPrUnSeenMessages = async () => {
        const { data } = await customAxios.get(`/offer/${offer_id}/partner-request/new-comments-count`)
        setPrCount(data.reduce((initial: any, obj: { new_comments_count: string }) => {
            return initial+= +obj.new_comments_count
        }, 0))
    }

    const getOfferUnSeenMessages = async () => {
        const { data } = await customAxios.get(`/offer/${offer_id}/new-comments-count`)
        setOfferCount(data.reduce((initial: any, obj: { new_comments_count: string }) => {
            return initial+= +obj.new_comments_count
        }, 0))
    }

    const getAll = async () => {
        const { data: prData } = await customAxios.get(`/offer/${offer_id}/partner-request/new-comments-count`)
        const { data: offerData } = await customAxios.get(`/offer/${offer_id}/new-comments-count`)

        setOfferCount(offerData.reduce((initial: any, obj: { new_comments_count: string }) => {
            return initial+= +obj.new_comments_count
        }, 0))

        setPrCount(prData.reduce((initial: any, obj: { new_comments_count: string }) => {
            return initial+= +obj.new_comments_count
        }, 0))
    }

    useEffect(() => {
        getAll().then()

        prRef.current = setInterval(getPrUnSeenMessages, 10000)
        if (offerRef.current) {
            clearInterval(offerRef.current);
        }

        return () => {
            if (prRef.current) {
                clearInterval(prRef.current);
            }

            if (offerRef.current) {
                clearInterval(offerRef.current);
            }
        };
    }, [])

    useEffect(() => {
        if (activeRequestTab === 'pr') {
            offerRef.current = setInterval(getOfferUnSeenMessages, 10000)
            if (prRef.current) {
                clearInterval(prRef.current);
            }
        } else {
            if (offerRef.current) {
                clearInterval(offerRef.current);
            }
        }

    }, [activeRequestTab])

    /*let subscriptionData: any = []

    const connectToSocket = (key: string, type = 'pr') => {
        const ctData = centrifuge.subscribe(key, function(ctx: any) {
            if (ctx) {
                if (type === 'pr') {
                    setTabsCounter(prevState => ({
                        ...prevState,
                        pr: prevState.pr + 1
                    }))
                } else {
                    setTabsCounter(prevState => ({
                        ...prevState,
                        offers: prevState.offers + 1
                    }))
                }
            }
        });
        subscriptionData.push(ctData)
    }

    const checkCommentAsSeen = async (id: string) => {
        const {} = await customAxios.post(`/company/comment/${id}/seen`)
    }

    const connectOfferToSocket = async () => {
        const { status, data } = await customAxios.get(`/partner-request/offer/${offer_id}?order_by=title&direction=DESC`)
        if (data.length > 0) {
            data.map((el: any) => {
                connectToSocket(`partner-request-${el.id}#${userData?.user_id}`)
            })
        }
    }

    const connectPrToSocket = async () => {
        const { status, data } = await customAxios.get(`/partner-request/offer/${offer_id}?order_by=title&direction=DESC`)
        if (data.length > 1) {
            data.filter((pr: { id: string }) => +pr.id !== +(pr_id || '0')).map((el: any) => {
                connectToSocket(`partner-request-${el.id}#${userData?.user_id}`)
            })
        }

        connectToSocket(`offer-${offer_id}#${userData?.user_id}`, 'offer')
    }

    const getAllCounts = async () => {
        try {
            const { data: offers } = await customAxios.get(`/offer/${offer_id}/new-comments-count`)
            const { data: pr } = await customAxios.get(`/offer/${offer_id}/partner-request/new-comments-count`)
            setTabsCounter(prevState => ({
                offers: offers || 0,
                pr: pr || 0
            }))
        } catch (e) {}
    }

    useEffect(() => {
        let activeOfferCf: any;
        if (centrifuge && active === 'offer' && activeRequestTab === 'pr') {
            activeOfferCf = centrifuge.subscribe(`offer-${offer_id}#${userData?.user_id}`, function(ctx: any) {
                if(ctx?.data?.comments?.id) checkCommentAsSeen(ctx.data.comments.id).then()
            });
        } else {
            if (activeOfferCf) {
                activeOfferCf.unsubscribe()
            }
        }

        return () => {
            if (activeOfferCf) {
                activeOfferCf.unsubscribe()
            }
        }
    }, [activeRequestTab, centrifuge])

    useEffect(() => {
        if (userData?.status.type_code === 300) {
            getAllCounts().then()
            setTimeout(getAllCounts, 3000)
        }
    }, [offer_id, pr_id])

    useEffect(() => {
        if (centrifuge && userData?.status.type_code === 300) {
            if (active === 'offer') connectOfferToSocket().then()
            if (active === 'partner_request') connectPrToSocket()
            if (offer_id) {

            }
        }
        return () => {
            if (subscriptionData.length > 0) {
                subscriptionData.map((el: any) => {
                    el?.unsubscribe()
                })
            }
        }
    }, [offer_id, centrifuge, pr_id])*/

    if ((userData?.status.type_code !== 300) && (userData?.status.type_code !== 400)) {
        return <></>
    }

    if (active === 'partner_request') {
        return (
            <div>
                <div className="singleOffer__tabs">
                    <div
                        style={{ marginRight: tabsCounter.offers === 0 ? '30px' : '40px' }}
                        onClick={() => history.push(`/offer/${offer_id}`)}
                        className={`singleOffer__tabs-item`}>
                        Offer Draft {offerCount > 0 && <div className="singleOffer__tabs__counter">{offerCount}</div>}
                    </div>
                    <div
                        onClick={() => history.push(`/offer/${offer_id}?tab=pr`)}
                        className={`singleOffer__tabs-item singleOffer__tabs-item-active`}>
                        Partner Requests {prCount > 0 && <div className="singleOffer__tabs__counter">{prCount}</div>}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="singleOffer__tabs">
                <div
                    style={{ marginRight: tabsCounter.offers === 0 ? '30px' : '40px' }}
                    onClick={() => setActiveRequestTab('offer')}
                    className={`singleOffer__tabs-item ${activeRequestTab === 'offer' && 'singleOffer__tabs-item-active'}`}>
                    Offer Draft {offerCount > 0 && <div className="singleOffer__tabs__counter">{offerCount}</div>}
                </div>
                <div
                    onClick={() => history.push(`/offer/${offer_id}?tab=pr`)}
                    className={`singleOffer__tabs-item ${activeRequestTab === 'pr' && 'singleOffer__tabs-item-active'}`}>
                    Partner Requests {prCount > 0 && <div className="singleOffer__tabs__counter">{prCount}</div>}
                </div>
            </div>
        </div>
    )
}