import {RouterType} from "../helpers/types";
import {ManagerKanban} from "../containers/LegalManager/ManagerKanban/ManagerKanban";
import Settings from "../containers/Settings";
import {Offers} from "../containers/Client/Onboarding/OfferList/Offers";
import {Offer} from "../containers/Client/Offer/Offer";
import {LegalRequest} from "../containers/Client/LegalRequest/LegalRequest";
import TaskView from "../containers/Client/Task/TaskView";
import {TestRoute} from "../containers/TestRoute";
import {Checkout} from "../containers/Settings/Routes/Checkout/Checkout";
import NotFound from "../containers/NotFound/NotFound";
import {FinishStep} from "../components/other/EmailHaveBeenSent/FinishStep";
import {ArchiveBoard} from "../containers/LegalManager/ManagerKanban/ArchiveBoard";
import {ServiceProviderView} from "../containers/ServiceProvider/view/ServiceProviderView";
import {SearchResults} from "../containers/Other/SearchResults/SearchResults";
import {BusinessGoalsVloKanban} from "../containers/BusinessGoals/BusinessGoalsVloKanban/BusinessGoalsVloKanban";
import {BusinessGoalView} from "../containers/BusinessGoals/BusinessGoalView/BusinessGoalView";
import {TestOffer} from "../components/TestOffer";

export const MANAGER:RouterType[] = [
    {
        exact: true,
        path: '/dashboard',
        title: 'kanban',
        Component: ManagerKanban
    },
    {
        exact: false,
        path: '/settings/',
        title: 'Настройки',
        Component: Settings,
    },
    {
        exact: false,
        path: '/offers',
        title: 'Настройки',
        Component: Offers,
    },
    {
        exact: false,
        path: '/offer/:id',
        title: 'Offer',
        Component: Offer,
    },
    {
        exact: false,
        path: '/offer/:id',
        title: 'Offer',
        Component: Offer,
    },
    {
        exact: false,
        path: '/legal-request/:id',
        title: 'Legal Request',
        Component: LegalRequest,
    },
    {
        exact: false,
        path: '/tasks/:id',
        title: 'Задача',
        Component: TaskView,
    },
    {
        exact: false,
        path: '/checkout',
        title: 'Legal Request',
        Component: Checkout,
    },
    {
        exact: false,
        path: '/archive',
        title: 'Legal Request',
        Component: ArchiveBoard,
    },
    {
        exact: false,
        path: '/404',
        title: 'Legal Request',
        Component: NotFound,
    },
    {
        exact: true,
        path: '/finish-bank-payment',
        title: 'finish-bank-payment',
        Component: FinishStep,
    },
    {
        exact: false,
        path: '/partner-request/:id',
        title: 'Dashboard',
        Component: ServiceProviderView,
    },
    {
        exact: false,
        path: '/search',
        title: 'Dashboard',
        Component: SearchResults,
    },
    {
        exact: false,
        path: '/legal-roadmap/:id',
        title: 'Dashboard',
        Component: BusinessGoalView,
    },
    {
        exact: false,
        path: '/legal-roadmap',
        title: 'Dashboard',
        Component: BusinessGoalsVloKanban,
    },
    {
        exact: false,
        path: '/test',
        title: 'Legal Request',
        Component: TestRoute,
    },
    {
        exact: false,
        path: '/offer-test/:id',
        title: 'Legal Request',
        Component: TestOffer,
    },
]