import React from "react";
import ModalContainer from "../../../../../components/ModalContainer";
import logoIcon from "../../../../../assets/images/logo.svg"
import closeIcon from "../../../../../assets/images/planAndCredits/close.png"
import {useLocation} from "react-router-dom";

export const PaymentErrorModal: React.FC<{ isOpen: boolean, close: () => void }> = ({ isOpen, close }) => {
    const location = useLocation()
    const urlSearchParams = new URLSearchParams(location.search);
    const { credits } = Object.fromEntries(urlSearchParams.entries()) || {  };

    return (
        <div className={'payment-plan__modal'}>
            <ModalContainer isOpen={isOpen} close={close}>
                <div>
                    <div className={'payment-plan__modal__logo'}>
                        <img src={logoIcon} alt=""/>
                    </div>
                    <div className={'payment-plan__modal__text'}>
                        <h4 style={{ marginTop: '50px' }}>Payment Error</h4>
                        <p style={{ textAlign: 'center' }}>
                            We were unable to process your payment. Please double-check your payment information or try another method.
                            For further assistance, contact our support team.
                        </p>
                    </div>
                    <div className="payment-plan__modal-success">
                        <img src={closeIcon} alt=""/>
                        <span><b>{credits || '?'} credits</b> bundle.</span>
                    </div>
                    <button onClick={close} className="btn-black mt10 w100">OK</button>
                </div>
            </ModalContainer>
        </div>
    )
}