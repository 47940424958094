import draftIcon from '../../assets/images/partnerRequest/draft.svg'
import offer from '../../assets/images/partnerRequest/draft.svg'
import done from '../../assets/images/partnerRequest/done.svg'
import click from '../../assets/images/partnerRequest/click.svg'
import sentToClientIcon from '../../assets/images/kanbanIcons/whiteEmailSendIcon.svg'

export function partner_request_statuses(status: string, accepted: number, is_done: string) {
    if (+status === 6) {
        return {
            background: '#000',
            color: '#FFFFFF',
            icon: draftIcon,
            title: 'PROJECT REQUEST (EXPIRED)'
        }
    }

    if (+status === 7) {
        return {
            background: '#FF5C5C',
            color: '#FFFFFF',
            icon: click,
            title: 'CLIENT HAS DECLINED'
        }
    }

    if (is_done === '1') {
        return {
            background: '#5d8c51',
            color: '#FFFFFF',
            icon: done,
            title: 'DONE'
        }
    }

    if (+accepted === 1 && +status === 5) {
        return {
            background: '#F3B71B',
            color: '#FFFFFF',
            icon: click,
            title: 'PROJECT APPROVED'
        }
    }

    if (+accepted === 5) {
        return {
            background: '#2361FF',
            color: '#FFFFFF',
            icon: draftIcon,
            title: 'Work In-Progress'
        }
    }

    switch (status) {
        case '0': return {
            background: '#8E9EC8',
            color: '#FFFFFF',
            icon: draftIcon,
            title: 'Project  REQUEST'
        }
        case '1': return {
            background: '#8E9EC8',
            color: '#FFFFFF',
            icon: draftIcon,
            title: 'Project  REQUEST'
        }
        case '3': return {
            background: '#8E9EC8',
            color: '#FFFFFF',
            icon: draftIcon,
            title: 'Project  REQUEST'
        }
        case '4': return {
            background: '#000',
            color: '#FFFFFF',
            icon: sentToClientIcon,
            title: 'ESTIMATES SENT TO THE CLIENT'
        }
        case '5': return {
            background: '#F3B71B',
            color: '#FFFFFF',
            icon: offer,
            title: 'offer'
        }
        case '7': return {
            background: '#FF5C5C',
            color: '#FFFFFF',
            icon: click,
            title: 'CLIENT HAS DECLINED'
        }
        default: return {
            background: '#8E9EC8',
            color: '#FFFFFF',
            icon: draftIcon,
            title: 'partner request'
        }
    }
}