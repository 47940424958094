import customAxios from "../api/interceptors";

type BillingUpdateType = {
    name: string
    number: string
    country?: string
    city: string
    state: string
    address: string
    postal_code: string
    vat: string
    stripe_country_code: string,
    company_id?: string | null,
    currency?: string
    full_name: string
    sending_wallet_address: string
    payment_method: number
}

export const confirmBillingService = () => customAxios.post(`/company/billing-info/update?id=1`)

export const getBillingClientDataService = (client_id: string) => customAxios.get(`/company/billing-info/get-by-first-company?company_id=${client_id}`)

export const updateBillingClientDataService = (data: BillingUpdateType) => customAxios.post(`/company/billing-info/update-by-first-company`, data)