import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextArea } from '../../../components/UI'
import { sendTicket } from '../../../service/other'
import { toastifySuccess, toastifyError } from '../../../service/toastify/toastify'
import { MainContextProvider } from '../../../context/MainContext'

const SendSupport = () => {
  const { setModalType } = useContext(MainContextProvider)
  const [text, setText] = useState('')
  const { t } = useTranslation()

  const sendPostData = async () => {
    if (!text.trim()) {
      toastifyError(t('support.messageRequired'))
      return
    }
    try {
      const res = await sendTicket(text)
      if (res.status === 200) {
        toastifySuccess(t('support.messageWasSent'))
        setModalType('')
        setText('')
      }
    } catch (e) {
      console.log('sendTicket Error', e)
    }
  }

  return (
    <div style={{ marginTop: '30px' }}>
      <TextArea
        label={''}
        otherProps={{
          value: text,
          placeholder: t('support.enterMessage'),
          onChange: (event) => setText(event.target.value),
          rows: 8,
        }}
      />
      <button
        onClick={sendPostData}
        style={{ width: '100%', marginTop: '15px' }}
        className={'btn-black'}
      >
        {t('support.send')}
      </button>
    </div>
  )
}

export default SendSupport
