import React, {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import {GoogleLogin} from "react-google-login";
import { gapi } from 'gapi-script';
import customAxios from "../../../service/api/interceptors";
import {toastifyError, toastifySuccess} from "../../../service/toastify/toastify";
import axios from "axios";
import {getSingleTask} from "../../../service/tasks";
import './deliverableView.scss'
import hideIcon from '../../../assets/images/hide-page.webp'
import {useHistory} from "react-router-dom";
import backIcon from '../../../assets/images/backBlackIcon.svg'
import useDrivePicker from "react-google-drive-picker";

const clientId = "558346228491-drh21qu52oblv6mul74h5ia8822uevd0.apps.googleusercontent.com"

export const DeliverableView: React.FC<any> = ({ match }) => {
    const { params: { id } } = match
    const { setLoading, setApprovedDeliverables, approvedDeliverables, setGoogleAuthToken, userData } = useContext(MainContextProvider)
    const [token, setToken] = useState('')
    const [deliverableUrl, setDeliverableUrl] = useState('')
    const [offerId, setOfferId] = useState('')
    const [isFinish, setIsFinish] = useState(false)
    const history = useHistory()


    const checkAccessToken = async () => {
        setLoading(true)
        if(!localStorage.getItem(`googleAccessToken`)) {
            setLoading(false)
            return;
        }
        try {
            const { status, data } = await axios.get(`https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${localStorage.getItem(`googleAccessToken`)}`);
            if(status === 200 && data?.expires_in > 0) {
                setToken(localStorage.getItem(`googleAccessToken`) + '')
            }
        } catch (e) {}
        setLoading(false)
    }

    const getTaskById = async (id: number) => {
        const { status, data } = await getSingleTask(id)
        setOfferId(data.project_id)
        if(status === 200 && +data.status !== 5) {
            setIsFinish(true)
        }
    }

    const getDeliverableUrl = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/company/deliverables/${id}?expand=task`)
            if (status === 200) {
                setDeliverableUrl(data.url)
                await getTaskById(data.task_id)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: clientId,
                // scope: 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/documents',
                scope: 'https://www.googleapis.com/auth/documents',
                accessType: 'offline'
            });
        }
        gapi.load('client:auth2', start);
        getDeliverableUrl().then()
        checkAccessToken().then()
    }, []);

    const accept = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/company/deliverable/accept?id=${id}`)
            if (status === 200) {
                setApprovedDeliverables(approvedDeliverables.filter((el: any) => +el.id !== +id))
                setIsFinish(true)
                toastifySuccess('Success. Task has been updated')
            }
        } catch (e) {}
        setLoading(false)
    }

    const deny = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/company/deliverable/deny?id=${id}`)
            if (status === 200) {
                setApprovedDeliverables(approvedDeliverables.filter((el: any) => +el.id !== +id))
                setIsFinish(true)
                toastifySuccess('Success. Task has been updated')

            }
        } catch (e) {}
        setLoading(false)
    }

    const getDocument = async () => {
        try {
            const { status, data } = await axios.get(`${deliverableUrl}?access_token=${token}`)
        } catch (e){
            // setIsFinish(true)
            /*setIsFinish(true)
            // @ts-ignore
            if(e?.response?.status === 401) {
                await remove()
            // @ts-ignore
            } else if(e?.response?.status === 403) {
                setIsFinish(true)
            }*/
        }
    }

    useEffect(() => {
        if(token && deliverableUrl) {
            getDocument().then()
        }
    }, [token, deliverableUrl])

    const responseGoogleSuccess = (response: any) => {
        console.log('responseGoogleSuccess', response);
        if(response?.accessToken) {
            if(!response?.xc?.scope?.includes('https://www.googleapis.com/auth/documents')) {
                toastifyError('You have to choose document access on second step of login window!')
                remove().then()
                return;
            }
            setGoogleAuthToken(response.accessToken)
            setToken(response.accessToken)
            localStorage.setItem('googleAccessToken', response.accessToken)
            localStorage.setItem('googleProfileObj', JSON.stringify(response.profileObj))
        }
    }

    const responseGoogleFailure = (response: any) => {
        toastifyError('Something went wrong.')
        console.log('responseGoogleFailure', response);
    }

    const [hide, setHide] = useState(false)

    const remove = async () => {
        setLoading(true)
      // const {} = await axios.delete(`https://admin.googleapis.com/admin/directory/v1/users/techua.com@gmail.com/tokens/829041807368-6rr03l1p83br8f4a42vsk1q7m07f3t0k.apps.googleusercontent.com`)
      //   var auth2 = gapi.auth2.getAuthInstance();
      //   auth2.signOut().then(function () {
      //       console.log('User signed out.');
      //       setToken('')
      //       setGoogleAuthToken('')
      //       localStorage.removeItem('googleAccessToken')
      //       localStorage.removeItem('googleProfileObj')
      //   });
        gapi.auth2.getAuthInstance().disconnect();
        setToken('')
        setGoogleAuthToken('')
        localStorage.removeItem('googleAccessToken')
        localStorage.removeItem('googleProfileObj')
        setLoading(false)
    }


    const [openPicker, authResponse] = useDrivePicker();
    // const customViewsArray = [new google.picker.DocsView()]; // custom view
    const handleOpenPicker = () => {
        openPicker({
            clientId: clientId,
            developerKey: "AIzaSyBXB2-AU8nY6nLxC-LvEHyB8ogBbPe7fFo",
            viewId: "DOCS",
            // token: token, // pass oauth token in case you already have one
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: false,
            token: token,
            // customViews: customViewsArray, // custom view
            callbackFunction: (data) => {
                if (data.action === 'cancel') {
                    console.log('User clicked cancel/close button')
                }
                console.log('data', data)
                if(data && data.action === 'picked') {
                    setDeliverableUrl(`https://docs.google.com/document/d/${data.docs[0].id}/edit`)
                }
            },
        })
    }

    const sss = () => {
        var body = {
            'value': 'uastal.org@gmail.com',
            'type': 'user',
            'role': 'writer'
        };
        var request = gapi.client.drive.permissions.insert({
            'fileId': '1ss8PqB4CH8r2GqeBh3Q9R_j4s_c31k3nyLmTSEXUFTM',
            'resource': body
        });
        console.log('request', request)
    }

    const openAccess = async () => {
        // gapi.client.drive
        // axios.get(`https://www.googleapis.com/drive/v2/permissionIds/uastal.org@gmail.com?access_token=${token}`)
        gapi.client.setApiKey("AIzaSyBXB2-AU8nY6nLxC-LvEHyB8ogBbPe7fFo");
        gapi.client.load("https://content.googleapis.com/discovery/v1/apis/drive/v2/rest")

        setTimeout(sss, 3000)

    }

    return (
        <div>
            {/*<button onClick={openAccess}>open access</button>
            <button onClick={() => handleOpenPicker()}>Open Drive</button>*/}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '5px' }}>
                <button onClick={() => history.push(`/offer/${offerId}`)} className={'googleLNButton'} style={{ boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px' }}>
                    <img src={backIcon} alt="" style={{ marginRight: '10px' }} />
                    Back to offer
                </button>
                {token && <button onClick={remove} className={'googleLNButton'} style={{ boxShadow: 'rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px' }}>
                    Logout of Google
                </button>}
                {/*{
                    token && <GoogleLogout
                        clientId={"558346228491-drh21qu52oblv6mul74h5ia8822uevd0.apps.googleusercontent.com"}
                        onLogoutSuccess={() => {
                            remove().then()
                        }}
                    />
                }*/}
                {token && userData?.status.type_code === 100 && '' && <div>
                    <button disabled={isFinish} style={{ height: '42px', textTransform: 'uppercase', width: '140px' }} onClick={accept} className="all_approve_deliverables__button">Approve</button>
                    <button disabled={isFinish} style={{ height: '42px', textTransform: 'uppercase', width: '140px' }} onClick={deny} className="all_approve_deliverables__deny all_approve_deliverables__button">Deny</button>
                </div>}
            </div>
            <br />
            {
                !token && <div style={{ minHeight: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#fff' }}>
                    <div>
                        <span>If you want to see deliverable, please </span>
                        <GoogleLogin
                            clientId={clientId}
                            buttonText="Login with Google"
                            onSuccess={responseGoogleSuccess}
                            onFailure={responseGoogleFailure}
                            theme={'dark'}
                            disabled={false}
                        />
                    </div>
                </div>
            }
            {token && deliverableUrl && <div className={'deliverableView'}>
                <iframe
                    // style={{ height: '100vh' }}
                    className={'deliverableView__iframe'}
                    width={'100%'}
                    src={`${deliverableUrl}?access_token=${token}`}
                />
                {!isFinish && <div className={'deliverableView__window'} style={{ width: hide ? '150px' : '500px' }}>
                    {!hide ? <div>
                        <div className={'deliverableView__header'}>
                            <h4>Please review this document</h4>
                            <img onClick={() => setHide(!hide)} src={hideIcon} alt=""/>
                        </div>
                        <div className="deliverableView__body">
                            <a>View VLO's description of this document</a>
                            <span>If you have any questions regarding this document - leave a comment.</span>
                            <span>If you are satisfied with the results of this work - click "Approve".</span>
                            <span>If there is something you want changed - click "Request edits</span>
                            <div className={'deliverableView__buttons'}>
                                <button style={{ textTransform: 'uppercase' }} disabled={isFinish} onClick={accept} className="all_approve_deliverables__button">Approve</button>
                                <button style={{ textTransform: 'uppercase' }} disabled={isFinish} onClick={deny} className="all_approve_deliverables__deny all_approve_deliverables__button">Request edits</button>
                            </div>
                        </div>
                    </div> : <div className={'deliverableView__minimized'}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <img onClick={() => setHide(!hide)} src={hideIcon} alt=""/>
                        </div>
                        <div className={'deliverableView__minimized-buttons'}>
                            <button style={{ textTransform: 'uppercase' }} disabled={isFinish} onClick={accept} className="all_approve_deliverables__button">Approve</button>
                            <button style={{ textTransform: 'uppercase' }} disabled={isFinish} onClick={deny} className="all_approve_deliverables__deny all_approve_deliverables__button">Request edits</button>
                        </div>
                    </div>}
                </div>}
            </div>}
        </div>
    )
}