import React from "react";
import icon from "../../../../assets/images/partnerRequest/expiredRightColumn.svg"
import {PartnerRequestType} from "../PartnerRequestType";
import {dateFormatter} from "../../../../helpers/dateFormatter";

type Props = {
    request: PartnerRequestType
}

export const PrExpiredRightColumn: React.FC<Props> = ({ request }) => {
    return (
        <div className={'pr-client-tasks-accepted'}>
            <div className="pr-client-tasks-accepted__header">
                <img src={icon} alt=""/>
                <span className={'text-uppercase'}>This request has expired</span>
            </div>
            <div className="pr-client-tasks-accepted__body">
                <em>UPDATED ON: {dateFormatter(request?.updated_at)}</em>
                <p>
                    Unfortunately, we have not received your task list and price & time estimates before the expiration date of this request.
                    We hope to have the opportunity to work with you on the next project.
                </p>
                {/*<a href="#">Expand Tasks & Estimates</a>*/}
            </div>
        </div>
    )
}