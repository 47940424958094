import {TextArea} from "../../../components/UI";
import React, {useContext, useEffect, useState} from "react";
import customAxios from "../../../service/api/interceptors";
import {MainContextProvider} from "../../../context/MainContext";
import controlErrorValid from "../../../components/UI/ControlValid/controlErrorValid";
import {toastifySuccess} from "../../../service/toastify/toastify";

export const LegalTaskDeliverable = () => {
    const { modalData, setModalType, setModalData } = useContext(MainContextProvider)
    const [value, setValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const updateDeliverable = async () => {
        const valid = controlErrorValid(value, { required: true, url: true })
        if(valid && typeof valid === 'string') {
            setError(valid)
            return;
        }
        else setError('')

        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`/company/deliverables/${modalData?.id}`, {
                name: modalData?.name,
                description: value,
                url: value,
                task_id: modalData.task_id
            })
            if(status === 200 || status === 201) {
                toastifySuccess('Deliverable has been updated')
                setModalType('')
                setModalData({ update: true })
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        if(modalData) {
            setValue(modalData.url)
        }
    }, [modalData])

    return (
        <div style={{ position: "relative" }}>
            <p className={'create_manager_offer__description'}>
                Please, enter deliverable URL for task "{`${modalData?.name}`}"
            </p>
            <TextArea
                otherProps={{
                    value: value, rows: 4,
                    onChange: e => setValue(e.target.value)
                }}
                errorMessage={error} valid={!error}
            />
            <button
                onClick={updateDeliverable}
                disabled={!value}
                style={{ width: '100%', marginTop: '20px' }} className={'btn-black'}>Save</button>
        </div>
    )
}