import React, { useContext, useEffect, useCallback } from 'react'
import { DeliverablesType } from './Deliverables.type'
import './deliverables.scss'
import Button from '../Button/Button'
import { MainContextProvider } from '../../../../../../context/MainContext'
import editIcon from '../../../../../../assets/images/pen.svg'
import { toastifySuccess } from '../../../../../../service/toastify/toastify'
import { useTranslation } from 'react-i18next'

const Deliverables:React.FC<DeliverablesType> = ({
  deliverables, changeDeliverables, state, trackerEvent, updateTaskFunc, role
}) => {
  const {
    modalType,
    setModalType,
    setModalData,
    modalData,
    userData,
  } = useContext(MainContextProvider)
  const { t } = useTranslation()

  const getData = useCallback(() => {
    if (modalType === 'deliverables-modal' && modalData.type) {
      if (modalData.type === 'update') {
        toastifySuccess(t('taskBody.deliverableWasUpdated'))
        changeDeliverables(modalData.item)
        setModalType('')
        setModalData(null)
        if (userData?.status.type_code === 200 && trackerEvent) {
          trackerEvent('task_updated', { action: 'deliverables_updated' })
        }
      } else if (modalData.type === 'delete') {
        toastifySuccess(t('taskBody.deliverableWasDeleted'))
        changeDeliverables('')
        setModalType('')
        setModalData(null)
      }
    }
  }, [modalData, setModalData, modalType])

  useEffect(() => {
    getData()
  }, [getData, modalData])

  const data = {
    budget: state.price_estimate,
    deadline: state.deadline,
    time_estimate: state.time_estimate,
    price_estimate: state.price_estimate,
    project_id: state.project_id,
    title: state.title,
    client_story: state.client_story,
    status: state.status,
    type: state.type,
    deliverables: state.deliverables,
    id: state.id,
  }

  return (
    <div className={'deliverables'}>
      {
        !deliverables && state.role === 'provider' && state.status + "" !== "3" ? (
          <Button
            label={t('taskBody.createDeliverable')}
            onChange={() => {
              setModalType('deliverables-modal')
              setModalData({ type: 'createModal', item: data })
            }}
          />
        ) : (
          <div className={'deliverables__desc'}>
            <p className={'deliverables__desc-block'} dangerouslySetInnerHTML={{ __html: deliverables }} />
            <div className={'flex-center'}>
              {state.role === 'provider' && state.status + '' !== '3' && (
                  <a
                      onClick={() => {
                        setModalType('deliverables-modal')
                        setModalData({ type: 'updateModal', item: data, configTitle: 'Update deliverables' })
                      }}
                      className={'deliverables__desc-edit'}
                  >
                    <img src={editIcon} alt={''} />
                  </a>
              )}
              {deliverables.trim().length > 0 && role === 'provider' && <a onClick={() => updateTaskFunc({ deliverables: '' }, 'singleItem', 'Deliverables have been deleted')} className="milestones__delete">✕</a>}
            </div>
          </div>
        )
}
    </div>
  )
}

export default Deliverables
