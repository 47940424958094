import React, {useEffect, useState} from "react";
import iconLeft from "../../../../assets/images/partnerRequest/left.svg"
import customAxios from "../../../../service/api/interceptors";
import {useHistory} from "react-router-dom";

type Props = {
    offer_id: string
    request_id: string
}

export const RequestSwitch: React.FC<Props> = ({ offer_id, request_id }) => {
    const history = useHistory()
    const [items, setItems] = useState<{ id: string }[]>([])

    const getItems = async () => {
        try {
            const { status, data } = await customAxios.get(`/partner-request/offer/${offer_id}?order_by=title&direction=DESC`)
            if (status === 200) {
                setItems(data)
            }
        } catch (e) {}
    }

    useEffect(() => {
        if (offer_id) getItems().then()
    }, [offer_id])

    if (items.length <= 1 || !offer_id || !request_id) {
        return <></>
    }
    
    const showPrevButton = () => {
        const findThisIndex = items.findIndex((el: { id: string }) => +el.id === +request_id)
        return items[findThisIndex - 1]?.id
    }

    const showNextButton = () => {
        const findThisIndex = items.findIndex((el: { id: string }) => +el.id === +request_id)
        return items[findThisIndex + 1]?.id
    }

    return (
        <div className={'pr-switch'}>
            {showPrevButton() ? <div onClick={() => history.push(`/partner-request/${showPrevButton()}`)} className="pr-switch__item">
                <div className="pr-switch__button">
                    <img src={iconLeft} alt=""/>
                </div>
                <span>previous REQUEST</span>
            </div> : <div />}
            {showNextButton() && <div
                onClick={() => history.push(`/partner-request/${showNextButton()}`)}
                className="pr-switch__item">
                <span>NEXT REQUEST</span>
                <div className="pr-switch__button pr-switch__button-reversed">
                    <img src={iconLeft} alt=""/>
                </div>
            </div>}
        </div>
    )
}