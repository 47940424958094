import { methodGet } from '../api/api'
import { apiPath } from '../api/constants'
import { DefaultResponseType } from '../../types/custom/defaultResponse.type'
import { UserProfileResponse } from './userProfile.type'

const getUserProfile = async (): Promise<DefaultResponseType<UserProfileResponse>> => {
  const { headers, data } = await methodGet({
    path: apiPath.userProfile,
  })

  return {
    data,
    headers,
  }
}

export {
  getUserProfile,
}
