import React, { useContext } from 'react'
import './payment-method-modal.scss'
import { useTranslation } from 'react-i18next'
import { CheckboxItem, Input } from '../../../components/UI'
import { MainContextProvider } from '../../../context/MainContext'

const PaymentMethodModal = () => {
  const { setModalType } = useContext(MainContextProvider)
  const { t } = useTranslation()
  return (
    <>
      <h3>{'Card Info'}</h3>
      <Input
        otherProps={{
          placeholder: t('paymentModal.cardholderName'),
        }}
        className={'payment-method-modal__input'}
      />
      <Input
        otherProps={{
          placeholder: t('paymentModal.cardNumber'),
        }}
        className={'payment-method-modal__input'}
      />
      <div className={'payment-method-modal__data'}>
        <Input
          otherProps={{
            placeholder: t('paymentModal.expirationDate'),
          }}
          className={'payment-method-modal__data-input'}
        />
        <Input
          otherProps={{
            placeholder: t('paymentModal.cvv'),
            type: 'password',
          }}
          className={'payment-method-modal__data-input'}
        />
      </div>
      <CheckboxItem
        label={t('paymentModal.defaultPaymentMethod')}
        active
        onChange={() => {}}
      />
      <button className={'btn-black payment-method-modal__button'}>{t('paymentModal.button')}</button>
    </>
  )
}

export default PaymentMethodModal
