import modules from './app.module.scss'
import firstStepModules from './first_step.module.scss'
import {useContext, useEffect, useState} from "react";
import logoIcon from '../../assets/images/registration/logo-svg.svg'
import axios from 'axios'
import {controlErrorFunc} from "../../components/UI/ControlValid/controlErrorValid";
import {FinishStep} from "../../components/other/Registration/FinishStep/FinishStep";
import {ModalLoader} from "../../components/other/ModalLoader/ModalLoader";
import {CustomCheckbox} from "../../components/other/Registration/CustomCheckbox/CustomCheckbox";
import {MainContextProvider} from "../../context/MainContext";
import {toastifyError} from "../../service/toastify/toastify";


const Input = ({ inputProps, error, parentStyle }) => {
    return (
        <div style={parentStyle} className={inputProps.className}>
            <input
                {...inputProps}
                className={modules.input}
            />
            {error && <p className={modules.error}>{error}</p>}
        </div>
    )
}

export const SighUpPage = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const query = Object.fromEntries(urlSearchParams.entries());
    const { setLoading } = useContext(MainContextProvider)
    const [isFinish, setIsFinish] = useState(false)
    const [containerLoading, setContainerLoading] = useState(false)
    const [checked, setChecked] = useState(false)
    const [errors, setErrors] = useState({})
    const [values, setValues] = useState({
        first_name: "",
        last_name: "",
        company: "",
        email: "",
        password: "",
        password_repeat: ""
    })

    const validationConfig = {
        first_name: { required: true },
        last_name: { required: true },
        company: { required: !query?.inviteToken },
        email: { required: true, email: true },
        password: { required: true, minLength: 8, maxLength: 14 },
        password_repeat: { required: true, minLength: 8, maxLength: 14 }
    }

    useEffect(() => {
        setLoading(false)
    }, [])

    const saveData = async () => {
        const err = controlErrorFunc({ obj: values, config: validationConfig })
        if(values.password !== values.password_repeat) {
            err.password = 'Passwords mismatch'
            err.password_repeat = 'Passwords mismatch'
        }
        if(Object.keys(err).length > 0) {
            setErrors(err)
            return
        } else {
            setErrors({})
        }

        setContainerLoading(true)
        const { first_name, last_name, company, email, password, password_repeat } = values
        const postData = {
            fullname: `${first_name} ${last_name}`,
            company, email, password, password_repeat,
            onboarding_url: query?.param,
            flow_id: query?.flow_id || 1,
            available_subscriptions: query?.available_subscriptions ? (query?.available_subscriptions || []).split(',').map(el => Number(el)) : null
        }

        const endpoint = query?.inviteToken ?
            `${process.env.REACT_APP_BASE_API}/v1/user/client/create/sign-up-by-invite` :
            `${process.env.REACT_APP_BASE_API}/user/client/create`

        if(query?.inviteToken) {
            postData['token'] = query?.inviteToken;
        }
        try {
            const { data, status } = await axios.post(endpoint, postData, {headers: { 'Content-Type': 'application/json' }})
            if(status === 200 && (data.client_id || data.user_id)) {
                setIsFinish(true)
            } else {
                if (data === "Token is not active.") {
                    toastifyError("Token is not active.")
                } else {
                    toastifyError('Something went wrong!')
                }
            }
        } catch (e){
            if(e?.response?.status === 400 || e?.response?.status === 404) {
                toastifyError(e?.response?.data.message)
            }
        }
        setContainerLoading(false)
    }

    if(containerLoading) {
        return <ModalLoader />
    }

    return isFinish ? <FinishStep /> : (
        <div className={'registration-quiz'} style={{ position: 'relative', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <div className={'registration-quiz__mobile-header'}>
                <img style={{ width: '65px' }} src={logoIcon} alt=""/>
            </div>
            <div className="registration-quiz__header">
                <div className="registration-quiz__header-container">
                    <h3>Registration</h3>
                    {/*<b>{step}/4</b>*/}
                </div>
            </div>
            <div className="registration-quiz__line">
                <div className="registration-quiz__line-abs" style={{ left: '25%' }} />
                <div className="registration-quiz__line-abs" style={{ left: '50%' }} />
                <div className="registration-quiz__line-abs" style={{ left: '75%' }} />
                <div className="registration-quiz__line-active" style={{ width: `${4 * 25}%`}} />
            </div>
            <div className={'registration-quiz__body'} style={{ flexGrow: 1 }}>
                <div className={modules.container}>
                    <div>
                        <div className={modules.label}>PERSONAL INFO</div>
                        <div style={{ display: 'flex' }}>
                            <Input
                                parentStyle={{ marginRight: '12px' }}
                                inputProps={{
                                    value: values.first_name,
                                    onChange: (e) => setValues({ ...values, first_name: e.target.value }),
                                    className: `${modules.w50}`,
                                    placeholder: 'First name'
                                }}
                                error={errors.first_name}
                                errorClass={modules.w50}
                            />
                            <Input
                                inputProps={{
                                    value: values.last_name,
                                    onChange: (e) => setValues({ ...values, last_name: e.target.value }),
                                    className: modules.w50,
                                    placeholder: 'Last name'
                                }}
                                error={errors.last_name}
                                errorClass={modules.w50}
                            />
                        </div>
                        {!query?.inviteToken && <Input
                            inputProps={{
                                value: values.company,
                                onChange: (e) => setValues({ ...values, company: e.target.value }),
                                placeholder: 'Company'
                            }}
                            error={errors.company}
                        />}
                        <Input
                            inputProps={{
                                value: values.email,
                                onChange: (e) => setValues({ ...values, email: e.target.value }),
                                placeholder: 'Email'
                            }}
                            error={errors.email}
                        />

                        <div style={{ marginTop: '34px' }} className={modules.label}>PASSWORD</div>
                        <Input
                            inputProps={{
                                value: values.password,
                                onChange: (e) => setValues({ ...values, password: e.target.value }),
                                placeholder: 'Password',
                                type: 'password'
                            }}
                            error={errors.password}
                        />
                        <Input
                            inputProps={{
                                value: values.password_repeat,
                                onChange: (e) => setValues({ ...values, password_repeat: e.target.value }),
                                placeholder: 'Repeat password',
                                type: 'password'
                            }}
                            error={errors.password_repeat}
                        />

                        <CustomCheckbox
                            isStopPropagation
                            className={modules.checkbox} checked={checked}
                            onChange={()=> setChecked(!checked)} label={'I’ve read and accepted ' +
                        '<a style="color: #000000" target="_blank" href="https://bit.ly/3iYh08T">Terms & Conditions</a> and <a style="color: #000000" target="_blank" href="https://bit.ly/3u0gSMm">Privacy Policy</a>.'} />
                        <button disabled={!checked} onClick={saveData} style={{ margin: '43px auto 0 auto' }} className={firstStepModules.nextButton}>
                            <span>CREATE ACCOUNT</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}