// @ts-ignore FIX_ME
import is from 'is_js'

type ValidationItem = {
    required?: boolean;
    email?: boolean;
    minLength?: number;
    maxLength?: number;
    number?: boolean;
    url?: boolean;
    editor?: boolean;
    minNumber?: number;
    maxNumber?: number;
    decimal?: boolean;
    hexadecimal?: boolean
}

export interface ControlValidType {
    (value?: string | number, validation?: ValidationItem): boolean | string
}

let controlErrorValid: ControlValidType

controlErrorValid = function (value, validation) {
    // console.log(validation, value)
    // if(typeof validation?.minNumber == 'number') {
    //     console.log()
    // }
    if (!validation) {
        return false

    }

    if (validation.required) {
        if (!value) {
            return "This field is required"
        }
        if (typeof value === 'string' && value.trim() === '') {
            return "This field is required"
        } else if(typeof value === 'number' && String(value).length === 0) {
            return "This field is required"
        }
    }

    if (validation.email && typeof value === 'string' && value.trim() !== '' && !is.email(value)) {
        return "enter correct email"
    }

    if (validation.minLength && String(value).trim() !== '' && String(value).trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').length < validation.minLength) {
        return `min length is ${validation.minLength} symbols (${String(value).trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').length})`
    }

    if (validation.maxLength && String(value).trim() !== '' && String(value).trim().replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').replace(/&nbsp;/gi, ' ').length > validation.maxLength) {
        return `max length is ${validation.maxLength} symbols (${String(value).replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '').replace(/&nbsp;/gi, ' ').length})`
    }

    if (validation.number && !is.number(Number(value))) {
        return "field is not number"
    }

    if (validation.decimal && is.decimal(Number(value))) {
        return "field can't be decimal"
    }

    if (validation.hexadecimal && !is.hexadecimal(value)) {
        return "field have to be hexadecimal"
    }

    if (typeof validation?.minNumber == "number" && Number(value) < validation.minNumber) {
        return "field value is less min number"
    }

    if (typeof validation?.maxNumber == "number" && Number(value) < validation.maxNumber) {
        return `field value is more min number`
    }


    if (validation.url && typeof value === 'string' && value.trim() !== '' && !is.url(value)) {
        return "enter correct url"
    }

    return false
}

type controlErrorType = {
    obj: { [key: string]: any },
    config: { [key: string]: ValidationItem },
}

export const controlErrorFunc = ({ obj, config }: controlErrorType): any => {
    const errors: { [key: string]: any } = {};
    Object.keys(config).map(key => {
        const error = config[key].editor ? controlErrorValid(obj[key].replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, ''), config[key]) : controlErrorValid(obj[key], config[key]);
        if(error) {
            errors[key] = error
        }
    })
    return errors;
}

export default controlErrorValid