import ModalContainer from "../../../components/ModalContainer";
import React, {useContext, useEffect, useState} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import customAxios from "../../../service/api/interceptors";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../service/toastify/toastify";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {Input, ReactEditor} from "../../../components/UI";
import logoIcon from "../../../assets/images/logo.svg";
import {MainContextProvider} from "../../../context/MainContext";
import {CreditControlModal} from "../../Modals/Credits/CreditControlModal";

export const CreateRequestFromBG: React.FC<any> = ({ isOpen, close, project_suggestion, company_id }) => {
    const { setLoading, getCreditCount, setModalType, setModalData, creditCount, userData } = useContext(MainContextProvider)
    const [values, setValues] = useState<{ [key: string]: string }>({
        title: '',
        description: ''
    })
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [haveToBeApproveModal, setHaveToBeApproveModal] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (isOpen && project_suggestion) {
            if (userData?.status.type_code === 100) getCreditCount()
            setValues({
                title: project_suggestion?.title,
                description: project_suggestion?.description
            })
        } else {
            setHaveToBeApproveModal(false)
        }
    }, [isOpen])

    const createRequest = async () => {
        const validReq = controlErrorFunc({ obj: values, config: {
                title: { required: true },
                description: { required: true }
            }})
        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`/project-suggestion/${project_suggestion.id}/request`, {
                companyId: company_id,
                description: values.description,
                title: values.title
            })

            if (status === 200 || status === 201) {
                if (creditCount.isCreditSubscription) {
                    setModalType('credit_delete_modal')
                    setModalData({
                        creditsSum: 1,
                        title: values.title,
                        subtitle: 'YOUR REQUEST HAS BEEN SENT TO YOUR VLO AND WILL NOW APPEAR ON YOUR ACTIVE BOARD.'
                    })
                    setTimeout(getCreditCount, 2000)
                }

                close()
                history.push(`/legal-request/${data?.id}`)
            }
        } catch (e) {}
        setLoading(false)
    }

    const createRequestSubmit = async () => {
        const validReq = controlErrorFunc({ obj: values, config: {
                title: { required: true },
                description: { required: true }
            }})
        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        setHaveToBeApproveModal(true)
    }

    return (
        <div>
            <ModalContainer isOpen={isOpen} close={close} heading={'Create a new request'}>
                <div>
                    {haveToBeApproveModal && <CreditControlModal
                        submit={createRequest}
                        close={() => setHaveToBeApproveModal(false)}
                        creditsSum={1}
                        commonTitle={values.title}
                        noCreditsSubtitle={'INSUFFICIENT CREDITs'}
                        noCreditsDescription={`This request requires <b>1 Credit</b>. You do not have enough Credits to proceed. This request requires 1 Credit. You do not have enough Credits to proceed. You can purchase more Credits now and then go to your Legal Roadmap to submit this request.`}
                    />}
                    {!haveToBeApproveModal && <>
                        <div className={'mt15'}>
                            <Input
                                label={'Title'}
                                otherProps={{
                                    value: values.title,
                                    onChange: e => setValues({ ...values, title: e.target.value })
                                }}
                                valid={!errors?.title}
                                errorMessage={errors.title}
                            />
                        </div>
                        <div className={'mt15'}>
                            <ReactEditor
                                value={values.description}
                                onChange={e => setValues({ ...values, description: e })}
                                valid={!errors?.description}
                                errorMessage={errors.description}
                            />
                        </div>
                        <button
                            onClick={creditCount.isCreditSubscription ? createRequestSubmit : createRequest}
                            className={'w100 btn-black text-uppercase mt20'}>CREATE</button>
                    </>}
                </div>
            </ModalContainer>
        </div>
    )
}




type ApproveSubmitToVloProps = {
    title: string
    credits: number
    close: () => void
    ok: () => void
}

const ApproveSubmitToVlo: React.FC<ApproveSubmitToVloProps> = ({ title, credits, close, ok }) => {
    return (
        <div>
            <div className={'flex justifyCenter'}>
                <img src={logoIcon} alt=""/>
            </div>
            <div className="heading4 text-center mt40">
                {title}
            </div>
            <p className={'p1 color-blue text-uppercase text-center mt20'}>
                1 credit will be charged. This will enable your VLO
                to begin working on your request.
            </p>
            <div className={'mt20'}>
                <div className={'planner-page__modal-container'}>
                    Credits Balance: <b className={'text-weight-600'}>{credits}</b>
                </div>
            </div>
            <div>
                <button onClick={ok} className={'btn btn-black w100 text-uppercase mt40'}>
                    SUBMIT TO VLO
                </button>
                <button onClick={close} className={'btn-white w100 text-uppercase mt10'}>
                    GO BACK
                </button>
            </div>
        </div>
    )
}




type NoCreditsModalProps = {
    title: string
    credits: number
    close: () => void
    ok: () => void
}

const NoCreditsModal: React.FC<NoCreditsModalProps> = ({ title, credits, close, ok }) => {
    return (
        <div>
            <div className={'flex justifyCenter'}>
                <img src={logoIcon} alt=""/>
            </div>
            <div className="heading4 text-center mt40">
                {title}
            </div>
            <p className={'p1 color-blue text-uppercase text-center mt20'}>
                INSUFFICIENT CREDITs
            </p>
            <p className={'p1 text-center mt20'}>
                This request requires <b className={'text-weight-600'}>1 Credit</b>. You do not have enough Credits to proceed.
                This request requires 1 Credit. You do not have enough Credits to proceed.
                You can purchase more Credits now and then go to your Legal Roadmap to submit this request.
            </p>
            <div className={'mt20'}>
                <div className={'planner-page__modal-container'}>
                    Credits Balance: <b className={'text-weight-600'} style={{ color: '#FF0000' }}>{'0'}</b>
                </div>
            </div>
            <div>
                <button onClick={() => window.location.href = '/settings/payment-plan'} className={'btn btn-black w100 text-uppercase mt40'}>
                    BUY cREDITS
                </button>
                <button onClick={close} className={'btn-white w100 text-uppercase mt10'}>
                    GO BACK
                </button>
            </div>
        </div>
    )
}


const CreditHaveBeenDeleted: React.FC<{ close: () => void, title: string }> = ({ close, title }) => {
    return (
        <div>
            <div className={'flex justifyCenter'}>
                <img src={logoIcon} alt=""/>
            </div>
            <div className="heading4 text-center mt40">
                {title}
            </div>
            <p className={'p1 color-blue text-uppercase text-center mt20'}>
                1 credit has been charged Successfully.
            </p>
            <p className={'p1 color-blue text-uppercase text-center mt10'}>
                your request has been sent to your VLO AND
                WILL NOW APPEAR ON YOUR ACTIVE BOARD.
            </p>
            <div className={'mt20'}>
                <div className={'planner-page__modal-container'}>
                    ✅ 1 Credit
                </div>
            </div>
            <div>
                <button onClick={close} className={'btn btn-black w100 text-uppercase mt40'}>
                    OK
                </button>
            </div>
        </div>
    )
}