import React from "react";
import ModalContainer from "../../../../../components/ModalContainer";
import logoIcon from "../../../../../assets/images/logo.svg"
import okIcon from "../../../../../assets/images/planAndCredits/ok.png"
import {useLocation} from "react-router-dom";

export const PaymentSuccessfulModal: React.FC<{ isOpen: boolean, close: () => void }> = ({ isOpen, close }) => {
    const location = useLocation()
    const urlSearchParams = new URLSearchParams(location.search);
    const { credits } = Object.fromEntries(urlSearchParams.entries()) || {  };

    return (
        <div className={'payment-plan__modal'}>
            <ModalContainer isOpen={isOpen} close={close}>
                <div>
                    <div className={'payment-plan__modal__logo'}>
                        <img src={logoIcon} alt=""/>
                    </div>
                    <div className={'payment-plan__modal__text'}>
                        <em>CREDITS</em>
                        <h4>Thank you! Payment<br /> successful.</h4>
                    </div>
                    <div className="payment-plan__modal-success">
                        <img src={okIcon} alt=""/>
                        <span><b>{credits || '?'} credits</b> bundle.</span>
                    </div>
                    <button onClick={close} className="btn-black mt10 w100">OK</button>
                </div>
            </ModalContainer>
        </div>
    )
}