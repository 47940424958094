import React, { useContext, useState } from 'react'
import './task-body.scss'
import { useTranslation } from 'react-i18next'
import taskIcon from '../../../../../../assets/images/taskIcon.svg'
import buttonIcon from '../../../../../../assets/images/task-button-subscribe.png'
import penIcon from '../../../../../../assets/images/pen.svg'
import TaskStatus from '../TaskStatus/TaskStatus'
import { TASK_STATUSES } from '../../taskHelpers'
import { MainContextProvider } from '../../../../../../context/MainContext'
import { TaskStateType } from '../../TaskView.type'
import { dateFormatter } from '../../../../../../helpers/dateFormatter'
import DropDown from '../../../../../../components/other/DropDown'
import {toastifyError} from "../../../../../../service/toastify/toastify";

const TaskBody: React.FC<TaskStateType> = ({
  created_at,
  updated_at,
  subscription,
  status,
  executors,
  title,
  price_estimate,
  time_estimate,
  total_price,
  deadline,
  client_story,
  setStatus,
  project_id,
  id,
  role,
  moveToArchive,
  deleteTask,
  milestones,
  openEditTaskModal,
  archived
}) => {
  const [readMore, setReadMore] = useState(false)
  const executor = executors && executors[0]
  const { t } = useTranslation()

  return (
    <div className={'task-body'}>
      <div className={'task-body__header'}>
        <div className={'task-body__header-left'}>
          <img src={taskIcon} alt={''} />
          <TaskStatus
            arr={TASK_STATUSES}
            current={status}
            onChange={(e) => {
              if(e === "3" && milestones.filter(item => item.status + "" === "0").length > 0) {
                toastifyError('All of milestones have to be closed!')
                return false;
              }
              setStatus && setStatus(e)
            }}
            disabled={role !== 'provider' || status + "" === "3"}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {subscription ? (
            <button className={'task-body__header-left-button'}>
              <img src={buttonIcon} alt={''} />
              <div>{t('taskBody.subscription')}</div>
            </button>
          ) : (
            <span className={'task-body__header-left-price'}>
              {total_price}
              {'$\r'}
            </span>
          )}
          {role === 'provider' && (
          <DropDown
            arr={
              [
                { label: 'Move to archive', value: 'archive', onClick: () => moveToArchive(), disabled: String(status) === "3" || archived === 1 },
                { label: 'Delete task', value: 'delete', onClick: () => deleteTask() },
              ]
            }
          />
          )}
        </div>
      </div>
      <div className={'task-body__description'}>
        <h2>{title}</h2>
        <h3>{t('taskBody.followUps')}</h3>
        <div className={'task-body__description-block'}>
          <div>
            <p dangerouslySetInnerHTML={{ __html: !readMore ? `${String(client_story).substring(0, 219)}${String(client_story).length > 219 ? '...' : ''}` : client_story }} />
            {String(client_story).length > 219
              && (
              <a onClick={() => setReadMore(!readMore)}>
                {t(readMore ? 'taskBody.hide' : 'taskBody.readMore')}
              </a>
              )}
          </div>
          {role === 'provider' && (
            <a
              onClick={() => {
                if(status + '' !== '3') {
                  if (openEditTaskModal) openEditTaskModal()
                }
              }}
              className={`task-body__description-edit ${status + '' === '3' && 'task-body__description-edit-disabled'}`}
            >
              <img src={penIcon} alt={''} />
            </a>
          )}
        </div>
      </div>
      <div className={'task-body__footer'}>
        <div className={'task-body__user'}>
          <div className={'task-body__user-photo'}>
            <img
              style={{ height: '30px', width: '30px' }}
              src={executor && executor.avatar_url}
              alt={''}
            />
          </div>
          <div className={'task-body__user-desc'}>
            <h4>{executor && executor.name}</h4>
            <em>{executor && executor.title}</em>
          </div>
        </div>
        <div className={'task-body__dates'}>
          <span className={'task-body__dates-item'}>
            <em>
              {t('taskBody.lastUpdate')}
              {':\r'}
              {' '}
            </em>
            <b>{updated_at && dateFormatter(updated_at)}</b>
          </span>
          <span className={'task-body__dates-item'}>
            <em>
              {t('taskBody.deadline')}
              {':\r'}
              {' '}
            </em>
            <b>{deadline && dateFormatter(`${deadline}`)}</b>
          </span>
        </div>
      </div>
    </div>
  )
}

export default TaskBody
