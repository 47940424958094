import {AuthModal, AuthModalProps} from "../AuthModal/AuthModal";
import ArrowIcon from '../../../../assets/images/arrow.svg'
import React, {useState} from "react";
import {resetPassword} from "../../../../service/auth/auth";
import './ResetPassword.scss'
import {AuthInput} from "../../../../components/UI/AuthInput/AuthInput";
import controlErrorValid from "../../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../../service/toastify/toastify";

const EmailIcon = <svg className="svg-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
    <g fill="none"><path d="M0 0h24v24H0V0z" /><path d="M0 0h24v24H0V0z" opacity=".87" /></g>
    <path d="M6 20h12V10H6v10zm6-7c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z" opacity=".3" />
    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z" />
</svg>

interface ResetPasswordProps extends AuthModalProps {
    token: string | null
}

export const ResetPassword: React.FC<ResetPasswordProps> = ({ isOpen, close, token }) => {
    const [isSuccess, setIsSuccess] = useState(false)
    const [errors, setErrors] = useState<{[key: string]: string}>({})
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    const resetPasswordSave = async () => {
        const passwordValid = controlErrorValid(password, { minLength: 8, required: true });
        const repeatPasswordValid = controlErrorValid(repeatPassword, { minLength: 8, required: true });
        let errorsObj = JSON.parse(JSON.stringify({}));

        if(passwordValid || repeatPasswordValid) {
            if(passwordValid && typeof passwordValid === "string") errorsObj.password = passwordValid;
            if(repeatPasswordValid && typeof repeatPasswordValid === "string") errorsObj.repeatPassword = repeatPasswordValid;
            setErrors(errorsObj)
            return false;
        }

        if((password || repeatPassword) && password !== repeatPassword) {
            errorsObj = {
                password: 'Password mismatch',
                repeatPassword: 'Password mismatch'
            }
            setErrors(errorsObj)
            return false
        }

        if(!token) return false;

        const { status, data } = await resetPassword({
            password1: password,
            password2: repeatPassword,
            token
        })
        if(status === 200 && JSON.parse(data)?.status == 'ok') {
            setErrors({})
            setIsSuccess(true)
        } else {
            toastifyError(JSON.parse(data)?.message || 'Something went wrong')
        }
    }

    return (
        <AuthModal isOpen={isOpen} close={close} col>
            <>
                <div className="auth-modal__container">
                    <div style={{ width: '1000px', display: 'flex' }} className="auth-modal__body">
                        <div className="auth-modal__left-col">
                            <div>
                                <div className="auth-modal__hr-header">
                                    <h1>{isSuccess ? 'Password have been saved' : 'Reset Password'}</h1>
                                    <hr />
                                </div>
                                {
                                    isSuccess ?
                                        <p className={'auth-modal__description'}>You can enter to cabinet<br /> with new password</p> :
                                        <p className={'auth-modal__description'}>We recommend set a strong password<br /> that you don't use anywhere else.</p>
                                }
                                <AuthInput
                                    icon={EmailIcon}
                                    otherProps={{
                                        type: 'password',
                                        value: password,
                                        placeholder: 'New Password',
                                        onChange: event => setPassword(event.target.value),
                                        disabled: isSuccess
                                    }}
                                    error={errors?.password}
                                />
                                <AuthInput
                                    icon={EmailIcon}
                                    otherProps={{
                                        type: 'password',
                                        value: repeatPassword,
                                        placeholder: 'Retype Password',
                                        onChange: event => setRepeatPassword(event.target.value),
                                        disabled: isSuccess
                                    }}
                                    error={errors?.repeatPassword}
                                />
                                <button
                                    onClick={isSuccess ? close : resetPasswordSave}
                                    className={'auth-modal__button'}>
                                    <img src={ArrowIcon} alt=""/>
                                    {isSuccess ? 'Close' :'Send'}
                                </button>
                            </div>
                        </div>
                        <div className="auth-modal__right-col">
                            <img style={{ maxWidth: '450px' }} src="https://spruko.com/demo/django/dashtic/DASHTIC/assets/images/pattern/login.png" alt=""/>
                        </div>
                    </div>
                    <div className={'auth-modal__close-button'}>
                        <a onClick={close} className={'auth-modal__close-button-link'}>Cancel</a>
                    </div>
                </div>
            </>
        </AuthModal>
    )
}