import { Search, History } from 'history'
import { KeyValueType } from '../types/custom/keyValue.type'
import { QueryFiltersType } from '../types/custom/filters.type'

type SetUrlSearchQueryNewType = {
  filter: QueryFiltersType | any,
  history: History,
  search: Search
}

export const getUrlSearchQuery = (search: Search): KeyValueType => {
  const query: URLSearchParams = new URLSearchParams(search)
  const entries: KeyValueType = Object.fromEntries(query)
  return entries
}

export const setUrlSearchQueryNew = ({ filter, history, search }: SetUrlSearchQueryNewType): QueryFiltersType => {
  let params = getUrlSearchQuery(search)

  const query = { ...params, ...filter }

  const queryKeys: string[] = Object.keys(query)

  const mappedQuery: string[] = queryKeys.reduce((result: string[], key: string) => {
    const queryValue = Array.isArray(query[key]) ? query[key].join(',') : query[key]
    if (queryValue) {
      result.push(`${key}=${queryValue}`)
    }
    return result
  }, [])

  history.push(`?${mappedQuery.join('&')}`)

  return query
}
