import React, {useCallback, useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import customAxios from "../../../service/api/interceptors";
import {useHistory, useLocation} from "react-router-dom";
import "./search_results.scss"
import searchIcon from "../../../assets/images/icons/search.svg"
import avatarIcon from "../../../assets/images/noAvatar.svg"
import fileIcon from "./file-icon.svg"
import {CustomSortDropDown, SortKanbanBoard} from "../../LegalManager/ManagerKanban/SortKanbanBoard/SortKanbanBoard";
import moment from "moment";
import Pagination from "../../../components/other/Pagination";

type SearchResultsType = {
    "id": number,
    "seen": number,
    "text": string,
    "author_id": number,
    "created_at": number,
    "updated_at": number,
    "is_question": number,
    "answer_comment_id": string,
    "pings": number,
    "offer_status": string
    "offer_id": string
    "chat_id": string
    partner_request_id: string
    partner_request_name: string
    "author": {
        "user_id": string,
        "type": string,
        "name": string,
        "avatar_url": string
    },
    "file": {
        "storage_file_id": string,
        "name": string,
        "name_to_show": string,
        "id": string
    }[],
    "company_name": string,
    "offer_name": string,
    "chat_title": string
}

function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function insertTag(text: string, key: string) {
    const escapedKey = escapeRegExp(key);
    const regex = new RegExp(`(${escapedKey})`, 'g');
    return text.replace(regex, '<i>$1</i>');
}

export const SearchResults = () => {
    const [count, setCount] = useState(0)
    const [items, setItems] = useState<SearchResultsType[]>([])
    const [searchText, setSearchText] = useState('')
    const { setLoading, userData } = useContext(MainContextProvider)
    const history = useHistory()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);

    const searchList = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/company/comment/search?text=${searchParams.get('text')}&direction=${searchParams.get('sort') || 'DESC'}&page=${searchParams.get('page') || 1}`)
            if (status === 200) {
                setCount(data.count)
                setItems(data.comments || [])
            }
        } catch (e) {}

        setLoading(false)
    }

    useEffect(() => {
        document.title = 'Search results'
        if (searchParams.get('text')) {
            setSearchText(searchParams.get('text') || '')
            searchList().then()
        }
    }, [searchParams.get('text'), searchParams.get('sort'), searchParams.get('page')])

    const updateParam = useCallback((paramName, newValue) => {
        const searchParams = new URLSearchParams(location.search);
        if (paramName !== 'page') {
            searchParams.set('page', '1')
        }
        if (searchParams.has(paramName)) {
            searchParams.set(paramName, newValue);
        } else {
            searchParams.append(paramName, newValue);
        }
        history.push(`${location.pathname}?${searchParams.toString()}`);
    }, [history, location.pathname, location.search]);

    const downloadFile = async (id: string, name: string) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`company/storage/download?file_id=${id}`, {
                responseType: 'blob',
            })
            if (status === 200) {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', name); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        } catch (e) {}
        setLoading(false)
    }

    const getCommentPage = async (id: string, offer_id: string, offer_status: string, pr?: boolean, chat_id?: string) => {
        const is_pr = userData?.status.type_code === 200 || pr
        setLoading(true)
        try {
            const { data } = await customAxios.get(`/chat/comment/${id}/page?per_page_count=10`)
            if (data?.page) {
                if (is_pr) {
                    window.open(`/partner-request/${offer_id}?initialChatPage=${data.page}&file_id=${id}&chat_id=${chat_id}`, '_blank', 'noopener,noreferrer')
                } else {
                    window.open(`/${+offer_status === 6 ? 'legal-request' : 'offer'}/${offer_id}?initialChatPage=${data.page}&file_id=${id}&chat_id=${chat_id}`, '_blank', 'noopener,noreferrer')
                }
            }
        } catch (e) {}
        setLoading(false)
    }

    const splicedText = (text: string = '') => ( text.length >= 20 ? `${text.slice(0, 20)}...` : text )

    return (
        <div className={'global-search'}>
            <div className={'global-search__result-count'}>
                <p>{searchParams.get('text')}: {count} results</p>
            </div>
            <div className={'global-search__main-block'}>
                <div className="global-search__search">
                    <input
                        type="text"
                        value={searchText}
                        onChange={e => setSearchText(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13 && searchParams.get('text') !== searchText) {
                                updateParam('text', searchText)
                            }
                        }}
                    />
                    <img onClick={() => {
                        if (searchParams.get('text') !== searchText) {
                            updateParam('text', searchText)
                        }
                    }} src={searchIcon} alt=""/>
                </div>
                <div className={'global-search__result'}>
                    {
                        (items || []).map(el => (
                            <div onClick={() => getCommentPage(el.id + '', el?.offer_id || el?.partner_request_id, el?.offer_status, !!el?.partner_request_id, el.chat_id)} className="global-search__result-item">
                                <div className="global-search__result-item-breadcrumbs">
                                    {`${splicedText(el.company_name)} > ${splicedText(el.offer_name || el.partner_request_name)} > ${el.chat_title}`}
                                </div>
                                <div className="global-search__result-item-main">
                                    <div className="global-search__result-item-avatar">
                                        <img src={el.author?.avatar_url} alt=""/>
                                    </div>
                                    <div className="global-search__result-item-main-text">
                                        <div className="global-search__result-item-main-heading">
                                            <b>{el?.author?.name}</b>
                                            <em>{moment(el.created_at * 1000).format('DD-MM-YYYY HH:mm')}</em>
                                        </div>
                                        <p dangerouslySetInnerHTML={{ __html: insertTag(el.text, searchParams.get('text') || '') }} />
                                        {
                                            el.file.length > 0 && (el.file || []).map(file => (
                                                <div className="global-search__file">
                                                    <div className={'global-search__file-img'}>
                                                        <img src={fileIcon} alt=""/>
                                                    </div>
                                                    <span>{file.name_to_show}</span>
                                                    <button onClick={(e) => {
                                                        e.stopPropagation()
                                                        downloadFile(file.id, file.name_to_show).then()
                                                    }}>DOWNLOAD</button>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    {
                        +count > 20 &&
                        <Pagination
                            currentPage={Number(searchParams.get('page')) || 1}
                            pagesAmount={Math.ceil(Number(count) / 20)}
                            setCurrentPage={(page) => updateParam('page', page + '')}
                        />
                    }
                </div>
            </div>
            <div className={'global-search__order'}>
                <CustomSortDropDown
                    onClick={(key) => {
                        updateParam('sort', key)
                    }}
                    value={searchParams.get('sort') || 'DESC'}
                    arr={[
                        { value: 'DESC', label: 'Created date (Descending)' },
                        { value: 'ASC', label: 'Created date (Ascending)' },
                    ]}
                />
            </div>
        </div>
    )
}