import React, {useContext, useEffect, useState} from "react";
import {Input} from "../../../components/UI";
import axios from "axios";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {MainContextProvider} from "../../../context/MainContext";
import {toastifySuccess} from "../../../service/toastify/toastify";

// Логика отправки повторного письма
// Когда показываем кнопку копирования у менеджера
// Может ли клиент зайти на подтвержденный оффер
// На какой email отправляется подтверждение

const buttonStyles = { width: '100%', marginTop: '20px' }
const reSendStyles = {}

export const OfflineAcceptOffer: React.FC<any> = ({ modalData }) => {
    const { setModalData, setModalType } = useContext(MainContextProvider)
    const [code, setCode] = useState('')
    const [loading, setLoading] = useState(true)
    const [text, setText] = useState('')


    const sendAcceptEmail = async () => {
        if(modalData && modalData.from_email) {
            setText('In order to accept this offer, please enter the confirmation code')
            setLoading(false)
            return
        }

        setLoading(true)
        const { status, data } = await axios.post(`${process.env.REACT_APP_BASE_API}/company/offer/send-offer-accepted-token`, {
            offer_id: modalData && modalData.offer_id,
            token_view: modalData && modalData.token_view
        })
        if(status === 200) {
            setText(data)
        }
        setLoading(false)
    }

    useEffect(() => {
        if(modalData && !modalData.accepted) {
            sendAcceptEmail().then()
        }
    }, [modalData])

    const [error, setError] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)

    const checkCode = async () => {
        setLoading(true)
        try {
            const { status, data } = await axios.post(`${process.env.REACT_APP_BASE_API}/company/offer/accept-offer-by-token`, {
                offer_id: modalData && modalData.offer_id,
                token_accepted: code
            })
            if(status === 200 && data.accepted === 1) {
                setIsSuccess(true)
                setText('Offer has been accepted')
                setModalType('')
                setModalData({
                    ...modalData,
                    accepted: 1
                })
                toastifySuccess('Offer has been accepted')
            } else {
                setError('Incorrect confirmation code. Please try again or contact our support')
            }
        } catch (e) {
            setError('Incorrect confirmation code. Please try again or contact our support')
        }
        setLoading(false)
    }

    return (
        <div style={{ position: "relative" }}>
            {loading && <ModalLoader />}
            <p className={'create_manager_offer__description'}>
                {text}
            </p>
            {!isSuccess && <div>
                <div style={{ marginTop: '10px' }}>
                    <Input
                        otherProps={{
                            value: code,
                            onChange: e => {
                                setCode(e.target.value)
                            },
                            placeholder: 'Confirmation code'
                        }}
                        errorMessage={error} valid={!error}
                    />
                </div>
                <button disabled={code.length < 1} onClick={checkCode} style={buttonStyles} className={'btn-black'}>Submit</button>
                {!modalData?.from_email && <a onClick={sendAcceptEmail} style={{ textAlign: "center", display: 'block', textDecoration: 'underline', marginTop: '12px', fontSize: '14px' }}>Re-send code</a>}
            </div>}
        </div>
    )
}