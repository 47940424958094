import React, {useContext, useEffect, useRef, useState} from 'react'
import './notification.scss'
import { AxiosResponse } from 'axios'
import arrowIcon from '../../../assets/images/icons/notification-item.svg'
import closeIcon from '../../../assets/images/modal-close.svg'
import { dateFormatter } from '../../../helpers/dateFormatter'
import { NotificationType } from '../../../helpers/types'
import {clearAllNotifications, setSingleNotification} from '../../../service/notifications'
import { MainContextProvider } from '../../../context/MainContext'
import { sendEvent } from '../../../service/tracker'
import {toastifySuccess} from "../../../service/toastify/toastify";
import {ModalLoader} from "../ModalLoader/ModalLoader";

type Props = {
  data: NotificationType[];
  history: any,
  setNopen: (e: boolean) => void;
}

const Notification:React.FC<Props> = ({ data, history, setNopen }) => {
  const { setSingleNotificationContext, userData, setNotifications } = useContext(MainContextProvider)
  const [loading, setLoading] = useState(false)
  const setItem = async (id:number) => {
    const res:AxiosResponse = await setSingleNotification(id)
    if (res.status === 200) {
      setSingleNotificationContext(id)
    }
  }


  const clearAll = async () => {
    setLoading(true)
    const { status, data } = await clearAllNotifications()
    if(status !== 200) return false;
    if(JSON.parse(data).status === 'ok') {
      setNotifications([])
      toastifySuccess(JSON.parse(data).message)
    }
    setLoading(false)
  }

  const trackerEvent = async (id: number) => {
      await sendEvent('notification_redirect', {
        datetime: (+(new Date()) / 1000).toFixed(),
        user_id: userData?.user_id,
        user_type: userData?.status.type_code,
        notification_id: id
      })
  }

  useEffect(() => {
    sendEvent('notifications_opened', {
      timestamp: (+(new Date()) / 1000).toFixed(),
      user_id: userData?.user_id,
      user_type: userData?.status.type_code
    })
  }, [])

  return (
    <div className={'notification'}>
      {loading && <ModalLoader />}
      {data.length === 0 && <p className={'notification__empty'}>No new notifications</p>}
      {
        <div className="notification__container">
          {
            data.map((item) => (
                <div className={'notification__item'}>
                  <div className={'notification__item-icon'}>
                    <img src={arrowIcon} alt={''} />
                  </div>
                  <a
                      style={{ textDecoration: 'none' }}
                      onClick={(e) => {
                        e.stopPropagation()
                        setItem(item.id)
                        history.push(item.url)
                        trackerEvent(item.id)
                      }}
                      className={'notification__item-text'}
                  >
                    <p>{item.text}</p>
                    <span className={'notification__item-date'}>{dateFormatter(item.datetime || item.updated_at)}</span>
                  </a>
                  <div
                      onClick={(e) => {
                        e.stopPropagation()
                        setItem(item.id).then()
                      }}
                      className={'notification__item-close'}>
                    <img src={closeIcon} alt={''} />
                  </div>
                </div>
            ))
          }
        </div>
            }
      {data.length > 0 && <button onClick={clearAll} className={'btn-black notification__btn'}>Close all</button>}
    </div>
  )
}

export default Notification
