import checkbox from './checkbox.module.scss'
import CheckboxIcon from './checkbox.svg'

export const CustomCheckbox = ({ checked, onChange, label, className = '', isStopPropagation }) => {
    return (
        <div onClick={onChange} className={`${className} ${checkbox.checkbox}`}>
            <div className={`${checkbox.container} ${checked ? checkbox.containerActive : 'custom_checkbox_not_active'}`}>
                {checked && <img src={CheckboxIcon} alt={checkbox.label} />}
            </div>
            {label && <span onClick={e => isStopPropagation && e.stopPropagation()} className={checkbox.label} dangerouslySetInnerHTML={{ __html: label }} />}
        </div>
    )
}