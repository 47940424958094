import './ClientKanbanHeader.scss'
import clientIcon from '../../../../assets/images/client.png'
import React, {useContext} from "react";
import {MainContextProvider} from "../../../../context/MainContext";

type ClientKanbanHeaderType = {
    managers?: {
        id: string
        fullname: string
        title: string
        avatar_path: string
    }[] | null
    manager?: any
}

export const ClientKanbanHeader: React.FC<ClientKanbanHeaderType> = ({ manager, managers }) => {
    const { setModalType, setModalData, userData } = useContext(MainContextProvider)

    return (
        <div className={'client-kanban-header'}>
            <div className="client-kanban-header__managers">
                {
                    managers && managers.map(item => (
                        <div className="client-kanban-header__manager">
                            <img onClick={() => {
                                setModalType('manager_profile_modal')
                                setModalData(item?.id)
                            }} style={{ height: '36px', cursor: 'pointer' }} className={'client-kanban-header__manager-photo'} src={item.avatar_path || clientIcon} alt=""/>
                            <div className={'client-kanban-header__manager-data'}>
                                <div className="client-kanban-header__manager-name">
                                    {item?.fullname}
                                </div>
                                <div className="client-kanban-header__manager-position">
                                    {!userData?.isTrial && (item.title || '-')}
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    manager && !Array.isArray(manager) && <div className="client-kanban-header__manager">
                        <img onClick={() => {
                            setModalType('manager_profile_modal')
                            setModalData(manager?.id)
                        }} style={{ height: '36px', cursor: 'pointer' }} className={'client-kanban-header__manager-photo'} src={manager.avatar_path || clientIcon} alt=""/>
                        <div className={'client-kanban-header__manager-data'}>
                            <div className="client-kanban-header__manager-name">
                                {manager?.fullname}
                            </div>
                            <div className="client-kanban-header__manager-position">
                                {!userData?.isTrial && (manager.title || '-')}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}