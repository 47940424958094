import React from "react";
import icon from "../../../../assets/images/partnerRequest/notInterested.svg"

export const PartnerRequestExpired: React.FC = () => {
    return (
        <div className={'pr-not-interested'}>
            <div className="pr-not-interested__heading">
                <img src={icon} alt=""/>
                <span>THIS REQUEST HAS EXPIRED</span>
            </div>
            <div className="pr-not-interested__body">
                <p>
                    The provider have not published their estimates before the request expiration OR this
                    request has NOT been included in the final offer for the client. This request will be
                    automatically removed from the system, no further actions are required.
                </p>
            </div>
        </div>
    )
}