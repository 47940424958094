import React, {useEffect, useState} from "react";
import "./payment-plan-items.scss"
import comingSoonIcon from "../../../../../assets/images/planAndCredits/coming-soon.png"
import infoIcon from "../../../../../assets/images/planAndCredits/info.svg"
import showMoreIcon from "../../../../../assets/images/planAndCredits/showMore.svg"
import ModalContainer from "../../../../../components/ModalContainer";
import logoIcon from "../../../../../assets/images/logo.svg";
import {useIntercom} from "react-use-intercom";
import customAxios from "../../../../../service/api/interceptors";

export const PaymentPlanItems: React.FC<{ subscriptionTitle: string, isUpcomingTab: boolean }> = ({ subscriptionTitle, isUpcomingTab }) => {
    const [plans, setPlans] = useState<any[]>([])
    const [period, setPeriod] = useState('monthly')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [showMore, setShowMore] = useState(false)
    const { showNewMessages } = useIntercom()
    const periodMonth = period === 'monthly' ? 1 : 3

    const getPlans = async () => {
        const { status, data } = await customAxios.get(`/v1/user/client/invoice/payment-plans`)
        setPlans(data)
    }

    useEffect(() => {
        getPlans().then()
    }, [])

    useEffect(() => {
        if (subscriptionTitle && subscriptionTitle.toLocaleLowerCase().includes('quarterly')) setPeriod('quarterly')
    },[subscriptionTitle])

    return (
        <div className={'payment-plan-items'}>
            <div onClick={() => setPeriod(period === 'quarterly' ? 'monthly' : 'quarterly')} className="payment-plan-items__switch">
                <span style={{ color: period === 'quarterly' ? '#7B8DB7' : '#000' }}>Monthly</span>
                <div style={{ justifyContent: period === 'quarterly' ? 'flex-end' : 'initial' }} className={'payment-plan-items__button'}>
                    <div className="payment-plan-items__circle" />
                </div>
                <span style={{ color: period === 'quarterly' ? '#000' : '#7B8DB7' }}>Quarterly</span>
            </div>

            <div className={'payment-plan-items__plans'}>
                {
                    plans.filter(item => item.payment_period === periodMonth).map(el => (
                        <div className="payment-plan-items__plan">
                            <div className="payment-plan-items__plan-heading">
                                <span>{el.title}</span>
                                <img className={'pointer'} onClick={() => setIsModalOpen(true)} src={infoIcon} alt=""/>
                            </div>
                            {subscriptionTitle === el.title && <div className={'payment-plan-items__plan-desc'}>
                                •{isUpcomingTab ? `upcoming` : `current`} plan•
                            </div>}
                            <div className="payment-plan-items__plan-body">
                                <div className="payment-plan-items__plan-list">
                                    <p dangerouslySetInnerHTML={{ __html: el.description }} />
                                </div>
                                {/*<ul>
                                    <li><b>1</b> business project</li>
                                    <li><b>Unlimited</b> legal roadmap projects</li>
                                    <li>Up to <b>3 users</b> (+$25 per additional user)</li>
                                    <li><b>1</b> Legal Discovery Session (LDS) with an industry expert per month</li>
                                    <li><b>Communicate</b> with VLOs on the platform (24h response time)</li>
                                    <li>Free Legal Board Planner</li>
                                    <li>4 Credits included each month</li>
                                    {
                                        showMore && (
                                            <>
                                                <li>Receive suggestions from VLOs on new legal requests</li>
                                                <li>Additional credits available for purchase</li>
                                                <li>Access to the Legal Nodes Network of verified partners and partner add-ons</li>
                                                <li>Access to Legal Nodes add-ons</li>
                                                <li>Slack integration (+$100)</li>
                                            </>
                                        )
                                    }
                                </ul>*/}

                                {/*<div onClick={() => setShowMore(!showMore)} className={'payment-plan-items__plan-more'}>
                                    {showMore ? 'Hide' : 'Show'} more {!showMore && <img src={showMoreIcon} alt=""/>}
                                </div>*/}
                                <div className="payment-plan-items__plan-hr" />
                                <div className="payment-plan-items__plan-price">
                                    <b>${el.price}/</b>
                                    <em className={'text-uppercase'}>{period === 'quarterly' ? 'quarter' : 'MONTH'}</em>
                                </div>
                                <button
                                    disabled={subscriptionTitle === el.title}
                                    onClick={() => showNewMessages(`Change subscription plan to ${(subscriptionTitle || '').toLocaleLowerCase().includes(period) ? 'Quarterly' : 'Monthly'}`)}
                                    className={'payment-plan-items__plan-button flex-center'}>BUY PLAN</button>
                            </div>
                        </div>
                    ))
                }


            </div>

            <InfoModal
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
            />
        </div>
    )
}


const InfoModal: React.FC<{ isOpen: boolean, close: () => void }> = ({ isOpen, close }) => {
    return (
        <div className={'payment-plan-information-modal'}>
            <ModalContainer isOpen={isOpen} close={close}>
                <div>
                    <div className={'payment-plan-information-modal__logo'}>
                        <img src={logoIcon} alt=""/>
                    </div>
                    <div className={'payment-plan-information-modal__text'}>
                        <h3>Business project</h3>
                        <p>
                            A business project has a business goal. Business projects are not legal projects.
                            This plan provides VLO support for 1 business project.
                        </p>
                        <h3>Legal project</h3>
                        <p>
                            Within your business project, you can have multiple legal projects.
                            These are individual legal, tax or other tasks that support your business goals.
                        </p>
                        <h3>Users</h3>
                        <p>
                            This plan is limited to 3 users. To add more users, please contact our support team.
                        </p>
                        <h3>Legal Q&A session</h3>
                        <p>
                            A session with an industry expert regarding your business goal and roadmap.
                            It lasts 30 mins. To book it please speak to your VLO.
                        </p>
                        <h3>VLO communication</h3>
                        <p>
                            Your VLO is available for you to message via the platform. VLOs typically respond within 24 hours. Response times can vary due to time zone differences.
                        </p>
                        <h3>Free Legal Board Planner</h3>
                        <p>
                            You can add an unlimited number of planned requests to your Planner, to help you map out your future tasks and goals.
                            Each request saved to your Planner can be submitted to your VLO at the cost of 1 Credit for them to get started on it.
                        </p>
                        <h3>Credits included each month</h3>
                        <p>
                            Your subscription includes some free monthly Credits, which must be used within the month that they are issued.
                            Make sure you use them, as your monthly Credits expire at the end of each month and cannot be transferred to the next month.
                        </p>
                        <button onClick={close} className={'btn-black w100 mt20'}>OK</button>
                    </div>
                </div>
            </ModalContainer>
        </div>
    )
}