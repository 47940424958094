import logoIcon from "../../../../../assets/images/logo.svg";
import ModalContainer from "../../../../../components/ModalContainer";
import React from "react";
import moment from "moment";

export const UnsubscribeModalSuccess: React.FC<{
    isOpen: boolean, close: () => void, subscription: any
}> = ({ isOpen, close, subscription }) => {
    return (
        <div className={'payment-plan__modal'}>
            <ModalContainer isOpen={isOpen} close={close}>
                <div>
                    <div className={'payment-plan-information-modal__logo'}>
                        <img src={logoIcon} alt=""/>
                    </div>
                    <div className={'payment-plan__modal__text mt40'}>
                        <h4>You have successfully<br /> unsubscribed.</h4>
                        <p style={{ textAlign: 'center', marginTop: '20px' }}>Your subscription will remain active until the expiry date:</p>
                    </div>
                    <div className={'payment-plan__modal-info'}>
                        <span>{subscription.title} <em>({subscription.date})</em></span>
                    </div>
                    <div className={'payment-plan-information-modal__text mt10'}>
                        <button onClick={close} className={'btn-black w100 mt20'}>OK</button>
                    </div>
                </div>
            </ModalContainer>
        </div>
    )
}