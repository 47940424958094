import React, {useContext, useEffect, useMemo, useRef, useState} from 'react'
import './header.scss'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import LogoIcon from '../../../assets/images/logo.svg'
import notificationsIcon from '../../../assets/images/header-notif.svg'
import { MainContextProvider } from '../../../context/MainContext'
import Notification from '../../../components/other/Notification/Notification'
import { UserType } from '../../../enums/userType'
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import {useHistory} from "react-router-dom";
import WaitingImage from '../../../assets/images/waiting-image.png'
import calendarIcon from '../../../assets/images/calendarWhite.svg'
import searchIcon from '../../../assets/images/icons/search.svg'
import {DeliverablesHeaderNotifications} from "./DeliverablesHeaderNotifications/DeliverablesHeaderNotifications";
import customAxios from "../../../service/api/interceptors";
import { openPopupWidget } from "react-calendly";
import {MobileHeading} from "../../../components/other/Mobile/MobileHeading/MobileHeading";
import {HeaderTimer} from "../../../components/other/HeaderTimer/HeaderTimer";
import {AllUserTimers} from "../../../components/other/HeaderTimer/AllUserTimers";
import {HeaderSearch} from "./HeaderComponents/HeaderSearch";
import {HeaderCreditBalance} from "./Other/HeaderCreditBalance";


const HeaderUserDropDown = ({ history, avatar_url }: { history: any, avatar_url: string }) => {
  const { setIsLoggedIn, headerTimer, setCurrentTimer  } = useContext(MainContextProvider)
  const [nOpen, setModalOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)
  useOnClickOutside({ ref: dropdownRef, handler: () => setModalOpen(false) })

  const stopTimer = async () => {
    try {
      const { data: tags } = await customAxios.get(`/timely/tags`)
      const { status, data } = await customAxios.put(`/timely/event/${headerTimer?.timer_id}/stop-timer`, {
        tag_id: tags[0].id, note: headerTimer?.type

      })
      if (status === 200) {
        // @ts-ignore
        setHeaderTimer(null)
        setCurrentTimer('')
      }
    } catch (e) {}
  }

  const logout = async () => {
    if (headerTimer?.created_at) {
      await stopTimer()
    }

    localStorage.clear()
    setIsLoggedIn(false)
  }

  return (
      <div className={'header__right-user'} ref={dropdownRef}>
        <img onClick={() => setModalOpen(!nOpen)} src={avatar_url} alt=""/>
        <CSSTransition in={nOpen} timeout={200} classNames={'my-node'} unmountOnExit>
          <div className="kanban-dropdown-mobile__dropdown" style={{ right: 0, top: 60, width: '175px', zIndex: 2 }}>
            <div onClick={() => history.push('/settings/account')} className="kanban-dropdown-mobile__dropdown-item">
              <span>Settings</span>
            </div>
            <div onClick={logout} className="kanban-dropdown-mobile__dropdown-item">
              <span>Log out</span>
            </div>
          </div>
        </CSSTransition>
      </div>
  )
}

const NotificationsData: React.FC<any> = ({ history }) => {
  const { notifications } = useContext(MainContextProvider)
  const [nOpen, setModalOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  useOnClickOutside({ ref: dropdownRef, handler: () => setModalOpen(false) })

  return <div  ref={dropdownRef}>
    <a id={'header-notifications-button'} onClick={() => setModalOpen(!nOpen)} className={'header__right-link'}>
      <img src={notificationsIcon} alt={''} />
      {notifications.length > 0
      && <span className={'header__right-counter'}>{notifications.length > 9 ? '9+' : notifications.length}</span>
      }
      <CSSTransition in={nOpen} timeout={200} classNames={'my-node'} unmountOnExit>
        <div>
          <div className={'header__right-link-squire'} />
          <Notification
              data={notifications}
              history={history}
              setNopen={setModalOpen}
          />
        </div>
      </CSSTransition>
    </a>
  </div>
}

const Header:React.FC<any> = ({ icons }) => {
  const history = useHistory()
  const { setModalType, role, userData, setModalData, isShowMainHeader, userOnBoarding, setLoading, isUnSubscribe } = useContext(MainContextProvider)
  const { t } = useTranslation()

  const button = useMemo(() => {
    if (userData?.status.type_code === 100) {
      return (
          <button
              title={userData.isTrial ? 'Please upgrade to Full Version' : ''}
              disabled={userData.isTrial} onClick={() => setModalType('client_kanban_request')} className={'btn btn-black text-uppercase'}>
            {t('lawyer.taskRequest')}
          </button>
      )
    }

    if (userData?.status.type_code === 300 || userData?.status.type_code === 400) {
      return (
          <button disabled={userData?.status.type_code === 400} onClick={() => {
            // setModalData({ step: 2, title: '', description: '' })
            setModalType('create_manager_offer')
          }} className={'btn btn-black text-uppercase'}>
            NEW OFFER
          </button>
      )
    }

    /*return (
        <button onClick={() => setModalType('create_task')} className={'btn-black'}>
          {t('header.createTask')}
        </button>
    )*/
  }, [role])
  
  const logoLink = () => {
    if (userData?.status.code === 200) {
      return '/dashboard'
    }

    if (userData?.status.code === 300 || userData?.status.code === 400) {
      return '/dashboard'
    }

    if(userData?.status.code === 200) {
      if (userOnBoarding) {
        return '/dashboard'
      } else {
        return 'onboarding'
      }
    }

    return '/'
  }

  const getUrl = async () => {
    setLoading(true)
    try {
      const { status, data } = await customAxios.get('/onboarding/get-kanban-calendly-widget') // /onboarding/calendly-request-widget
      if (status === 200) {
        openPopupWidget({ url: `${data}${data.includes('?') ? '&' : '?'}email=${userData?.email}&name=${userData?.name}`})
      }
    } catch (e) {}
    setLoading(false)
  }

  return (
      <>
        <header className={`${!isShowMainHeader && 'mobile-header'} header`} style={{ padding: '0 5px' }}>
          <div className={'header__left'}>
            <img onClick={() => history.push(logoLink())} className={'header__logo'} src={LogoIcon} alt={''} />
            <ul style={{ display: 'none' }} className={'header__nav'}>
              {/*<li><a onClick={() => history.push('/settings/history')} style={{ display: 'flex', alignItems: 'center' }}>
              Invoices
            </a></li>*/}
            </ul>
          </div>
          <div className={'header__right'}>
            {userData?.status.type_code === 300 ? <HeaderTimer/> : <div />}
            <div className={'flex'}>
              <div className={'header__right-icons'}>
                <HeaderSearch />
                <HeaderCreditBalance />
                {userData?.status.type_code === 300 && <AllUserTimers />}
                {icons && icons()}
                {userData?.status.type_code === 100 && <DeliverablesHeaderNotifications />}
                <NotificationsData history={history} />
                {userData?.isTrial && userData?.status.type_code === 100 && userData?.flows.onboarding === 1 && <div onClick={getUrl} className="callVlo">
                  <div className="callVlo__circle">
                    <img src={calendarIcon} alt=""/>
                  </div>
                  <div className="callVlo__title">
                    Book a 2nd legal strategy session
                  </div>
                </div>}
                {/*<a className="header__right-link"><img src={settingsIcon} alt=""/></a>*/}
                <HeaderUserDropDown history={history} avatar_url={userData?.avatar_url || ''} />
              </div>
              {(userData?.status.type_code === 300 || userData?.status.type_code === 400) &&
                  <button disabled={userData?.status.type_code === 400} onClick={() => setModalType('attach_client_modal')} className={'btn btn-black text-uppercase header__white-button'}>New client</button>
              }
              {userData?.status.type_code === 300 &&
                  <button onClick={() => setModalType('red_flags')} className={'btn btn-black text-uppercase header__white-button'}>Account Health Report</button>
              }
              {userData?.status.type_code === 100 && +userData?.flows.onboarding === 1 && !isUnSubscribe &&
                  <button onClick={() => setModalType('invite_team_mate')} className={'btn btn-black text-uppercase header__white-button'}>Invite teammate</button>
              }
              {!history.location.pathname.includes('onboarding') && !isUnSubscribe && button }
            </div>

          </div>
        </header>
        <MobileHeading />
      </>
  )
}

export default Header
