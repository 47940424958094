import React, {useContext, useEffect} from 'react'
import Img from '../../../assets/images/error-boundry.png'
import {useHistory} from "react-router-dom";
import {MainContextProvider} from "../../../context/MainContext";

const text = {
    '404': {
        heading: 'Page not found',
        desc: 'Looks like This page doesn`t exists'
    },
    'error': {
        heading: 'Oh no, something went wrong!',
        desc: 'Something went wrong there. Please try again soon or write to technical support.'
    }
}

const buttonStyles = { fontSize: '16px', padding: '8px 25px', textDecoration: 'none', margin: '0 auto', display: 'table', lineHeight: '26px' }

export const ErrorBoundaryComponent = ({ type }) => {
    const history = useHistory()
    const { setLoading, userData } = useContext(MainContextProvider)

    useEffect(() => {
        setLoading(false)
        document.title = "Page not found"
    },[])

    const buttonSwitch = () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const query = Object.fromEntries(urlSearchParams.entries());
        switch (query.entity) {
            case 'project': return (
                <button onClick={() => history.push('/projects')} style={buttonStyles} className={'btn-black'}>{'View of all projects'}</button>
            )
            case 'task': return (
                <button onClick={() => history.push(userData.status.type === 'client' ? '/dashboard' : '/tasks')} style={buttonStyles} className={'btn-black'}>{'View of all tasks'}</button>
            )
            default: return (
                <a onClick={() => window.location.href = '/dashboard' } style={buttonStyles} className={'btn-black'}>{'To dashboard'}</a>
            )
        }
    }

    return (
        <div>
            <img className={'error-boundary__img'} style={{ maxWidth: '600px', display: 'block', margin: '0 auto' }} src={Img} alt=""/>
            <div>
                <h2 style={{ fontSize: '30px', fontWeight: 600, color: '#3e3e3e', textAlign: 'center', marginTop: '10px' }}>{text[type].heading}</h2>
                <p style={{ textAlign: "center", fontSize: '16px', fontWeight: 400, color: '#9c9c9c', margin: '10px auto', maxWidth: '400px', lineHeight: '20px' }}>
                    {text[type].desc}
                </p>
                {buttonSwitch()}
            </div>
        </div>
    )
}
