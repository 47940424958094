import React, { useContext, useEffect, useState } from 'react'
import { Input } from '../../../components/UI'
import { MainContextProvider } from '../../../context/MainContext'
import './milestones-modal.scss'
import { createSubTask, deleteSubTask, updateSubTask } from '../../../service/tasks'
import { SubTaskType } from '../../../types/task.type'
import { toastifySuccess } from '../../../service/toastify/toastify'
import { useTranslation } from 'react-i18next'
import controlErrorValid from "../../../components/UI/ControlValid/controlErrorValid";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";

const MilestonesModal = () => {
  const [loading, setLoading] = useState(false)
  const { setModalData, modalData } = useContext(MainContextProvider)
  const [value, setValue] = useState('')
  const [pages, setPages] = useState('')
  const [pageValid, setPageValid] = useState('')
  const [valid, setValid] = useState('')
  const { t } = useTranslation()

  const createElement = async () => {
    const validRes = controlErrorValid(value, { required: true, maxLength: 255 })
    if (validRes) {
      setValid(validRes + '')
      return false;
    }
    setValid('')

    if(modalData?.prefix === 'milestones') {
        const validPageRes = controlErrorValid(pages, { required: true, maxLength: 5, number: true, minNumber: 0, decimal: true })
        if(validPageRes) {
            setPageValid(validPageRes.toString())
            return false
        } else {
            setPageValid('')
        }
    }

    const data:SubTaskType = {
      title: value,
      status: '0',
      sort: modalData.item.sort || 1,
      task_id: modalData.item.task_id,
      executor_type: modalData.item.executor_type,
      // executor_id: modalData.item.executor_id,
      sp_price: +pages,
    }

    setLoading(true)
    try {
      const res = modalData.type === 'updateModal'
        ? await updateSubTask(modalData.item.id, data, modalData.link)
        : await createSubTask(data, modalData.link)
      if (res.status === 200 || res.status === 201) {
          const returnData = res.data;
          if(modalData.type === "updateModal") {
              returnData.id = modalData.item.id;
          }
        setModalData(
          {
            type: modalData.type === 'updateModal' ? 'update' : 'push',
            item: returnData,
          },
        )
      }
    } catch (e) {
      console.log('error')
    }
    setLoading(false)
  }

  useEffect(() => {
    if (modalData && modalData.item) {
      modalData.item.title && setValue(modalData.item.title)
      modalData.item.sp_price && setPages(modalData.item.sp_price)
    }
  }, [modalData])

  const deleteMilestone = async () => {
    setLoading(true)
    const res = await deleteSubTask(modalData.item.id, modalData.link)
    if (res.status === 204) {
      toastifySuccess(t('milestonesModal.deleted'))
      setModalData({ type: 'delete', item: modalData.item })
    }
    setLoading(false)
  }

  return (
    <div className={'create_milestones__body'} style={{ position: "relative" }}>
        {loading && <ModalLoader />}
      <br />
      <Input
        label={'Name'}
        otherProps={{
          placeholder: t('milestonesModal.enterMessage'),
          value,
          onChange: (event) => setValue(event.target.value),
          onKeyDown: (event) => {
            if (modalData.type === 'updateModal' && event.keyCode === 13 && value.length === 0) {
              if (window.confirm(t('milestonesModal.confirmDelete'))) deleteMilestone().then()
            }
          },
        }}
        valid={!valid}
        errorMessage={valid}
      />
      <br />
      {modalData?.prefix === 'milestones'
        && (
        <Input
          label={'Pages'}
          otherProps={{
            value: pages,
            type: 'number',
            onChange: (event) => {
                setPages(event.target.value)
            }
          }}
          valid={!pageValid}
          errorMessage={pageValid}
        />
        )}
      <button onClick={createElement} className={'create_milestones__save btn-black'}>
        {t('milestonesModal.button')}
      </button>
    </div>
  )
}

export default MilestonesModal
