import React, {useContext, useEffect, useRef, useState} from "react";
import {PartnerRequestType} from "../PartnerRequestType";
import cursorIcon from "../../../../assets/images/kanbanIcons/request.svg"
import squireWhiteIcon from "../../../../assets/images/square-icon-white.svg"
import {useOnClickOutside} from "../../../../hooks/useOnClickOutside";
import squareBlack from "../../../../assets/images/square-icon-black.svg";
import squareWhite from "../../../../assets/images/square-icon-white.svg";
import {partner_request_statuses} from "../../partner_request_statuses";
import customAxios from "../../../../service/api/interceptors";
import {controlErrorFunc} from "../../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError, toastifySuccess} from "../../../../service/toastify/toastify";
import {ModalLoader} from "../../../../components/other/ModalLoader/ModalLoader";
import ModalContainer from "../../../../components/ModalContainer";
import Input from "../../../../components/UI/Input/Input";
import {ReactEditor} from "../../../../components/UI";
import {MainContextProvider} from "../../../../context/MainContext";

type Props = {
    data: PartnerRequestType
    request_id: number
    update: () => void
}

export const PartnerRequestHeader: React.FC<Props> = ({ data, request_id, update }) => {
    const { userData } = useContext(MainContextProvider)
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })
    const status_data = partner_request_statuses(data?.status, data?.offer?.accepted, data?.is_done)
    const [isModalOpen, setIsModalOpen] = useState(false)

    return (
        <>
            <div className={'partner-request-view__header'} style={{ background: status_data.background }}>
                <div className={'partner-request-view__header-status'}>
                    <img src={status_data.icon} alt=""/>
                    <span style={{ color: status_data.color }}>{status_data.title}</span>
                </div>
                {userData?.status.type_code === 300 && <div className="offerHeader__dropdown" ref={dropdownRef}>
                    <img style={{width: '30px', height: '30px'}} onClick={() => setIsOpen(!isOpen)}
                         className={'offerHeader__right-dots'} src={squireWhiteIcon} alt=""/>
                    {
                        isOpen && (
                            <div className={'offerHeader__dropdown-container task-status__body'}>
                                <div onClick={() => setIsModalOpen(true)} className="task-status__body-item">EDIT</div>
                            </div>
                        )
                    }
                </div>}

            </div>
            <UpdateRequest
                modalData={isModalOpen}
                requestData={data}
                requestId={request_id}
                close={() => setIsModalOpen(false)}
                update={(data: any) => {
                    update()
                    //setPartnerRequestData({ ...data, title: data.title, description: data.description })
                    setIsModalOpen(false)
                }}
            />
        </>
    )
}


const initialData = {
    title: '',
    description: '',
}

const UpdateRequest: React.FC<any> = ({ close, update, modalData, requestId, requestData }) => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState(initialData)
    const [errors, setErrors] = useState<{[key: string]: string}>({})

    const createRequest = async () => {
        const validReq = controlErrorFunc({ obj: values, config: {
                title: { required: true, maxLength: 255 }, description: { required: true }
            }})
        if(Object.keys(validReq).length >= 1) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        setLoading(true)
        const { title, description } = values
        try {
            const { status, data } = await customAxios.put(`/partner-request/${requestId}/update`, {
                title, description
            })
            if (status === 200) {
                update({ title, description })
                toastifySuccess('Partner request has been updated')
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        if (modalData) {
            setValues({
                ...JSON.parse(JSON.stringify(initialData)),
                title: requestData?.title,
                description: requestData?.description
            })
        }
    }, [modalData])

    return (
        <div className={'vlo-partner-request-modal create_manager_offer'} style={{ position: "relative" }}>
            {loading && <ModalLoader />}
            <ModalContainer heading={'Update Partner Request'} isOpen={modalData} close={close}>
                <div className={'mt5'}>
                    <Input
                        label={'Title'}
                        className={'mt15'}
                        otherProps={{
                            value: values.title,
                            onChange: e => setValues({ ...values, title: e.target.value })
                        }}
                        valid={!errors?.title}
                        errorMessage={errors.title}
                    />
                    <ReactEditor
                        className={'mt10'}
                        value={values.description}
                        onChange={text => setValues({ ...values, description: text })}
                        valid={!errors?.description}
                        errorMessage={errors.description}
                    />
                    <button onClick={createRequest} className={'btn-black w100 text-uppercase mt25'}>
                        Update
                    </button>
                </div>
            </ModalContainer>
        </div>
    )
}