import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios'
import { refreshToken } from '../auth/auth'
import { clearToken, getAccessToken, getExpiresInToken } from '../token/token'
import { apiPath } from './constants'
import { toastifyError } from '../toastify/toastify'

const baseUrl = process.env.REACT_APP_BASE_API

const customAxios: AxiosInstance = axios.create({
  baseURL: `${baseUrl}/`,
})

const skipUnauthorizedErrorsUrl = {
  [apiPath.login]: apiPath.login,
}

const onRequest = async (request: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const expiresIn = getExpiresInToken()
  const isNeedRefreshToken = expiresIn && Number(expiresIn) < Date.now()
  if (isNeedRefreshToken) {
    try {
      await refreshToken()
    } catch (e) {
      console.log(e)
    }
  }

  const accessToken = getAccessToken()

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error)

const onResponse = (response: AxiosResponse): AxiosResponse => response

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  const urlRequest = (error && error.response && error.response.config.url) as string
  const skipLogout = skipUnauthorizedErrorsUrl[urlRequest]

  const responseStatus = error && error.response && error.response.status

  if (responseStatus === 401 && !skipLogout) {
    clearToken()
    window.location.href = '/'
  }

  if (responseStatus === 403 && window.location.host !== 'localhost:3000') {
    window.location.href = '/404'
  }

  if (responseStatus === 404 || responseStatus === 500) {
    toastifyError('Something went wrong')
  }

  if (responseStatus === 409 || responseStatus === 400) {
    toastifyError(error?.response?.data?.message)
  }

  return Promise.reject(error)
}

customAxios.interceptors.request.use(
  (request) => onRequest(request),
  (error) => onRequestError(error),
)

customAxios.interceptors.response.use(
  (response) => onResponse(response),
  (error) => onResponseError(error),
)

export default customAxios
