import React, {useContext, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {MainContextProvider} from "../../../context/MainContext";
import {useIntercom} from "react-use-intercom";
import customAxios from "../../../service/api/interceptors";
import {intercomCatchErrorMessage} from "../../../helpers/intercomCatchErrorMessage";
import {getAccessToken} from "../../../service/token/token";
import axios from "axios";
import {ReactSelect} from "../../../components/UI";
import './monthly_report_page.scss'
import calendarIcon from '../../../assets/images/calendarBlue.svg'
import calendarBlackIcon from '../../../assets/images/calendar.png'
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import {CSSTransition} from "react-transition-group";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import {sendEvent} from "../../../service/tracker";
import {dateFormatter} from "../../../helpers/dateFormatter";
import moment from "moment";

const today = new Date();
const sevenDaysAgo = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));

function getLastWeek() {
    const now = new Date();
    const currentDay = now.getDay();
    const daysToMonday = currentDay === 0 ? 6 : currentDay - 1;

    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - daysToMonday - 7);
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);
    return [startOfWeek, endOfWeek]
}

function getThisMonth() {
    const today = new Date(1683697546000);
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));

    if (lastSunday.getMonth() !== currentMonth && firstDayOfMonth.getDay() !== 0) {
        return null;
    }

    if (Number(today.getDate()) < 8) {
        firstDayOfMonth.setMonth(firstDayOfMonth.getMonth() - 1)
        return [ firstDayOfMonth, firstDayOfMonth ]
    }

    if (firstDayOfMonth.getDay() === 0) {
        return [ firstDayOfMonth, firstDayOfMonth ]
    }

    return [ firstDayOfMonth, lastSunday ]
}

function getLastMonth() {
    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    return [firstDayOfLastMonth, lastDayOfLastMonth];
}

function toLocalTimestamp(date: Date) {
    const utcTimestamp = date.getTime();
    const timezoneOffset = date.getTimezoneOffset();
    const offsetMillis = timezoneOffset * 60 * 1000;
    const localTimestamp = utcTimestamp - offsetMillis;
    return localTimestamp;
}

function getLastMonthTimestampWithDay(timestamp: number) {
    const activeDate = new Date(timestamp)
    const thisDate = new Date()
    const monthCount = getLastWeek()[1].getMonth() === thisDate.getMonth() ? 0 : 1
    thisDate.setMonth(new Date().getMonth() - monthCount)
    thisDate.setDate((activeDate.getDate()))
    return thisDate
}

function getDaysInLastMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() - 1;

    return new Date(year, month + 1, 0).getDate();
}

export const Report: React.FC = () => {
    const month = (new Date()).getMonth() + 1
    //const month = 4
    const year = (new Date()).getFullYear()
    const location = useLocation()
    const search = location.search;
    const params = new URLSearchParams(search);


    const { setLoading, userData, creditCount } = useContext(MainContextProvider)
    const [file, setFile] = useState(null)
    const [startDate, setStartDate] = useState(1)
    const [endDate, setEndDate] = useState(month)
    const [associations, setAssociations] = useState<{value: string, label: string}[]>([])
    const [association, setAssociation] = useState(null)
    const [sendToEmail, setSendToEmail] = useState(false)
    const { showNewMessages } = useIntercom()
    const [requestDate, setRequestDate] = useState([getLastWeek()[0], getLastWeek()[1]])
    const [value, onChange] = useState<any>([new Date(), new Date()]);
    const [isOpen, setIsOpen] = useState(false)
    const [minDate, setMinDate] = useState(new Date())
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })
    const [activateDateTimestamp, setActivateDateTimestamp] = useState(0)

    const getAssociations = async () => {
        try {
            const { status, data } = await customAxios.get(`company/company/company-associations`)
            if (status === 200) {
                setAssociations((data || []).map((el: any) => ({ value: el.id, label: el.name })))
                const company_association_id = params.get('company_association_id')
                const isExists = !!data.find((el: any) => el.id == company_association_id)
                if (company_association_id && isExists) {
                    // @ts-ignore
                    setAssociation(company_association_id)
                } else {
                    setAssociation(data[0].id)
                }
            }
        } catch (e) {
            //await showNewMessages(intercomCatchErrorMessage(e))
        }
    }

    const getSubscriptions = async () => {
        try {
            const { data, status } = await customAxios.get(`user/client/info/subscriptions`)
            if (status === 200) {
                const item = (data || []).find((el: { title: string, activate_date_format: string }) => !el.title.includes('Trial'))
                const dataTimestamp = toLocalTimestamp(new Date(item.activate_date_format))
                setActivateDateTimestamp(dataTimestamp)
                const start_date = params.get('start_date')
                const finish_date = params.get('finish_date')
                if (start_date && finish_date) {
                    if (((+start_date + 87000) * 1000) > (+dataTimestamp)) {
                        const finishDate = ((+finish_date * 1000 - 100) <= toLocalTimestamp(new Date())) ? new Date(+finish_date * 1000) : getLastWeek()[1]
                        setRequestDate([new Date(+start_date * 1000), finishDate])
                    }
                }
            }
        } catch (e) {}
    }

    useEffect(() => {
        getAssociations().then()
        getSubscriptions().then()
        document.title = 'VLO`s Work Report'
    }, [])

    const downloadFile = (fileData: any, filename: string) => {
        const url: string = window.URL.createObjectURL(new Blob([fileData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }

    const getFile = async () => {
        setLoading(true)
        const accessToken = getAccessToken()

        try {
            const { status, data } =
                await axios.get(`${process.env.REACT_APP_BASE_API}/report/report/monthly-client-report?start_date=${Math.floor(toLocalTimestamp(requestDate[0]) / 1000)}&finish_date=${Math.floor(toLocalTimestamp(requestDate[1]) / 1000)}&company_association_id=${association}`, {
                    responseType: 'blob',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
            if(status === 200) {
                setFile(data)
                setTimeout(() => downloadFile(data, `Report ${moment(requestDate[0]).format('DD/MM/YYYY')}-${moment(requestDate[1]).format('DD/MM/YYYY')}.pdf`), 1000)
                sendUserEvent().then()
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setLoading(false)
    }
    
    const formatDate = (dataDate: Date) => {
        const day = dataDate.getDate().toString().padStart(2, '0');
        const month = (dataDate.getMonth() + 1).toString().padStart(2, '0');
        return `${day}.${month}`;
    }

    let end_date = new Date();
    if (end_date.getDay() !== 0) {
        end_date.setDate(end_date.getDate() - end_date.getDay());
    }

    const sendUserEvent = async () => {
        console.log(requestDate)

        await sendEvent('report_viewed', {
            timestamp: (+(new Date()) / 1000).toFixed(),
            user_id: userData?.user_id,
            company_id: association,
            timespan_from: Math.floor(toLocalTimestamp(requestDate[0]) / 1000),
            timespan_to: Math.floor(toLocalTimestamp(requestDate[1]) / 1000),
            source: 'cabinet'
        })
    }

    function calcBillingPeriodUpdated() {
        const billing_date = new Date(activateDateTimestamp);
        let start_date_tim: Date = end_date < billing_date ? end_date : billing_date;
        let end_date_tim: Date = new Date(end_date);
        let billing_end_date = new Date(end_date);
        billing_end_date.setDate(billing_date.getDate())

        const end_date_day = end_date.getDate()
        const start_date_day = start_date_tim.getDate()

        let end_billing_date_difference = Math.floor(
            ((+end_date) - (+billing_date)) / (1000 * 60 * 60 * 24)
        )

        if (end_billing_date_difference > 30) {
            end_date_tim = billing_end_date

            start_date_tim.setMonth(end_date_tim.getMonth() - (start_date_day >= end_date_day ? 1 : 0))
        } else {

        }

        return [ +start_date_tim, +end_date_tim ]
    }


    return (
        <div className={'monthly_report_page'}>
            <h1 className={'monthly_report_page__heading'}>Reports</h1>
            <b className={'monthly_report_page__desc'}>VLO`s Work Report</b>

            <div className="monthly_report_page__container">
                {!creditCount.isCreditSubscription && <div className="monthly_report_page__left-col">
                    <p>
                        This report details your VLO`s billable work undertaken in the selected time period.
                        Please note than it may take up to 48 hours for details to populate.<br/><br/>
                        You can view other monthly reports by contracting
                        <a href="mailto:invoicing@legalnodes.com">invoicing@legalnodes.com</a>
                        <div className={'monthly_report_page__checkbox'}>
                            {/*<CheckboxItem
                                active={sendToEmail}
                                onChange={() => setSendToEmail(!sendToEmail)}
                                label={'I want to receive a monthly report by email'}
                            />*/}
                        </div>
                    </p>
                </div>}
                {creditCount.isCreditSubscription &&
                    <div className="monthly_report_page__left-col">
                        <p>
                            You can download here the report with details on work conducted by your VLO and used credits in the selected time period.
                            Please note that it may take up to 48 hours for the details to populate.<br /> <br />
                            Should you require any assistance or have any questions, please contact our support
                            team via the Chat on the Platform or by sending an email to <a style={{ display: 'inline-block' }} href="mailto:help@legalnodes.com">help@legalnodes.com</a>.
                        </p>
                    </div>
                }
                <div className={'monthly_report_page__right-col'}>
                    {(associations.length > 1 && (+getLastWeek()[1] > +activateDateTimestamp)) && <ReactSelect
                        label={'Company'}
                        value={association + ''}
                        options={associations}
                        onChange={el => {
                            setAssociation(el.value)
                        }}
                    />}
                    {(+getLastWeek()[1] < +activateDateTimestamp) ? <div>
                        <div className="comment-list__desc" style={{ padding: '10px', marginTop: '10px' }}>
                            Sorry, currently there is no record data to display in the work report. Please note than it may take up to 48 hours for details to populate.
                        </div>
                    </div> : <div className={'monthly_report_page__date'} ref={dropdownRef}>
                        <div className="monthly_report_page__date-container" onClick={() => setIsOpen(!isOpen)}>
                            <b>Selected time period</b>
                            <span>
                                ({formatDate(requestDate[0])} - {formatDate(requestDate[1])})
                            </span>
                            <img src={calendarIcon} alt=""/>
                        </div>
                        <CSSTransition in={isOpen} classNames={'my-node'} timeout={200} unmountOnExit>
                            <div className="monthly_report_page__date-dropdown">
                                <div className="monthly_report_page__date-dropdown-heading">
                                    Most recent report includes VLO's work up until {formatDate(getLastWeek()[1])}
                                </div>
                                <div className="monthly_report_page__period-range">
                                    {/*<img src={okIcon} alt=""/>*/}
                                    <a onClick={() => onChange([new Date(calcBillingPeriodUpdated()[0]), new Date(end_date)])}>This Billing Cycle ({formatDate(new Date(+calcBillingPeriodUpdated()[0]))} - {formatDate(new Date(+end_date))})</a>
                                    {(toLocalTimestamp(getLastWeek()[0]) > activateDateTimestamp) && <a onClick={() => onChange(getLastWeek())}>Last week ({formatDate(getLastWeek()[0])} - {formatDate(getLastWeek()[1])})</a>}
                                    {/*// @ts-ignore*/}
                                    {/*{getThisMonth() && <a onClick={() => onChange([getThisMonth()[0], getLastWeek()[1]])}>This Month ({formatDate(getThisMonth()[0])} - {formatDate(getLastWeek()[1])})</a>}*/}
                                    {(toLocalTimestamp(getLastMonth()[0]) > toLocalTimestamp(getLastMonth()[1])) && <a onClick={() => onChange(getLastMonth())}>Last Month ({formatDate(getLastMonth()[0])} - {formatDate(getLastMonth()[1])})</a>}
                                </div>
                                <div className={'monthly_report_page__range'}>
                                    <div className={'monthly_report_page__range-label'}>Custom Date Range</div>
                                    <img className={'monthly_report_page__range-calendar1'} src={calendarBlackIcon} alt=""/>
                                    <img className={'monthly_report_page__range-calendar2'} src={calendarBlackIcon} alt=""/>
                                    <DateRangePicker
                                        onChange={onChange} value={value}
                                        locale={'en'}
                                        calendarIcon={<></>}
                                        clearIcon={null}
                                        format={'dd.MM.y'}
                                        maxDate={getLastWeek()[1]}
                                        minDate={new Date(activateDateTimestamp)}
                                        //autoFocus={false}
                                    />
                                    <button onClick={() => {
                                        setRequestDate(value)
                                        setIsOpen(false)
                                    }} className={'btn-black w100 mt15'}>APPLY</button>
                                </div>
                            </div>
                        </CSSTransition>
                    </div>}

                    <button disabled={+getLastWeek()[1] < +activateDateTimestamp} onClick={getFile} className={'monthly_report_page__button btn-black'}>VIEW REPORT</button>
                </div>
            </div>
        </div>
    )
}