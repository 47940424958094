import React, {useContext, useRef, useState} from "react";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import filterIcon from "../../../assets/images/filter.svg";
import {CSSTransition} from "react-transition-group";
import {Input} from "../../UI";
import searchIcon from "../../../assets/images/icons/search.svg";
import {MainContextProvider} from "../../../context/MainContext";

export const getCompanyStatus = (dept: string) => {
    if (dept === 'unsubscribed') return { label: 'inactive', color: '#000', background: '#eeeff6' }
    if (dept === 'frozen') return { label: 'frozen', color: '#fff', background: '#ff602e' }
    if (dept === 'warned') return { label: 'warned', color: '#000', background: '#ffcf23' }
    if (dept === 'trial') return { label: 'trial', color: '#000', background: '#777777' }
    if (dept === "none") return { label: 'active', color: '#fff', background: '#0b4cff' }
    return {}
}

export const ClientsSearchDropdown: React.FC<{ onClick: (key: string) => void, arr?: { value: string, label: string }[], value: string, id?: string }> = ({ onClick, arr = [], value, id }) => {
    const { userData } = useContext(MainContextProvider)
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })
    const [searchValue, setSearchValue] = useState('')

    const current = arr.find((el: any) => el.value === value)
    return (
        <div className={"sort_kanban_board"} ref={dropdownRef} id={id || 'undefined-id'}>
            <div style={{ justifyContent: 'flex-start' }} className={"sort_kanban_board__current"} onClick={() => {
                setIsOpen(true)
                setSearchValue('')
            }}>
                <img className={'mr10'} src={filterIcon} alt=""/>
                <div>
                    <em></em> <b>{current?.label}</b>
                </div>
            </div>
            <CSSTransition in={isOpen} timeout={{ enter: 200, exit: 200 }} classNames={'my-node'} unmountOnExit>
                <div className={"sort_kanban_board__dropdown"}>
                    <div style={{ position: "relative" }}>
                        <Input otherProps={{
                            style: { margin: '5px 10px 10px 10px' },
                            value: searchValue,
                            onChange: e => setSearchValue(e.target.value)
                        }} />
                        <img style={{ position: 'absolute', right: '20px', top: '18px' }} src={searchIcon} alt=""/>
                    </div>
                    <div style={{ maxHeight: '300px', overflow: 'auto' }}>
                        {
                            (arr || []).filter(el => el.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())).map((item: any) => (
                                <div
                                    className={`flex justifySpaceBetween alignCenter sort_kanban_board__dropdown-el ${current?.value === item.value && "sort_kanban_board__dropdown-el-active"}`}
                                    key={item.value}
                                    onClick={() => {
                                        onClick(item.value)
                                        setIsOpen(false)
                                        setSearchValue('')
                                    }}
                                >
                                    {item.label}
                                    {userData?.status.type_code !== 100 && getCompanyStatus(item.debt)?.label && <div
                                        style={{
                                            color: getCompanyStatus(item.debt).color,
                                            background: getCompanyStatus(item.debt).background,
                                            borderRadius: '20px',
                                            padding: '3px 12px',
                                            marginLeft: '5px',
                                            textTransform: 'uppercase'
                                        }}>{getCompanyStatus(item.debt).label}</div>}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </CSSTransition>
        </div>
    )
}