import logoIcon from '../../../assets/images/logo.svg'
import './frozen_modal.scss'
import React, {useContext} from "react";
import {MainContextProvider} from "../../../context/MainContext";

export const FrozenModal: React.FC<any> = ({ history }) => {
    const { setModalType, setModalData } = useContext(MainContextProvider)

    const goToInvoices = () => {
        history.push(`/settings/history`)
        setModalType('')
        setModalData(null)
    }

    return (
        <div className={'frozen_modal__modal'}>
            <div>
                <img src={logoIcon} alt=""/>
            </div>
            <h2>
                This workspace has been suspended due to unpaid invoices.
            </h2>
            <p>
                Your Workspace has been suspended due to a failure to pay invoices within 10 calendar days of the final request for payment.
            </p>
            <b>
                To reactive your services, please visit the Billing Page where you can view outstanding invoices and make payment.
            </b>
            <button onClick={goToInvoices} className={'btn-black w100'}>Go to Billing</button>
        </div>
    )
}