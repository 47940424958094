import React, {useContext} from "react";
import waitIcon from "../../../../assets/images/waitingIcon.svg";
import notificationsIcon from '../../../../assets/images/kanbanNotifications.svg'
import chatIcon from '../../../../assets/images/kanbanChatIcon.svg'
import './dropdown__notifications.scss'
import notificationIcon from '../../../../assets/images/notifications-dropdown-icon.svg'
import {dateFormatter} from "../../../../helpers/dateFormatter";
import {MainContextProvider} from "../../../../context/MainContext";

type DropDownNotificationsType = {
    show: {
        wait?: boolean
        comments: number
        notifications: number
    }
    isShowNotificationsDropDown?: boolean
    notificationsItems?: {datetime: string, text: string}[]
    white?: boolean
}

export const DropDownNotifications: React.FC<DropDownNotificationsType> = ({ show, isShowNotificationsDropDown, notificationsItems, white }) => {
    const { userData } = useContext(MainContextProvider)

    return (
        <div className={'dropdown__notifications'}>
            {/*{userData?.status.type_code === 100 && show.wait && <div className={'dropdown__notifications__relative'}>
                <img src={waitIcon} alt=""/>
                <div className="dropdown__notifications__block">
                    <div>
                        <h4><img style={{ marginRight: '5px' }} src={waitIcon} alt=""/>We’re working on it!</h4>
                        <p>
                            Your VLO is preparing the Offer for you. We will provide you with an update soon.
                        </p>
                    </div>
                </div>
            </div>}*/}
            {show.notifications > 0 && <div className={'dropdown__notifications__relative'}>
                {/*<img src={notificationIcon} alt="" style={{ marginRight: 0 }}/>*/}
                {isShowNotificationsDropDown && <div>
                    <div className={'dropdown__notifications__block'}>
                        <div style={{ width: '329px' }}>
                            {
                                (notificationsItems || []).map(({ text, datetime }) => (
                                    <div className={'dropdown__notifications-item'}>
                                        <div className={'dropdown__notifications-item-icon'}>
                                            <img src={notificationIcon} alt=""/>
                                        </div>
                                        <div className={'dropdown__notifications-item-text'}>
                                            <p>{text}</p>
                                            <em>{dateFormatter(datetime)}</em>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>}
            </div>}
            {show.comments > 0 && <div className={'dropdown__notifications__chat dropdown__notifications__relative'}>
                <span className={'dropdown__notifications__chat-count'}>{show.comments}</span>
                {/*<img src={chatIcon} alt=""/>*/}
            </div>}
        </div>
    )
}