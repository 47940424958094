export enum TokenEnum {
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
  expiresIn = 'expiresIn',
}

export type TokenType = {
  [TokenEnum.accessToken]: string,
  [TokenEnum.refreshToken]: string,
  [TokenEnum.expiresIn]: string,
}
