import { AxiosResponse } from 'axios'
import { methodGet } from './api/api'
import customAxios from './api/interceptors'
import { ProjectPostType, ProjectType } from '../types/project.type'

export const getProjects = async (urlData?:string) => methodGet({
  path: `company/projects?expand=company,curator,tasks,price_estimate,team,subscription,deadline,country${urlData}&per-page=50`,
})

export const createProject = async (data:ProjectPostType):Promise<AxiosResponse> => customAxios.post('company/projects', data)

export const getSingleProject = (id: string | null):Promise<AxiosResponse> => customAxios.get<ProjectType>(`company/projects/${id}?expand=subscription,price_estimate`)
