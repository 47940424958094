import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ApiMethodType } from '../../types/custom/apiMethod.type'
import customAxios from './interceptors'

export const methodGet = ({
  path = '', body = {}, headers = {}, cancelToken,
}: ApiMethodType): Promise<AxiosResponse> => {
  const config: AxiosRequestConfig = {
    params: body,
    headers,
    cancelToken,
  }

  // if (cancelToken) {
  //     config.cancelToken = cancelToken;
  // }

  return customAxios.get(
    path,
    {
      ...config,
    },
  )
}

export const methodPost = ({ path = '', body = {}, headers = {} }: ApiMethodType): Promise<AxiosResponse> => customAxios.post(
  path,
  { ...body },
  { headers },
)
