import {OfflineLayout} from "./OfflineLayout";
import React, {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../context/MainContext";
import {useHistory, useLocation} from "react-router-dom";
import {getSingleOfferService} from "../../service/client/offer";
import axios from "axios";
import {OfferHeader} from "../../components/other/ClientKanbanData/OfferHeader/OfferHeader";
import {switchNextSteps} from "../Lawyer/Task/TasksProjectKanban/TasksProjectKanbanGrid/TasksProjectKanbanGrid";
import {OfferHeading} from "../../components/other/ClientKanbanData/OfferHeading/OfferHeading";
import {LegalTeam} from "../../components/other/ClientKanbanData/LegalTeam/LegalTeam";
import {TaskTabList} from "../../components/other/ClientKanbanData/TaskTabList/TaskTabList";
import {DeliverablesTab} from "../../components/other/ClientKanbanData/Deliverables/DeliverablesTab";
import {OfferFooter} from "../../components/other/ClientKanbanData/OfferFooter/OfferFooter";
import {toastifySuccess} from "../../service/toastify/toastify";
import Tabs from "../../components/other/Tabs";

export const OfflineOffer: React.FC<any> = ({ match }) => {
    const { setLoading, userData, modalData, setModalType, setModalData } = useContext(MainContextProvider)
    const { params: { id } } = match
    const { search } = useLocation()
    const history = useHistory()
    const urlSearchParams = new URLSearchParams(search);
    const { token_view, email } = Object.fromEntries(urlSearchParams.entries()) || {  };
    const [offerData, setOfferData] = useState<any>({})
    const [notifications, setNotifications] = useState<any[]>([])
    const [tasks, setTasks] = useState<any>([])
    const [deliverables, setDeliverables] = useState('')
    const [activeTab, setActiveTab] = useState('tasks')
    const [isAccepted, setIsAccepted] = useState(false)
    const [isUnSubscribe, setIsUnSubscribe] = useState(false)

    const getOffer = async () => {
        setLoading(true)
        try {
            const { data, status } = await axios.get(`${process.env.REACT_APP_BASE_API}/company/client/kanban/${id}?token_view=${token_view}&expand=manager,lawyers,next_step,price_estimate,offer_price,project,notifications,client,company`)
            if(status === 200) {
                setOfferData({
                    ...data.offer,
                    client: data.client,
                    company: data.company,
                    ['is done']: data['is done'],
                    offerComments: (data.offer.offerComments || []).reverse()
                })
                setIsUnSubscribe(data.company.debt === 'unsubscribed')
                setDeliverables(data.deliverables)
                setTasks(data.tasks)
                if(data.offer.accepted === 1) {
                    setIsAccepted(true)
                }
            } else {
                history.push(`/`)
            }
        } catch (e) {
            history.push(`/`)
        }

        setLoading(false)
    }

    useEffect(() => {
        getOffer().then()

        if(email) {
            setModalData({
                offer_id: id,
                token_view: token_view,
                from_email: email
            })
            setModalType('offline_accept_offer')
        }
    }, [id])

    useEffect(() => {
        if(modalData && modalData.accepted === 1) {
            setIsAccepted(true)
        }
    }, [modalData])

    const offerStatus = () => {
        if(offerData['is done'] === 1) {
            return {
                text: 'Done',
                color: '#07B56C'
            }
        }
        if(+offerData?.accepted === 0 ) {
            return {
                text: 'DRAFT',
                color: '#A8B3CF'
            }
        }
        if(+offerData?.accepted === 1 || +offerData?.accepted === 2 || +offerData?.accepted === 3) {
            return {
                text: 'OFFER',
                color: 'rgb(255, 154, 61)'
            }
        }
        if(offerData?.accepted === 5) {
            return {
                text: 'Tasks Assigned',
                color: '#2361FF'
            }
        }
        return {
            text: '-',
            color: '#2361FF'
        }
    }

    return (
        <OfflineLayout>
            <div style={{ width: '800px', margin: '0 auto' }}>
                <div className={'singleOffer task-view'}>
                    <div className="task-view__left-side">
                        <div className="task-body">
                            <OfferHeader
                                statusText={window.location.href.includes('onboarding') ? '' : offerStatus().text}
                                statusBackground={offerStatus().color}
                                isWaitIcon={userData?.status.type_code === 100 && (+offerData?.accepted === 1 || +offerData?.accepted === 0)}
                                updated_at={offerData?.updated_at}
                                isMoveToPayment={userData?.status.type_code === 300 && +offerData.accepted === 1}
                                moveToPayment={() => {}}
                                isMoveToPublic={userData?.status.type_code === 300 && +offerData.accepted === 0}
                                moveToPublic={() => {}}
                                isStartDelivery={userData?.status.type_code === 300 && +offerData.accepted === 3}
                                startDelivery={() => {}}
                                isMoveToDone={userData?.status.type_code === 300 && +offerData.accepted === 5 && +offerData['is done'] !== 1}
                                moveToDone={() => {}}
                                moveToUnDone={() => {}}
                                notifications={notifications}
                                accepted={+offerData?.accepted}
                                is_done={+offerData['is done']}
                                changeStatus={(status: string) => {}}
                                copyOfferInfoToBuffer={() => {}} hideBackButton
                                isOffer
                                is_archived={offerData.is_archived}
                                moveToArchive={() => {}}
                            />
                            <OfferHeading
                                id={id}
                                heading={offerData?.title}
                                step={+offerData['is done'] !== 1 ? switchNextSteps(+offerData.accepted) : ''}
                                text={offerData?.description}
                                company_id={offerData?.company_id}
                                isNextStep={true}
                                isEdit={userData?.status.type_code === 300}
                                data={offerData}
                            />
                            <LegalTeam
                                items={[
                                    {
                                        id: offerData?.company?.manager?.user_id,
                                        photos: offerData?.manager?.photos,
                                        name: offerData?.manager?.name,
                                        title: 'VLO',
                                        type: '300'
                                    },
                                    ...offerData.lawyers || []
                                ]}
                            />
                        </div>
                        <Tabs
                            arr={[
                                {
                                    Component: <TaskTabList
                                        project={offerData.project}
                                        tasks={tasks}
                                        showStatus={offerData?.accepted !== 0}
                                        deleteTask={() => {}}
                                        isAcceptToDeleteTask={offerData?.accepted === 0 || offerData?.accepted === 2}
                                        accepted={offerData?.accepted}
                                    />,
                                    label: (userData?.status.type_code === 300 || (userData?.status.type_code === 100 && (window.innerWidth <= 500 ? tasks.length > 0 : true ))) ? 'Tasks' : '',
                                    value: 'tasks'
                                },
                                {
                                    Component: <DeliverablesTab
                                        deliverables={deliverables}
                                        offer_id={id}
                                        clearDeliverables={() =>{}}
                                    />,
                                    label: +offerData?.accepted === 5 &&
                                    (userData?.status.type_code === 300 || (userData?.status.type_code === 100 && deliverables.length > 0)) ? 'Deliverables' : '',
                                    value: 'deliverables'
                                }
                            ]}
                            active={activeTab}
                            onChange={(tab) => setActiveTab(tab)}
                            DivBottomRender={<OfferFooter
                                totalCreditPrice={0}
                                copyGeneratedLink={() => {}}
                                totalPrice={offerData?.offer_price || 0}
                                accepted={offerData?.accepted}
                                ifOfflineButton={isAccepted}
                                isUnSubscribe={isUnSubscribe}
                                offlineButton={() => {
                                    setModalData({
                                        offer_id: id,
                                        token_view: token_view,
                                        from_email: email
                                    })
                                    setModalType('offline_accept_offer')
                                }}
                                onClick={() => {}} />
                            }
                        />
                    </div>
                </div>
            </div>
        </OfflineLayout>
    )
}